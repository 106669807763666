<template>
  <div>
    <van-nav-bar
        title="店铺状态"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <div>
      <Steps :active="active" :active-color="info.apply_state==1?'08a229':'#AC3A35'">
        <Step>支付款项</Step>
        <Step>平台审核</Step>
        <Step>完成</Step>
      </Steps>
    </div>

    <van-form @submit="onSubmit" class="form">

      <div class="p1">
        <div class="title">店铺状态</div>
        <div class="kuai flex" style="justify-content: space-between;">
          <div class="name">当前状态</div>
          <div class="content" >
            {{info.status_now}}
          </div>
        </div>

        <div class="kuai flex" style="justify-content: space-between;">
          <div class="name">店铺名称</div>
          <div class="content" >
            {{info.store_name}}
          </div>
        </div>

        <div class="kuai flex" style="justify-content: space-between;">
          <div class="name">登录账户</div>
          <div class="content" >
            {{info.seller_name}}
          </div>
        </div>

        <div class="kuai flex" style="justify-content: space-between;">
          <div class="name">登录密码</div>
          <div class="content" >
            同当前登录密码
          </div>
        </div>

        <div class="kuai flex" style="justify-content: space-between;">
          <div class="name">开店款项</div>
          <div class="content" v-if="active==0" >
            ¥{{info.moeny}}
          </div>
          <div class="content" v-else>
            已支付
          </div>
        </div>
      </div>

      <div style="margin: 16px;">
        <van-button round block v-if="active==0" @click="pay" type="primary" style="background: #AC3A35;border: 1px solid #AC3A35;" native-type="submit">
          马上支付
        </van-button>
        <van-button round block v-if="active==2" @click="login" type="primary" style="background: #08a229;border: 1px solid #08a229;margin-top: 10px;" >
          去登录
        </van-button>
      </div>
    </van-form>
    <div id="pay"></div>
  </div>
</template>

<script>
import {NavBar,Icon,Form,Field,Button,Uploader,Step,Steps,Cell,CellGroup,Radio,RadioGroup,Popup,Picker,Area,Toast} from 'vant'
import {ref} from 'vue'
import config from "../../../config.base"

export default {
  name: "kdinfo",
  setup() {
    const fileList = ref([
      { url: 'https://ae01.alicdn.com/kf/U8a21d21c024a434a84b7352c47c6b9cab.jpg' },
      { url: 'https://ae01.alicdn.com/kf/Ubaa7c9b3494b4d71b2c70d66293c06c4J.jpg' },
      // Uploader 根据文件后缀来判断是否为图片文件
      // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    ]);


    const afterRead = (file) => {
      file.status = 'uploading';
      file.message = '上传中...';

      setTimeout(() => {
        file.status = 'failed';
        file.message = '上传失败';
      }, 1000);
    };
    return {
      fileList,
      afterRead,
    };
  },
  data(){
    return {
      active:0,
      columns:[
        '杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'
      ],
      info:{

      },
      state:{
       dpfl:{
         showPicker:false,
         value:'请选择'
       },
      },
      checked:'',
      checked_shangjia:'',
      areaList:[],
    }
  },
  created() {
    this.checkStatus();
    this.payid();
  },
  methods:{
      checkStatus(){
          this.$api.kd_status().then(r=>{
            // console.log(r);
            if(r.data.id==null){
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
            this.info = r.data;
          
            if(r.data.apply_state==1){
              this.active = 2;
              this.info.status_now = '正常营业';
              return;
            }
          
            if(this.info.apply_status==1){
              this.active = 1;
              this.info.status_now = '等待审核中';
            }else{
              this.active = 0;
              this.info.status_now = '待支付';
            }
          })
      },
      payid(){
        if(window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
          if(this.$route.query.code!=undefined){
              let data={};
              data.code = this.$route.query.code;
              data.method = 'post';
              this.$api.acPayId(data).then(r=>{
                  if(r.code!==200){
                      Toast('获取id错误');
                  }
              })
          }else{
              this.$api.acPayId({
                call_back_url: window.location.href,
                method:'get'
              }).then(res => {
                if (res.data.url !== undefined){
                     window.location.href = res.data.url;
                }
              });
          }
        }
      },
    onConfirm_dpfl(value){
        this.state.dpfl.value = value;
        this.state.dpfl.showPicker = false;
        // console.log(111);
    },
    pay(){
       if(window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
           //微信
           this.$api.kdWeChatPay().then(r => {
               if (r.code === 200) {
                 if (typeof window.WeixinJSBridge === 'undefined') {
                   window.WeixinJSBridge = {};
                 }
                 let that = this;
                 window.WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(r.data.pay_config),
                     function (res) {
                       console.log(res);
                       // TODO: 检查微信支付结果
                       that.weChatKdQuery(r.data.order_sn);
                     }
                 );
               }
             });
       }else{
           //支付宝
           this.$api.getPayForm().then(r=>{
             if(r.code==200){
               document.getElementById('pay').innerHTML = r.data.payForm;
               document.forms['alipaysubmit'].submit();
             }
           })
       }
    },
    /*
    * 检查微信支付结果
    * */
    weChatKdQuery: function (orderSn) {
      this.$api.weChatKdQuery({order_sn: orderSn}).then(res => {
        if (res.code === 200) {
          Toast('保证金支付成功');
          this.checkStatus();
        } else {
          if (res.code === 500) {
            Toast('支付失败');
            this.checkStatus();
          } else {
            setTimeout(() => {
              this.weChatKdQuery(orderSn);
            }, 200);
          }
        }
      });
    },
    login(){
      window.open(config.baseURL+'/Seller/Admin/login')
    },
    onClickLeft(){
      this.$router.push('/user');
    }
  },
  components:{
    [NavBar.name]:NavBar,[Icon.name]:Icon,
    [Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
    [Uploader.name]:Uploader,
    [Cell.name]:Cell,
    [CellGroup.name]:CellGroup,
    [RadioGroup.name]:RadioGroup,
    [Radio.name]:Radio,
    [Popup.name]:Popup,
    [Picker.name]:Picker,
    [Area.name]:Area,
    Step,
    Steps
  },
  computed:{

  }
}
</script>

<style scoped>
.form{
  margin: 10px;
  border-radius: 18px;
}
.van-cell.van-field:first-child{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.van-cell.van-field:nth-child(3){
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.p1{
  color: black;
  margin: 10px;
  font-size: 16px;
  text-align: left;
}
.title{
  color: #d2211b;
  font-weight: bold;
  margin-bottom: 10px;
}
.kuai{
  background: white;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.name{
  padding: 10px;
  padding-left: 5px;
}
.content{
  padding: 10px;
  display: block;
  color: #8d8b8b;
}
.tips{
  color: #8d8b8b;
  font-size: 12px;
  margin:10px;
}
</style>
