<template>
  <div class="main">
    <div class="flex l_top">
      <router-link to="/login">
        <div style="font-size: 20px;">X</div>
      </router-link>
    </div>
    <div class="flex denglu">
      验证码登录
    </div>
    <div class="form">
      <Field class="input" v-model="info.mobile"  type="tel" label="手机号"  placeholder="请输入手机号"></Field>
      <Field
          v-model="info.sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
      >
        <template #button>
          <Button size="small" type="default" class="yzm" @click="sms">{{code}}</Button>
        </template>
      </Field>

<!--      <div class="flex" style="justify-content: flex-end;font-size: 14px;margin-top: 8px;">忘记密码?</div>-->
      <Button id="denglu_btn" type="primary" size="large" @click="smp" color="#AC3C35">登录</Button>
    </div>
    <div class="xieyi">
      <p>未注册手机登录后即自动注册</p>
      <p>代表您同意天工开物 <span>用户协议</span> 和 <span>隐私政策</span></p>
    </div>
    <div class="qita">
      - 其他登录方式 -
    </div>
    <div class="si_icon">
      <p><img src="../../assets/img/qq.png"></p>
      <p><img src="../../assets/img/weibo.png"></p>
      <p><img src="../../assets/img/weixin.png"></p>
      <p><img src="../../assets/img/pguo.png"></p>
    </div>
  </div>
</template>

<script>
import {Field, Button, Toast} from 'vant';
import {setKey} from "@/util/cache";

export default {
  name: "Ylogin",
  data(){
    return {
      sertxt:'',
      activeName:'index',
      code:'发送验证码',
      tag:'SmsLogin',
      info:{
        mobile:'',
        sms:'',
      }
    }
  },
  created() {
  },
  methods:{
    sms(){
      if(typeof this.code == 'number') return;
      if(!(/^1[3456789]\d{9}$/.test(this.info.mobile))){
        Toast('手机号输入有误');
        return;
      }

      this.$api.sendRegSmsCode({phone:this.info.mobile,tag:this.tag}).then(r=>{
            if(r.code==200) {
              this.code = 60;

              this.time = setInterval(() => {
                if (this.code == 0) {
                  clearInterval(this.time);
                  this.code = '发送验证码';
                  return;
                }
                this.code -= 1;
              }, 1000)
            }
          }
      )
    },
    smp(){
      if(!(/^1[3456789]\d{9}$/.test(this.info.mobile))){
        Toast('手机号输入有误');
        return;
      }

      if(this.info.sms.length!=6){
        Toast('验证码输入有误');
        return;
      }

      this.$api.ylogin(this.info).then(r=>{
        if(r.code==200){
          setKey('Tg_token',r.data.token);
          this.$store.commit('user',r.data);
          window.history.length > 2 ? this.$router.go(-2) : this.$router.push('/user')
        }
      })
    }
  },
  components:{
    Field,Button
  },
  computed:{

  }

}
</script>
<style>
.van-search__content{
  border-radius: 4px;
}
</style>
<style scoped>
  .main{
    /*width: 100%;*/
    background: white;
    height: 95vh;
    color: black;
    padding: 15px;
  }
  .l_top{
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .denglu{
    margin: 20px;
    font-size: 32px;
    font-weight: 400;

  }
  .form{
    margin-bottom: 20px;
  }
  .input{
    margin-bottom: 20px;
  }
  #denglu_btn{
    width: 80%;padding: 10px;margin-top: 20px;
    margin-bottom: 10px;
  }
  .xieyi{
    font-size: 14px;
    color: #8d8b8b;
  }
  .xieyi span{
    color: black;
  }
  .qita{
    margin: 20px;
    margin-top: 20vh;
    color: #8d8b8b;
    font-size: 14px;
  }
  .si_icon{
    display: flex;
    justify-content: center;
  }
  .si_icon img{padding: 10px;width: 30px;height: 30px;}
  .yzm{
    min-width: 40px;
  }
</style>