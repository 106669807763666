<template>
  <div class="main">
    <van-nav-bar
        :title="good_info.title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
        <van-icon name="share" size="18" style="color: white;" />
      </template>
    </van-nav-bar>
    <Pj v-if="good_info.label!=''" :label="good_info.label"></Pj>

    <van-swipe class="my-swipe" :autoplay="isPlayVideo ? false : 3000" indicator-color="white" @change="swipeChange">

      <!--   视频   -->
      <van-swipe-item v-if="good_info.video">
        <video :src="good_info.video" @click="playVideo" class="auctionVideo" @ended="playVideo" ref="descVideo"></video>
        <div class="auctionVideoAction" v-show="!isPlayVideo">
          <van-icon name="play-circle" size="60px" color="#FFFFFF" @click="playVideo"></van-icon>
        </div>
      </van-swipe-item>

      <template v-for="(v,k) in images" :key="k">
        <van-swipe-item @click="show_img(k)">
          <img class="ms_img" v-lazy="v" />
        </van-swipe-item>
      </template>
    </van-swipe>

    <div class="p1">
      <div class="flex p1_1">
        <p class="money">¥{{good_info.assemble_price}} <span class="txtdel">¥{{good_info.single_buy_price}}</span></p>
        <p class="kucun">剩余{{good_info.num}}件</p>
      </div>
      <div class="title flex">{{good_info.title}}</div>
    </div>

    <div class="p1">
      <div class="flex pj">
        <p class="huoping_tips">支付并邀请两人参伙，人数不足自动退款。</p>
        <p class="more"><van-icon name="arrow"></van-icon></p>
      </div>
      <div>
        <img class="huo_img" src="@/assets/img/huoping.png">
      </div>
    </div>

<!--作品介绍-->
    <div class="p1">
      <div class="p1_info">
        <p>· 正品保障</p>
        <p>· 七天无理由</p>
      </div>
      <button class="canshu_cat" @click="cat_zp" >作品参数</button>
    </div>

    <van-popup
        v-model:show="zpshow"
        closeable
        close-icon="close"
        position="bottom"
        :style="{}"
    >
      <div class="title_zp" >作品参数</div>
      <div class="canshu_zp flex">
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
      </div>

    </van-popup>

<!--    -->

<!--    企业介绍-->
    <div class="p1 flex qiye">
      <div class="flex qiye_guan">
        <div class="flex">
          <p><img class="qiye_img" :src="good_info.store_logo"></p>
          <div class="qiye_jianjie">
            <p class="qiye_name">{{good_info.store_name}}</p>
            <p class="qiye_gary">企业、创始人介绍</p>
            <p class="qiye_gary">作品/产地</p>
          </div>
        </div>
        <div><van-button plain hairline color="red" class="guanzhu" @click="gz">{{good_info.focus?'已关注':'关注'}}</van-button></div>
      </div>
<!--      <div class="qiye_desc">xxxxxxxxxxxxxxxxxxx</div>-->
    </div>
<!---->


    <div class="p1 dd" >
      <div class="flex pj">
        <p>作品评价 ( {{good_info.pl_num}} )</p>
        <router-link :to="{path:'/evaluate',query:{id:good_info.pro_id}}">
          <p class="more">查看更多 <van-icon id="pj_right" name="arrow"></van-icon></p>
        </router-link>
      </div>
      <template v-if="good_info.pl.length>0">
        <template v-for="(va,ks) in good_info.pl" :key="ks">
          <div class="flex pj_1" >
            <div class=" flex">
              <p ><img class="avatar" v-lazy="va.avatar?$va.head:'/avatar.png'"></p>
              <div class="nickname">
                {{va.nickname}}
                <p class="star"><Rate v-model="va.goods_rank" size="12" readonly></Rate></p>
              </div>

            </div>
            <div class="createtime">{{va.add_time}}</div>
          </div>
          <div class="descx">
            <!--          <template v-for="(p,pk) in va.pics" :key="pk">-->
            <!--            <img v-lazy="p">-->
            <!--          </template>-->
            <div class="desc_text">{{va.content}}</div>
          </div>
        </template>
      </template>
    </div>

    <div class="p2">
      <p>商品详情</p>
    </div>
    <div class="htmlv" v-html="good_info.goods_content"></div>

    <van-action-bar class="bar">
      <van-action-bar-icon icon="chat-o" text="私信" @click="onClickIcon" />
      <van-action-bar-button type="warning" @click="singleBuy" :text="'单独购买 ¥'+(good_info.single_buy_price)" color="#D76A31" />
      <van-action-bar-button type="danger" @click="pay" :text="'伙拼购买 ¥'+good_info.assemble_price" color="#AB3C35" />
    </van-action-bar>

  </div>
</template>

<script>
import {NavBar, Toast,Swipe,SwipeItem,ImagePreview,Icon,Rate,
  ActionBar, ActionBarIcon, ActionBarButton,Popup,Button,Dialog} from 'vant';
import Pj from "@/components/Pj"
import {wxShare,isWx} from '@/util/wx.js'; 
  
// 字符串内容全部替换
String.prototype.replaceAll = function(find, replace){
  let reg = new RegExp(find,"g");  //创建正则RegExp对象
  return this.replace(reg, replace);
};

// 将被转义的 html 代码转义回原始状态
String.prototype.unescape = function () {
  return this.replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
      .replaceAll("&#39;", "'")
      .replaceAll("&amp;nbsp;", "&nbsp;");
};

export default {
  name: "Factions",
  data() {
    return {
      images: [

      ],
      show:false,
      index:1,
      zpshow:false,
      pid:0,
      good_info:{
        num:0,
        label:'',
        title:'祖传陶瓷碗',
        pingjia:88,
        pl:[

        ]
      },

      isPlayVideo: false,
    }
  },
  created() {
    this.pid = this.$route.query.id;
    this.code = this.$route.query.share_code;
    if(this.pid==undefined || this.pid<=0){
      this.$router.push('/');
    }
    this.$api.factiondata({id:this.pid}).then(r=>{
      if(r.code==200){
        // console.log(r);
        if(r.msg=='活动不存在或已结束'){
          this.onClickLeft();
        }
        r.data.goods_content = r.data.goods_content.unescape();
        this.good_info = r.data;
        this.good_info.pingjia=0;
        this.images = r.data.images;
        
        if(isWx()){
            wxShare(window.location.href,r.data.title,r.data.goods_remark,r.data.original_img);
        }

      //  查找团购的
        if(this.code!=undefined && this.code!=''){
          this.$api.myteam(this.code).then(v=>{
              if(v.code==200){
                Dialog.alert({
                  title: '您的好友 ['+v.data.assemble_info.ranks[0].nickname+']',
                  message: '邀请您购买 '+v.data.assemble_info.goods_name,
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
                this.$store.commit('add_share_code',v.data.share_id);
              }
          })
        }
      }
    })
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    show_img(k){
      ImagePreview({
        images:this.images,
        startPosition: k,
      });
    },

    /*
    * 单独购买
    * */
    singleBuy: function () {
      this.$router.push({path:'checkin',query:{id:this.pid, isAssembleSingle: true}});
    },

    cat_zp(){
      this.zpshow=true;
    },
    gz(){
      this.$api.focus(this.good_info.store_id).then(r=>{
        Toast(r.msg);
        if(r.msg=='关注成功'){
          this.good_info.focus=true;
        }else{
          this.good_info.focus=false;
        }
      })
    },
    pay(){
      this.$router.push({path:'checkin',query:{id:this.pid}})
    },

    /*
    * 轮播切换回调事件
    * */
    swipeChange: function () {
      if (this.good_info.video){
        let el = this.$refs.descVideo;
        if (!el.paused){
          this.playVideo();
        }
      }
    },

    /*
    * 播放视频
    * */
    playVideo: function () {
      let el = this.$refs.descVideo;
      if (el.paused){
        el.play();
      }else{
        el.pause();
      }
      this.isPlayVideo = !el.paused;
    },

  },
  components: {
    [NavBar.name]:NavBar,
    [Swipe.name]:Swipe,
    [SwipeItem.name]:SwipeItem,
    [Icon.name]:Icon,
    [ActionBar.name]:ActionBar, [ActionBarIcon.name]:ActionBarIcon, [ActionBarButton.name]:ActionBarButton,
    Rate,[Popup.name]:Popup,[Button.name]:Button,Pj
  },
  computed: {}
}
</script>

<style scoped>

.auctionVideo{
  width: 100%;
  height: 365px;
  background-color: #000000;
  object-fit: contain;
}

.auctionVideoAction{
  width: 100%;
  height: 365px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}

.my-swipe{
  width: 100%;
  height: 340px;
  position: relative;
  bottom: 1px;
  z-index: 99;
}
.huoping_tips{
  color: #8d8b8b;font-size: 14px;border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.main{
  margin-bottom: 100px;
}
.bar{padding:10px;}
.ms_img{
  width: 100%;
  height: 340px;
  object-fit: cover;
}
.p1{
  margin: 10px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  position: relative;

}
.huo_img{
  width: 100%;
}
.p1_1{
  justify-content: space-between;
}
.pj{
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 16px;
  color: black;
}

.more{
  font-size: 14px;
  color: #8d8b8b;
  position: relative;
  top: 2px;
}
.p1_info{
  text-align: left;
  display: flex;
  font-size: 14px;
  color: #8d8b8b;
}
.p1_info p{
  padding-right: 10px;
}
#pj_right{position: relative;top: 2px}
.money{color: #d2211b;font-weight: bold}
.money span{color: #4e4949;font-weight: lighter;font-size: 16px;}
.kucun{color: #8d8b8b;font-size: 14px;position: relative;top: 10px}
.title{justify-content: start;padding-top: 5px;font-size: 16px;font-weight: bold;color: black;text-align: left;}
.avatar{width: 40px;border-radius: 99px;}
.nickname{color: #8d8b8b;font-size: 14px;padding-left: 10px;text-align: left;}
.pj_1{justify-content: space-between;}
.createtime{color: #8d8b8b;font-size: 14px;position: relative;top:14px;}
.descx{justify-content: start;text-align: left;padding-left: 50px;font-size: 16px;padding-top: 5px;margin-bottom: 10px;}
.descx img{width: 80px;padding: 10px;}
.desc_text{padding-top: 0px;position: relative;bottom: 10px;font-size: 14px;}
.dd{margin-bottom: 20px;}
.title_zp{padding: 10px;border-bottom: 1px solid #eee;}
.canshu_zp{
  justify-content: space-between;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
}
.line_zp{
  padding-bottom: 20px;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.canshu_cat{
  font-size: 14px;
  padding: 5px;
  position: absolute;
  right: 13px;
  bottom: 5px;
  border-radius: 8px;
  background: #be3d47;
  color: white;
}
.qiye_img{
  width: 60px;
  border-radius: 5px;
}
.qiye{
  flex-wrap: wrap;
}
.qiye_guan{
  justify-content: space-between;
  width: 100%;
}
.qiye_jianjie{
  font-size: 14px;
  padding-left: 10px;
  color: black;
  font-weight: bold;
}
.qiye_desc{
  margin-top: 15px;
  font-size: 16px;
  margin-left: 10px;

}
.qiye_name{
  margin-bottom: 5px;
  font-size: 16px;
}
.qiye_gary{
  color: #8d8b8b;
  font-size: 12px;
}
.guanzhu{
  padding: 0px 20px;
}
.p2{
  background: black;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
}
 .htmlv{
   /* margin: 5px; */
   font-size: 14px;
	text-align: left;
 }
  .htmlv >>> img{
    width: 100%;
	display: block;
  }
.htmlv >>> video{
  width: 100%;
}
#tiao >>> .van-progress__portion{
  max-width: 100%;
}
</style>
