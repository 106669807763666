<template>
  <div>
    <van-nav-bar
        :title="edit==true?'修改地址':'添加地址'"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <AddressEdit
        id="edit"
        :area-list="areaList"
        show-postal
        :show-delete="edit==true"
        show-set-default
        show-search-result
        :address-info="info"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        @change-detail="onChangeDetail"
    />
    <!--        :search-result="searchResult"-->

  </div>
</template>

<script>
import {NavBar,Icon,AddressEdit,Toast} from 'vant'
import {areaList} from '@vant/area-data';
import {ref} from 'vue'
export default {
    name: "Edit",
    setup(){
      const searchResult = ref([]);
      const onChangeDetail = (val) => {
        if (val) {
          searchResult.value = [
            {
              name: '黄龙万科中心',
              address: '杭州市西湖区',
            },
          ];
        } else {
          searchResult.value = [];
        }
      };

      return {
        areaList,
        searchResult,
        onChangeDetail,
      };
    },
    data(){
        return {
            edit:false,
            info:{}
        }
    },
    created() {
      this.edit = this.$route.query.e==1?true:false;
      //修改地址
      if(this.edit){
        this.$api.getaddress({id:this.$route.query.id}).then(r=>{
          if(r.code==200){
            this.info = r.data;
            this.info.areaCode = r.data.areacode;
            console.log(this.info)
          }
        })
      }
    },
    methods:{
      onSave(e){
        // console.log(e);
        // return;
       if(this.edit){
         this.$api.edit_address({
           'consignee':e.name,
           'province':e.province,
           'city':e.city,
           'district':e.county,
           'address':e.addressDetail,
           'mobile':e.tel,
           'areaCode':e.areaCode,
           'is_default':e.isDefault?1:0,
           'zipcode':e.postalCode,
           'id':this.$route.query.id
         }).then(r=>{
           if(r.code==200){
             Toast(r.msg);
             this.$router.push('/address');
           }
         });
       }else{
         this.$api.add_address({
           'consignee':e.name,
           'province':e.province,
           'city':e.city,
           'district':e.county,
           'address':e.addressDetail,
           'mobile':e.tel,
           'areaCode':e.areaCode,
           'is_default':e.isDefault?1:0,
           'zipcode':e.postalCode,
         }).then(r=>{
           if(r.code==200){
             Toast(r.msg);
             this.$router.push('/address');
           }
         });
       }
      },
      onDelete(){
        this.$api.del_address(this.$route.query.id).then(r=>{
          if(r.code==200){
            Toast('删除成功');
            this.$router.push('/address');
          }
        })
      },
      onClickLeft(){
        // window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/address');
        this.$router.go(-2)
      }
    },
    components:{
      [NavBar.name]:NavBar,[Icon.name]:Icon,
      AddressEdit
    },
    computed:{
    
    }
}
</script>

<style scoped>
#edit >>> .van-cell__title{
  text-align: left;
}
</style>
