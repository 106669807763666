//src\util\request.js
import axios from "axios"
//这里使用vant的吐司来提示
import {Toast} from 'vant'
import {getKey,removeKey} from '@/util/cache'
import config from "../../config.base"
import router from "@/router";
import QueryString from "qs";
/*
*axios.create
*a.根据指定配置创建一个新的 axios，也就是每个新 axios都有自己的配置
*b.新 axios只是没有取消请求和批量发请求的方法，其它所有语法都是一政的
*c.为什么要设计这个语法?
*需求:项目中有部分接口需要的配置与另一部分接口需要的配置不太一样，如何处理
*解决:创建2个新axios，每个都有自己特有的配置，分别应用到不同变求的接口请求中
*/

let instance = axios.create({
    baseURL: config.baseURL,
    timeout: 10000,
    changeOrigin:true,
    headers: { 'Content-Type': 'application/json'},
})

//设置方法2
// instance.defaults.baseURL = config.baseURL
// instance.defaults.timeout = 5000
//表示跨域请求时是否需要使用凭证（cookie）,设置后cookie会出现在请求头
//axios.defaults.withCredentials = true;
instance.defaults.withCredentials=true;

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    //我在这里给请求参数添加了token
    if (config.method != "get" && config.headers["Content-Type"]!='multipart/form-data') {
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
        config.data = QueryString.stringify(config.data);
    }

    // var token = { "clienttype": "web", "ordertoken": "", "clienttoken": clientToken, "version": "1.0.0" };
    // config.headers["TG_TOKEN"] = getKey();
    config.headers["TOKEN"] = getKey();

    // config.params = {
    //     // "token": clientToken,
    //     ...config.params
    // };

    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么

    if(response.data.msg!=undefined && response.data.msg!=''){
        Toast(response.data.msg);
    }

    if (response.data.code == "200") {
        return response.data
    }


    if (response.data.code == "299") {
        // 登录过期
        removeKey();
        Toast.fail({
            message:response.data.msg
        })
    //    跳转
        router.push('login');

    }

    return response.data;

}, function (error) {
    Toast.fail({
        message: "网络错误",
        duration: 1.5 * 1000
    })
    return Promise.reject(error)
})

export default instance