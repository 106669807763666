<template>
  <van-popup
      close-icon="close"
      position="bottom"
      closeable="true"
      :style="{}"
      @click-close-icon="close"
      @click-overlay="close"
  >
    <div class="title_zp" >
      <div class="flex js title">
        <p>当前出价人</p>
        <p>当前价</p>
      </div>
      <div class="flex js">
        <div class="flex chujia">
          <img class="chujia_pic" :src="user.avatar==''?'@/assets/img/tu1.png':user.avatar">
          <p class="zi">{{user.nickname==''?'暂无':user.nickname}}</p>
        </div>
        <div class="now_pirce">¥ {{total}}</div>
      </div>
    </div>
    <div style="font-size: 16px;font-weight: bold;text-align: left;padding-top: 10px;padding-left: 10px;">出价金额</div>
    <div class="canshu_zp flex">
      <Stepper  class="num" :min="num" v-model.number="num" input-width="70px"  button-size="80" disable-input ></Stepper>
    </div>

    <div id="pay" @click="push">
      立即出价
    </div>
  </van-popup>

</template>

<script>
import {Icon,Popup,Stepper} from 'vant';
export default {
    name: "Pmpay",
    props:[
      'total','user'
    ],
    data(){
        return {
          num:0,
        }
    },
    created() {
    },
    methods:{
      push(){
        this.$emit('pushprice',this.num)
      },
      close(){
        this.$emit('gbchujia')
      },
    },
    components:{
      [Popup.name]:Popup,[Icon.name]:Icon,Stepper
    },
    computed:{

    },
    watch:{
      total(){
        this.num = this.total+1;
      }
  }
}
</script>

<style scoped>
.title_zp{padding: 10px;margin-top:40px;border-bottom: 1px solid #eee;}
.canshu_zp{
  justify-content: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 50px;
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 30px;
}
.fangshi{
  justify-content: space-between;
  font-size: 18px;
  color: #8d8b8b;
}
#fangshi_img{
  width: 25px;
  position: absolute;
  right: 110px;
}
#fang_jian{
  position: relative;
  top:2px;
}
#pay{
  margin-top: 18px;
  padding: 15px;
  font-size: 20px;
  background: #AC3A35;
  color: white;
}
.js{
  justify-content: space-between;
}
.title{
  color: #8d8b8b;
  font-size: 14px;
}
.chujia_pic{
  width: 40px;
  border-radius: 999px;
  margin-right: 10px;
}
.chujia{
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.zi{
  padding-top: 10px;
}
.now_pirce{
  position: relative;
  top: 14px;
  font-weight: bold;
}

</style>
