<template>
  <div class="main">
    <nav-bar
        title="会员中心"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
      </template>
    </nav-bar>

    <div class="black">
      <div class="bg">
        <div class="flex p1">
          <div class="k1">
            <img src="@/assets/img/tu3.png" class="pic">
          </div>
          <div class="k2">
              <p class="k2_1">天工开物</p>
              <p class="k2_2">
                <Tag round plain class="k2_2_tag">标签</Tag>
                <Tag round plain class="k2_2_tag">标签</Tag>
                <Tag round plain class="k2_2_tag">标签</Tag>
              </p>
          </div>
        </div>
      </div>
    </div>

    <div class="di">
    </div>

    <div class="p2">
      <div class="flex p2_1">
        <p>会员权益</p>
        <p><Icon class="dan_1"  name="arrow" /></p>
      </div>
      <div class="p2_2 flex">
          <div class="list">
            <p><img class="vip_pic" src="../../../assets/img/vip-97.png"></p>
            <p class="num">0</p>
            <p class="vip">普通会员</p>
            <p class="zunxiang" style="color: #d2211b;font-weight: bold;">尊享权益</p>
            <p class="tequan">特权XX</p>
            <p class="tequan">特权XX</p>
            <p class="tequan">特权XX</p>
            <p class="tequan">特权XX</p>
            <p class="tequan">特权XX</p>
            <p class="tequan">特权XX</p>
          </div>
        <div class="list">
          <p><img class="vip_pic" src="../../../assets/img/vip-98.png"></p>
          <p class="num">5000</p>
          <p class="vip">超级会员V1</p>
          <p class="zunxiang" style="color: #348CB9;font-weight: bold;">尊享权益</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
        </div>
        <div class="list">
          <p><img class="vip_pic" src="../../../assets/img/vip-99.png"></p>
          <p class="num">40000</p>
          <p class="vip">超级会员V2</p>
          <p class="zunxiang" style="color: #EA9563;font-weight: bold;">尊享权益</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
        </div>
        <div class="list">
          <p><img class="vip_pic" src="../../../assets/img/vip-100.png"></p>
          <p class="num">150000</p>
          <p class="vip">超级会员V3</p>
          <p class="zunxiang" style="color: #349276;font-weight: bold;">尊享权益</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
          <p class="tequan">特权XX</p>
        </div>
      </div>
    </div>

<!--    -->




  </div>
</template>
<script>
import { NavBar, Button,Tag,Icon} from "vant";
export default {
  name: "Mypm",
  setup() {

  },
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      list:[

      ]
    }
  },
  created() {
  },
  methods:{
    onClickLeft(){
      this.$router.go(-1);
    }
  },
  components: {
    NavBar,[Button.name]:Button,Tag,Icon
  },
  computed: {}

}
</script>
<style>

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}




</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
}
.www{
  position: relative;
  bottom: 1px;
  z-index: 999;
}
.black{
  background: black;
  padding: 15px;
  position: relative;
  z-index: 2;
  bottom: 1px;
}
.bg{
  background: url("../../../assets/img/vip-94.png");
  width: 100%;
  height: 200px;
  background-size: 100% 100%;
}
.di{
  background: url("../../../assets/img/vip-95.png");
  width: 100%;
  height: 50px;
  background-size: 100% 100%;
  z-index: 99;
  position: absolute;
  top: 230px;
}
.p1 img{
  width: 50px;
  border-radius: 99px;
}
.p1{
  padding: 10px;
  font-size: 16px;
  color: black;
}
.k2{
  padding-left: 10px;
  text-align: left;
  padding-top: 2px;
}
.k2_2_tag{
  padding: 2px 6px;
}
.p2{
  margin: 10px;
  background: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
}
.p2_1{
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 20px;
}
.dan_1{
  position: relative;
  top: 4px;
}
.vip_pic{
  width: 50px;
}
.p2_2{
  margin: 10px 0px;
  justify-content: space-between;
}
.zunxiang{
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  margin: 9px 0px;
  padding: 5px 0px;
}
.list .tequan:last-child{
  border-bottom: 1px solid #000000;
}
.list{
  width: 22%;
}
.tequan{
  padding: 5px 0px;
}
.vip{
  color: #AC3A35;
}
.num{
  margin: 5px 0px;
  color: black;
  font-weight: bold;
  font-size: 14px;
}
</style>