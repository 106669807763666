<template>
  <div class="main">
    <div class="flex l_top">
      <router-link to="/">
        <div style="font-size: 20px;">X</div>
      </router-link>
    </div>
    <div class="flex denglu">
      {{title}}
    </div>
    <div class="form">
      <Field class="input" v-model="info.mobile"  required maxlength="11"  type="tel" label="手机号"  placeholder="请输入手机号"></Field>
      <Field class="input"
          v-model="info.sms"
          center
             required
          clearable
             maxlength="6"
          label="短信验证码"
          placeholder="请输入短信验证码"
      >
        <template #button>
          <Button size="small" class="yzm" type="default" @click="sms">{{code}}</Button>
        </template>
      </Field>
      <Field class="input" v-model="info.password"  required type="password" label="密 码" placeholder="请输入密码"></Field>
      <Field class="input" v-model="info.repassword" required type="password" label="确认密码" placeholder="确认密码"></Field>
      <Button id="denglu_btn" type="primary" @click="sumbit" size="large" color="#AC3C35">提交</Button>
    </div>

  </div>
</template>

<script>
import {Field,Button,Toast} from 'vant';
import {setKey} from "@/util/cache";

export default {
  name: "Rest",
  data(){
    return {
      sertxt:'',
      title:'',
      activeName:'index',
      code:'发送验证码',
      info:{
        mobile:'',
        password:'',
        repassword:'',
        sms:'',
      }
    }
  },
  created() {
    this.title = this.$route.query.rg==1?'注册账号':'重置密码';
    this.tag = this.$route.query.rg==1?'SmsReg':'SmsFindPwd';
  },
  methods:{
    sms(){
      if(typeof this.code == 'number') return;
      if(!(/^1[3456789]\d{9}$/.test(this.info.mobile))){
        Toast('手机号输入有误');
        return;
      }

      this.$api.sendRegSmsCode({phone:this.info.mobile,tag:this.tag}).then(r=>{
         if(r.code==200) {
           this.code = 60;

           this.time = setInterval(() => {
             if (this.code == 0) {
               clearInterval(this.time);
               this.code = '发送验证码';
               return;
             }
             this.code -= 1;
           }, 1000)
         }
        }
      )
    },
    sumbit(){
      if(!(/^1[3456789]\d{9}$/.test(this.info.mobile))){
        Toast('手机号输入有误');
        return;
      }

      if(this.info.sms.length!=6){
        Toast('验证码输入有误');
        return;
      }

      if(this.info.password.length<8 || this.info.repassword.length<8){
        Toast('请至少输入8位数的密码');
        return;
      }

      if(this.info.password != this.info.repassword){
        Toast('确认密码不一致');
        return;
      }

      if(this.$route.query.rg==1){
        this.$api.registerConfirm(this.info).then(r=>{
          if(r.code==200){
            setKey('Tg_token',r.data.token);
            this.$store.commit('user',r.data);
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/user')
          }
        })
      }else{
        this.$api.resetPasswordByPhone(this.info).then(r=>{
          if(r.code==200){
            // setKey('Tg_token',r.data.token);
            // this.$store.commit('user',r.data);
             this.$router.push('/login')
          }
        })
      }





    }
  },
  components:{
    Field,Button
  },
  computed:{

  }

}
</script>
<style>
.van-search__content{
  border-radius: 4px;
}
</style>
<style scoped>
.main{
  /*width: 100%;*/
  background: white;
  height: 95vh;
  color: black;
  padding: 15px;
}
.l_top{
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 20px;
}
.denglu{
  margin: 20px;
  font-size: 32px;
  font-weight: 400;

}
.form{
  margin-bottom: 20px;
}
#denglu_btn{
  width: 80%;padding: 10px;margin-top: 20px;
  margin-bottom: 10px;
}
.xieyi{
  font-size: 14px;
  color: #8d8b8b;
}
.xieyi span{
  color: black;
}
.qita{
  margin: 20px;
  margin-top: 20vh;
  color: #8d8b8b;
  font-size: 14px;
}
.si_icon{
  display: flex;
  justify-content: center;
}
.si_icon img{padding: 10px;width: 30px;height: 30px;}
.yzm{
  min-width: 40px;
}
</style>