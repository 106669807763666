<template>
  <div class="main">
    <div class="headder">
      <div class="flex kuang">
        <Search placeholder="茶器" background="#000000" class="index_serach" v-model="sertxt" show-action
                @search="onSearch"
                @cancel="onCancel"></Search>
      </div>
    </div>

    <van-tabs v-model="activeName" animated background="#000" color="#fff"
              title-active-color="#8f2526"
              title-inactive-color="#fff">
      <van-tab title="作品" name="index">
        <div class="sou">
          热门搜索
        </div>
        <div class="item">
          <p>和田玉</p>
          <p>和田玉</p>
          <p>和田玉</p>
          <p>和田玉</p>
          <p>和田玉</p>
          <p>和田玉</p>
        </div>
        <Button>全部分类</Button>
      </van-tab>
      <van-tab title="直播" name="b" ><Empty description="敬请期待"/></van-tab>
      <van-tab title="拍卖" name="c" ><Empty description="敬请期待"/></van-tab>
      <van-tab title="匠人" name="c" ><Empty description="敬请期待"/></van-tab>
    </van-tabs>

    </div>


    <Bottom></Bottom>
</template>

<script>
import {Tab, Tabs, Search, Empty,Button} from 'vant';
import Bottom from "@/components/Bottom";

export default {
  name: "Soso",
  data() {
    return {
      sertxt: '',
      activeName: 'index',
    }
  },
  created() {
  },
  methods() {

  },
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Search,
    Empty,
    Bottom,Button
  },
  computed: {}

}
</script>
<style>
.login_tips {
  width: 100%;
  position: fixed;
  bottom: 60px;
  z-index: 999;
  background: rgb(0 0 0 / 89%);
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
}
.van-search__action{
  color: white;
}
.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}

.van-search__content {
  border-radius: 4px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.van-tabs__content {
  background: #f9f9f9;
}

.van-tabs__line {
  bottom: 20px;
}



</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
}
.item{
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 15px;
  margin: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  color: #8d8b8b;
  font-size: 14px;
}
.item p{
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #8d8b8b;
  margin: 5px 7px;
}
.sou{
  display: flex;
  color: #8d8b8b;
  font-size: 18px;
  margin: 16px;
}
.quxiao{
  color: white;
  font-size: 16px;
}

.bars {
  font-size: 18px;
  position: relative;
  top: 2px
}

.fenlei {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #fff;
  z-index: 9999;
  font-size: 14px;
  font-weight: bold
}

#fename {
  position: relative;
  bottom: 2px;
}

.headder {
  background: #000000;
  padding: 14px 20px 0px 10px;
  position: relative;
}

.index_serach {
  padding: 0;
  width: 100%;
  padding-right: 5px;
}

.kuang {
  justify-content: space-between;
}

.card {
  font-size: 30px;
  padding-left: 10px;
}

</style>