<template>
  <div>
    <van-nav-bar
        title="消息"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
    </van-nav-bar>

    <div class="flex lan_one">
      <p><img src="@/assets/img/ms1.png">
        <span>最新评论</span>
      </p>
      <p><img src="@/assets/img/ms2.png">
        <span>我的收藏</span>

      </p>
      <p><img src="@/assets/img/ms3.png">
        <span>我的粉丝</span>

      </p>
      <p><img src="@/assets/img/ms4.png">
        <span>社区通知</span>
      </p>
    </div>

    <div class="flex lan_two">
      <div class="mess flex" v-for="(v,k) in mess" :key="k">
        <p><img class="lan_pic" :src="v.img"></p>
        <p>
          <span>{{v.name}}</span>
          <span>{{v.desc}}</span>
        </p>
      </div>
    </div>
  </div>
  <Bottom></Bottom>
</template>

<script>
import Bottom from "@/components/Bottom";
import {NavBar} from 'vant'
export default {
    name: "Message",
    data(){
        return {
            mess:[
              {
                id:1,
                img:require('../../assets/img/tu3.png'),
                name:'xxxx',
                desc:'xxxxxxxxxxxx'
              },
              {
                id:1,
                img:require('../../assets/img/tu3.png'),
                name:'xxxx',
                desc:'xxxxxxxxxxxx'
              }
            ]
        }
    },
    created() {
    },
    methods:{
      onClickLeft(){
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
      },
    },
    components:{
      Bottom,[NavBar.name]:NavBar
    },
    computed:{
    
    }
}
</script>

<style scoped>
.lan_one{
  color: black;
  padding: 20px;
  background: white;
  font-size: 14px;
  justify-content: space-between;
}
.lan_one img{
  width: 40px;
  height: 40px;
}
.lan_one span{
  display: block;
}
.lan_two{
  background: white;
  margin-top: 10px;
  flex-wrap: wrap;
}
.mess{
  border-bottom: 1px solid #dbd9d9;
  padding: 10px;
  width: 100%;
}
.lan_pic{
  width: 55px;
  border-radius: 35px;
  height: 55px;
}

.lan_two span{
  display: block;
  text-align: left;
  padding-left: 20px;
  color: black;
  font-size: 20px;
}
</style>
