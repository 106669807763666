<template>
	<van-popup v-model:show="zpshow" closeable close-icon="close" close="close" position="bottom" :style="{}">
		<div class="title_zp">确认付款</div>
		<div class="canshu_zp flex">
			<div>¥{{total}}</div>
		</div>
		<div class="flex title_zp fangshi">
			<p style="">支付方式</p>
			<p v-if="paytype=='weChatPay'"><img id="fangshi_img" src="@/assets/img/wechat.png">微信支付
				<van-icon id="fang_jian" name="arrow"></van-icon>
			</p>
			<p v-else><img id="fangshi_img" class="zfb" src="@/assets/img/zfb.jpg">支付宝
				<van-icon id="fang_jian" name="arrow"></van-icon>
			</p>
		</div>

		<div id="pay" @click="pay">
			安全支付
		</div>

		<div id="param"></div>

	</van-popup>

</template>

<script>
	import {
		Icon,
		Popup,
		Toast
	} from 'vant';
	import config from "../../config.base"
	export default {
		name: "Pay",
		props: [
			'total', 'orderId', 'master_order_sn'
		],
		data() {
			return {
				zpshow: true,
				pay_radio: 'pay_code=',
				paytype: 'weChatPay'
			}
		},
		created() {
			if (this.orderId == undefined || this.orderId <= 0) {
				Toast('参数错误');
			}
			if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) != 'micromessenger') {
				this.paytype = 'alipayMobile';
			}
			// alert(this.master_order_sn);
		},
		methods: {
			pay() {
				window.location.href = `${config.baseURL}/NewApi/open/pay?order_id=${this.orderId}&master_order_sn=${this.master_order_sn}&pay_radio=${this.pay_radio+this.paytype}&host=${window.location.href}`;
				// this.$api.pay('crow', {
				// 	order_id: this.orderId,
				// 	master_order_sn: this.master_order_sn,
				// 	pay_radio: this.pay_radio+this.paytype,
				// 	host: window.location.host,
				// }).then(r => {
				// 	if (r.code == 200) {
				// 		// console.log(r);
				// 		document.getElementById('param').innerHTML = r.data.code_str;
				// 		document.forms['alipaysubmit'].submit();
				// 	} else {
				// 		this.$router.push('/order')
				// 	}
				// })
			},
			close() {
				this.$emit('close', true);
			}
		},
		components: {
			[Popup.name]: Popup,
			[Icon.name]: Icon
		},
		computed: {

		}
	}
</script>

<style scoped>
	.title_zp {
		padding: 10px;
		border-bottom: 1px solid #eee;
		font-size: 18px;
	}

	.canshu_zp {
		justify-content: center;
		padding: 10px 20px;
		flex-wrap: wrap;
		display: flex;
		margin-top: 50px;
		font-size: 44px;
		font-weight: bold;
		margin-bottom: 30px;
	}

	.fangshi {
		justify-content: space-between;
		font-size: 18px;
		color: #8d8b8b;
	}

	#fangshi_img {
		width: 25px;
		position: absolute;
		right: 110px;
	}

	#fang_jian {
		position: relative;
		top: 2px;
	}

	#pay {
		margin-top: 18px;
		padding: 15px;
		font-size: 20px;
		background: #AC3A35;
		color: white;
	}

	.zfb {
		width: 50px;
		right: 100px !important;
	}
</style>
