<template>
  <div class="main">
    <div class="bg">
<!--      one-->
      <div class="flex p1">
        <div class="flex">
          <p><img class="avatar" :src="user.head_pic"></p>
          <div class="p1_1">
            <p class="name">{{user.nickname}}</p>
            <p class="biaoqian">
              <Tag round plain size="medium" >{{udata.level_name}}</Tag>
            </p>
          </div>
        </div>
           <div class="p1_2" @click="$router.push('/infoedit')">
            <Icon name="setting-o" />
           </div>
      </div>

<!--      four-->

      <div class="flex four">
        <div>
          <p class="num">0</p>
          <p class="zi">关注</p>
        </div>
        <div>
          <p class="num">0</p>
          <p class="zi">粉丝</p>
        </div>
        <div>
          <p class="num">{{udata.goods_collect_count}}</p>
          <p class="zi">收藏</p>
        </div>
        <div @click="$router.push('evaluatelist')">
          <p class="num">{{udata.comment_count}}</p>
          <p class="zi">评价</p>
        </div>
      </div>

<!--      red-->
      <div class="red flex">
        <div class="flex ss">
          <p><img src="@/assets/img/user_1.png"></p>
          <div @click="empty">
            <p class="red_1">会员中心</p>
            <p class="red_2">最新权益<Icon class="red_3" name="arrow" /></p>
          </div>
        </div>
        <div class="flex" @click="empty">
          <p><img src="@/assets/img/user_2.png"></p>
          <div>
            <p class="red_1">优惠卷</p>
            <p class="red_2">最新权益<Icon class="red_3" name="arrow" /></p>
          </div>
        </div>
      </div>
    </div>

<!--    订单-->
    <div class="p2" @click="$router.push('/order')">
        <div class="flex js">
          <p class="mydan">我的订单</p>
          <p><Icon class="dan_1"  name="arrow" /></p>
        </div>

      <div class="flex five">
        <div class="dingdan">
          <p class="num"><img class="num_p" src="@/assets/img/dan61.png"></p>
          <p class="zi">待付款</p>
        </div>
        <div class="dingdan">
          <p class="num"><img class="num_p" src="@/assets/img/dan62.png"></p>
          <p class="zi">待发货</p>
        </div>
        <div class="dingdan">
          <p class="num"><img class="num_p" src="@/assets/img/dan63.png"></p>
          <p class="zi">待收货</p>
        </div>
        <div class="dingdan" @click="$router.push('/pl')">
          <p class="num"><img class="num_p" src="@/assets/img/dan64.png"></p>
          <p class="zi">待评价</p>
        </div>
        <div class="dingdan" @click="empty">
          <p class="num"><img class="num_p" src="@/assets/img/dan65.png"></p>
          <p class="zi">售后服务</p>
        </div>
      </div>
    </div>

    <!--    轮播-->
    <Swipe :autoplay="3000" class="lb">
      <SwipeItem v-for="(image, index) in images" :key="index">
        <img :src="image" />
      </SwipeItem>
    </Swipe>

<!--    八个-->
    <div class="p3">
      <div class="flex five">
        <div class="dingdan ss" @click="$router.push('/mypm')">
          <p class="num"><img class="num_p_1" src="@/assets/img/b66.png"></p>
          <p class="zi">我的拍卖</p>
        </div>
        <div class="dingdan ss"  @click="$router.push('/dh')">
          <p class="num"><img class="num_p_1" src="@/assets/img/b69.png"></p>
          <p class="zi">我的搭伙</p>
        </div>
        <div class="dingdan ss" @click="empty">
          <p class="num"><img class="num_p_1" src="@/assets/img/b67.png"></p>
          <p class="zi">我的学习</p>
        </div>
        <div class="dingdan ss" @click="empty">
          <p class="num"><img class="num_p_1" src="@/assets/img/b68.png"></p>
          <p class="zi">保证金</p>
        </div>
      </div>

      <div class="flex five">
        <div class="dingdan ss" @click="$router.push('/address')">
          <p class="num"><img class="num_p_1_shu" src="@/assets/img/b70.png"></p>
          <p class="zi">收货地址</p>
        </div>
        <div class="dingdan ss" @click="empty">
          <p class="num"><img class="num_p_1_shu k" src="@/assets/img/b71.png"></p>
          <p class="zi">客服中心</p>
        </div>
        <div class="dingdan ss" @click="empty">
          <p class="num"><img class="num_p_1_shu" src="@/assets/img/b72.png"></p>
          <p class="zi">意见反馈</p>
        </div>
        <div class="dingdan ss" @click="empty">
          <p class="num"><img class="num_p_1_shu" src="@/assets/img/b73.png"></p>
          <p class="zi">邀请好友</p>
        </div>
      </div>
      <div class="flex five" >
        <div class="dingdan ss" @click="$router.push('/kaidian')">
          <p class="num"><Icon class="biao" name="cashier-o" /></p>
          <p class="zi">商家申请</p>
        </div>
      </div>
    </div>

    <div class="exit" @click="exit">
      注销登录
    </div>
  </div>
  <Bottom></Bottom>
</template>

<script>
import Bottom from "@/components/Bottom";
import {Icon,Tag,Swipe,SwipeItem,Toast} from 'vant';
import {removeKey} from "@/util/cache";

export default {
    name: "Index",
    data(){
        return {
          images: [
            'https://img01.yzcdn.cn/vant/apple-1.jpg',
            'https://img01.yzcdn.cn/vant/apple-2.jpg',
          ],
          user:{
            nickname:'开工开物用户',
            head_pic:'../../assets/img/tu3.png',
          },
          udata:{},
        }
    },
    created() {
      this.$api.userinfo().then(r=>{
        if(r.code==200){
          this.user = r.data;
          if(this.user.head_pic==null) this.user.head_pic='/avatar.png';
        }
      })
      this.$api.userindex().then(r=>{
        if(r.code==200){
          this.udata = r.data;
        }
      })
    },
    methods:{
      exit(){
        this.$api.exit_useri().then(r=>{
          if(r.code==200){
            removeKey();
            this.$router.push('/login');
          }
        })
      },
      empty(){
        Toast('暂未开放');
      }
    },
    components:{
      Bottom,Icon,Tag,Swipe,SwipeItem
    },
    computed:{

    }
}
</script>

<style scoped>
  .main{
    margin-bottom: 80px;
  }
  .bg{
    background: url("../../assets/img/user_bg.png");
    background-size:100% 100%;
    width: 100%;
    height: 200px;
  }
  .avatar{
    width: 50px;
    border-radius: 55px;
  }
  .p1{
    padding: 20px;
    color: white;
    text-align: left;
    justify-content: space-between;
  }
  .p1_1{
    padding-left: 12px;
  }
  .name{
    font-size: 20px;
    height: 30px;
    width: 148px;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
  }
  .four{
    color: white;
    font-size: 14px;
    justify-content: space-between;
    padding: 0px 30px;
  }
  .num{
    font-size: 22px;
    font-weight: bold;
  }
  .red{
    background: #AC3C35;
    padding: 10px;
    color: white;
    margin: 0px 20px;
    font-size: 16px;
    margin-top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    text-align: left;
    justify-content: space-between;
  }
  .red img{
    height: 40px;
    padding-right: 10px;
  }
  .red_1{
    color: #DFE5C0;
    font-weight: bold;
  }
  .flex.ss{
    border-right: 1px solid #000000;
    padding-right: 25px;
  }
  .red_2{
    color: black;
    font-size: 14px;
  }
  .red_3{
    position: relative;
    top:2px;
  }
  .p2{
    margin: 14px;
    margin-top:50px;
    background: white;
    padding: 10px;
    border-radius: 15px;
  }

  .p3{
    margin: 14px;
    margin-top:10px;
    background: white;
    padding: 10px;
    border-radius: 15px;
  }

  .js{
    justify-content: space-between;
  }
  .mydan{
    font-size: 16px;
    font-weight: bold;
  }
  .dan_1{
    font-size: 16px;
    position: relative;
    bottom: 4px;
  }
  .five{
    color: black;
    font-size: 14px;
    margin-top: 3px;
    justify-content: space-between;
    /*padding: 0px 10px;*/
  }
  .num_p{
    width: 42px;
    height: 34px;
  }
  .dingdan{
    width: 20%;
  }
  .lb{
    height: 120px;
    margin: 10px;
    border-radius: 15px;
  }
  .lb img{
    /*height: 150px;*/
    width: 100%;
    object-fit:contain;
  }
  .num_p_1{
    width: 27px;
    height: 30px;
  }
  .num_p_1_shu{
    width: 27px;
    height: 38px;
  }
  .num_p_1_shu.k{
    width: 34px;
    height: 38px;
  }
  .dingdan.ss{
    padding-top: 10px;
    height: 60px;
  }
  .biaoqian{
    position: relative;
    bottom: 0.21rem;
    color: black;
  }
  .exit{
    border: 1px solid #eeeeee;
    margin: 15px;
    padding: 5px;
    border-radius: 10px;
    background: #AC3A35;
    color: #eeeeee;
    font-size: 20px;
  }
.biao{
  margin-top: 3px;
  font-size: 35px;
  color: #383838;
}
</style>
