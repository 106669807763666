<template>
  <div class="bg">
    <van-nav-bar
        title="确认订单"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />

    <div class="p1" @click="address">
        <template v-if="order.default_address.length==0">
            <div class="address">点击添加收货地址</div>
        </template>
        <template v-else>
            <div class="address">{{ order.default_address.address }}</div>
            <div id="jiantou">
              <van-icon id="pj_right" name="arrow"></van-icon>
            </div>
            <div class="name flex">
              <div>{{ order.default_address.name }}</div>
              <div>{{ order.default_address.tel }}</div>
            </div>
        </template>
      
    </div>

    <div class="card_my">
      <div class="qname">
        <img :src="order.order_detail.store_logo" class="qpic">
        {{ order.order_detail.store_name }}
      </div>
      <van-swipe-cell style="position: relative;">
        <van-card
            class="goods-card"
            :thumb="order.order_detail.img"
        >
          <template #title>
            <p class="title">{{ order.order_detail.title }}</p>
            <p class="desc">{{ order.order_detail.subtitle }}</p>
          </template>
          <template #price>
            <p class="price_s">¥ {{ order.order_detail.price }}</p>
          </template>
          <template #footer>
            <Stepper class="num" min="1" v-model="num"></Stepper>
          </template>
        </van-card>
        <template #right>
          <van-button square style="height: 100%" text="删除" type="danger" class="delete-button"/>
        </template>
      </van-swipe-cell>
    </div>


    <div class="p1">
      <div class="address">留言</div>
      <div id="zishu">{{ liuyan.length }}/140</div>
      <textarea class="liuyan flex" v-model="liuyan"/>
    </div>

  </div>

  <div class="flex bottom">
    <div class="p10">
      <div class="heji">合计：<span class="qian">¥{{ total }}</span></div>
      <div>
        <Button id="dingdan" @click="pay">确认订单</Button>
      </div>
    </div>
  </div>

  <Pay v-if="payshow" :total="total" :order-id="oid"></Pay>


</template>

<script>
import {NavBar, Toast, Button, Icon, Checkbox, SwipeCell, Stepper, Card, Popup} from 'vant';
import Pay from "@/components/Pay";
import {mapState} from 'vuex'

export default {
  name: "Checkin",
  data() {
    return {
      zpshow: true,
      payshow: false,
      pid: 0,
      type: '',
      level_id: 0,
      oid: 0,
      s_id: 0,
      liuyan: '',
      list: {
        loading: false,
        finished: true,
      },
      num: 1,
      order: {default_address: {name: ''}, order_detail: {}},
      cats: [
        {
          qname: '天工开物',
          qpic: require('../../../assets/img/tu3.png'),
          qid: 1,
          check: true,
          list: [
            {
              image: require('../../../assets/img/tu3.png'),
              title: '商品商品商品商品商品商品商品',
              desc: 'xxxxxxxxxxxxxxxxxx',
              num: 1,
              price: 99,
              check: true,
              id: 1,
            },
            {
              image: require('../../../assets/img/tu3.png'),
              title: '商品',
              desc: 'xxxxxxxxxxxxxxxxxx',
              num: 1,
              price: 99,
              check: false,
              id: 2,
            },
          ]
        },
      ],

    }
  },
  created() {
    this.pid = this.$route.query.id;
    if (this.$route.query.level_id != undefined) {
      this.type = 'crowd';
      this.crowd();
    } else {
      this.type = 'assemble';
      if (this.$route.query.isAssembleSingle) {
        this.assembleSingle();
      } else {
        this.assemble();
      }
    }


  },
  methods: {
    onClickLeft() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },

    // 搭伙单独购买
    assembleSingle: function () {
      this.s_id = this.$route.query.s_id;
      if (this.pid <= 0 || this.pid == undefined) {
        Toast('参数错误');
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        return;
      }

      this.$api.assembleSingleOrder({act_id: this.pid, address_id: this.$route.query.s_id}).then(r => {
          this.order = r.data;
        if (r.data.default_address.length <= 0) {
          Toast('请先添加收货地址');
          return false;
        }
        if (this.$route.query.s_id == undefined) {
          this.s_id = r.data.default_address.id;
        }
      })
    },

    assemble() {
      this.s_id = this.$route.query.s_id;
      if (this.pid <= 0 || this.pid == undefined) {
        Toast('参数错误');
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        return;
      }
      this.$api.factionorder({act_id: this.pid, address_id: this.$route.query.s_id}).then(r => {
          this.order = r.data;
        if (r.data.default_address.length <= 0) {
          Toast('请先添加收货地址');
          return false;
          // this.$router.push('address')
        }
        if (this.$route.query.s_id == undefined) {
          this.s_id = r.data.default_address.id;
        }
      })
    },
    crowd() {
      this.level_id = this.$route.query.level_id;
      this.s_id = this.$route.query.s_id;
      if (this.pid <= 0 || this.level_id <= 0 || this.pid == undefined || this.level_id == undefined) {
        Toast('参数错误');
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        return;
      }
      this.$api.croworder({act_id: this.pid, level: this.level_id, address_id: this.$route.query.s_id}).then(r => {
        console.log(r);
        if (r.data.default_address.length <= 0) {
          Toast('请先添加收货地址');
          return false;
          // this.$router.push('address')
        }
        this.order = r.data;
        if (this.$route.query.s_id == undefined) {
          this.s_id = r.data.default_address.id;
        }
      })
    },
    pay() {
        if(this.order.default_address.length==0){
            Toast('请先添加收货地址');
            return false;
        }
      this.zpshow = true;
      var axios;
      if (this.type == 'crowd') {
        axios = this.$api.orderConfirm('crow', {
          'act_id': this.pid,
          'level': this.level_id,
          'notice': this.liuyan,
          'order_num': this.num,
          'address_id': this.s_id,
        });
      } else if (this.type == 'assemble') {
        console.log(this.share_code);
        if (this.$route.query.isAssembleSingle) {
          axios = this.$api.orderConfirm('assembleSingle', {
            'act_id': this.pid,
            // 'joinTeam':this.$route.query.joinTeam?this.$route.query.joinTeam:'',
            'notice': this.liuyan,
            'order_num': this.num,
            'joinTeam': this.share_code,
            'address_id': this.s_id,
          });
        } else {
          axios = this.$api.orderConfirm('assemble', {
            'act_id': this.pid,
            // 'joinTeam':this.$route.query.joinTeam?this.$route.query.joinTeam:'',
            'notice': this.liuyan,
            'order_num': this.num,
            'joinTeam': this.share_code,
            'address_id': this.s_id,
          });
        }
      }
      console.log(this.type);
      axios.then(r => {
        if (r.data.order_id != undefined) {
          this.payshow = true;
          this.oid = r.data.order_id;
        }
      })
    },
    address() {
      if (this.type == 'crowd') {
        this.$router.push('/address?id=' + this.pid + '&level_id=' + this.level_id)
      } else {
        this.$router.push('/address?id=' + this.pid + '&tuan=1')
      }
    }
  },
  components: {
    [NavBar.name]: NavBar, Button, [Icon.name]: Icon,
    [Checkbox.name]: Checkbox, [SwipeCell.name]: SwipeCell, [Stepper.name]: Stepper, [Card.name]: Card,
    Stepper, [Popup.name]: Popup, Pay
  },
  computed: {
    total() {
      var b = 0;
      if (this.order.order_detail.price != undefined) {
        b = this.order.order_detail.price * this.num;
      }
      return b;
    },
    ...mapState(['share_code'])
  },
  watch: {}
}
</script>
<style scoped>
.p1 {
  margin: 10px;
  background: white;
  border-radius: 6px;
  padding: 10px;
  position: relative;
}

.title_zp {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.canshu_zp {
  justify-content: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 50px;
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 30px;
}

.qname {
  font-size: 16px;
  display: flex;
  margin-bottom: 18px;
  line-height: 30px;
}

.qpic {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 8px;
}

.bg {
  background: #f9f9f9;
}

.bottom {
  position: fixed;
  bottom: 0px;
  background: #F6F6F6;
  width: 100%;
}

.p10 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.qian {
  color: #d2211b;
}

.heji {
  font-size: 18px;
  margin-top: 10px;
  color: black;
}

#dingdan {
  border-radius: 5px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
  padding-right: 30px;
  padding-left: 30px;
}

.address {
  text-align: left;
  font-size: 16px;
}

#jiantou {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.name {
  color: #8d8b8b;
}

.name div {
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
}


.card_my {
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.title {
  font-size: 14px;
  text-align: left;
  width: 160px;
  padding-left: 10px;
}

.price_s {
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 58px;
  font-size: 18px;
  font-weight: bold;
}

.num {
  position: absolute;
  bottom: 10px;
  left: 226px;
}

#zishu {
  position: absolute;
  right: 8px;
  font-size: 16px;
  font-weight: 400;
  bottom: 5px;
}

.liuyan {
  height: 60px;
  font-size: 16px;
  width: 100%;
  padding-top: 10px;
}

.desc {
  font-size: 14px;
  text-align: left;
  width: 160px;
  position: relative;
  right: 5px;
}
</style>
