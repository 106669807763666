<template>
  <img v-if="pj.div_icon" :src="pj.div_icon" class="pjj">
</template>

<script>

export default {
  name: "Pj",
  props:[
    'label'
  ],
  data(){
    return {
      pj:{
        'div_color':null,
        'div_icon':null,
        'div_name':null
      },
    }
  },
  created() {
    if(this.label!='' && this.label!==null){
      this.pj = this.label;
    }
  },
  methods:{

  },
  components:{
  },
  computed:{

  },
  watch:{

  }
}
</script>

<style scoped>

</style>
