<template>
  <div class="bg">
    <nav-bar
        title="信息修改"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
      </template>
    </nav-bar>
    <van-form @submit="onSubmit">
      <van-field
          v-model="user.nickname"
          name="昵称"
          label="昵称"
          placeholder="昵称"
      />
      <van-field
          v-model="user.mobile"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          maxlength="11"
      />
      <van-field
          v-model="user.email"
          name="邮箱"
          label="邮箱"
          placeholder="邮箱"
      />
      <van-field name="radio" label="性别">
        <template #input>
          <van-radio-group v-model="user.sex" direction="horizontal">
            <van-radio name="0">保密</van-radio>
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
          v-model="user.password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="smp">提交</van-button>
      </div>
    </van-form>


    <!--    <div>1111</div>-->
  </div>
</template>

<script>
import { NavBar,Toast,Form,Field,Button,Radio,RadioGroup } from 'vant';

export default {
  name: "Research",
  data(){
    return {
      activeName:'index',
      user:{
        nickname:'',
        password:'',
        mobile:'',
        sex:'0',
        email:'',
      }
    }
  },
  created() {
    this.$api.userinfo().then(r=>{
      this.user = r.data;
    })
  },
  methods:{
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    onClickRight() {
      Toast('按钮');
    },
    smp(){
      if(!this.user.mobile!='' && !(/^1[3456789]\d{9}$/.test(this.user.mobile))){
        Toast('手机号输入有误');
        return;
      }
      this.$api.put_userinfo(this.user).then(r=>{
        Toast(r.msg);
      })
    }
  },
  components:{
    NavBar,[Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
    [Radio.name]:Radio,
    [RadioGroup.name]:RadioGroup,
  },
  computed:{

  }
}
</script>
<style>
@import "../../style/header.css";

</style>
<style scoped>
.bg{
  background: #f9f9f9;
}

</style>
