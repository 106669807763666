<template>
    <div class="bg">
        <van-nav-bar
                title="时尚首饰"
                left-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                style="background: black"
        >
            <template #right>
                <span class="cat" @click="showSidebar">筛选</span>
            </template>
        </van-nav-bar>
    </div>

        <List  v-model:loading="list.loading"
               :finished="list.finished"
               finished-text="没有更多了"
               error-text="请求失败，点击重新加载"
               @load="onLoad"
        >
            <div class="goods_list">
            <template v-for="(v,k) in goods_data" :key="k">
                <Goods :="v"></Goods>
            </template>
            </div>

        </List>

    <!--右边框-->
    <Popup v-model:show="showBar" position="right" class="barx" >
        <div class="jiage_k">
            <p class="jiage_p" >价格区间</p>
            <p >
                <input class="jiage" placeholder="最低价"> -
                <input class="jiage" placeholder="最高价">
            </p>
            <p class="bottom_s">
              <button class="rest a">重置</button>
              <button class="rest b">完成</button>
            </p>
        </div>
    </Popup>


</template>

<script>
    import {NavBar, Toast,List,Popup } from 'vant';
    import Goods from "@/components/Goods";
    export default {
        name: "Product",
        data() {
            return {
                goods_data:[
                    {title:'商品名',price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
                    {title:'商品名',price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
                    {title:'商品名',price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
                ],
                showBar:true,
                list:{
                    loading:false,
                    finished:true,
                }
            }
        },
        created() {
        },
        methods: {
            onClickLeft(){
              window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            },
            onClickRight() {
                Toast('按钮');
            },
            showSidebar(){
                this.showBar = !this.showBar;
            }
        },
        components: {
            [NavBar.name]:NavBar,Goods,List,Popup
        },
        computed: {}
    }
</script>
<style>
    .barx{
        height: 100%;
        width: 280px;
        padding-top: 70px;
        color: #8d8b8b;
        display: flex;
        padding-left: 20px;
        font-size: 18px;
    }

</style>
<style scoped>

    .bg{
        background: #f9f9f9;
    }
    .zhongchou{
        background: #AC3C35;
        color: white;
        padding: 10px 0px;
        position: fixed;
        font-size: 22px;
        bottom: 0px;
        width: 100%;
    }
    .goods_list{
        padding-left: 5px;
    }
    .cat{
        color: #cccccc;
    }
    .jiage{
        border: 1px solid #c3c0c0;
        padding: 5px;
        background: #eeeeee;
        width: 110px;
        font-size: 18px;
    }

    .jiage_k{
        text-align: left;
      position: relative;

    }

    input::-webkit-input-placeholder{
      font-size: 16px;
    }
    input::-ms-input-placeholder{
      font-size: 16px;
    }

    .jiage_p{
        padding-bottom: 10px;
    }
    .bottom_s{
      position: absolute;
      bottom: 50px;
      display: flex;
    }
    .rest{
      padding: 10px;
      width: 130px;
      color: white;
    }
    .rest.a{
      background: #D76A31;
      border-bottom-left-radius:5px;
      border-top-left-radius:5px;
    }
    .rest.b{
      background: #AB3C35;
      border-bottom-right-radius:5px;
      border-top-right-radius:5px;
    }

</style>
