//src\util\cache.js
import Cookies from 'js-cookie'

const TokenKey = 'Tg_token'

//获取
export function getKey (Key=TokenKey) {
    return Cookies.get(Key)
}

//设置
export function setKey (key=TokenKey,val='') {
    return Cookies.set(key, val)
}

//删除
export function removeKey (Key=TokenKey) {
    return Cookies.remove(Key)
}

//示例
//src\view\test\Myindex.vue
//引入
// import { getToken, setToken} from '@/util/cache'
//
// methods: {
//     gettoken(){
//         this.token = getToken;
//     },
//     settoken(){
//         var str = "wo shi she zhi de"
//         setToken(str);
//     }
// }