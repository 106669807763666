<template>
  <div>
    <van-nav-bar
        title="商品XXX"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
        <van-icon name="share" size="18" style="color: white;" />
      </template>
    </van-nav-bar>

    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <template v-for="(v,k) in images" :key="k">
        <van-swipe-item @click="show_img(k)">
          <img class="ms_img" v-lazy="v" />
        </van-swipe-item>
      </template>
    </van-swipe>

    <div class="p1">
      <div class="flex p1_1">
        <p class="money">¥88 <span class="txtdel">¥99</span></p>
        <p class="kucun">剩余{{good_info.num}}件</p>
      </div>
      <div class="title flex">{{good_info.title}}</div>
    </div>

    <div class="p1">
      <div class="p1_info">
        <p>· 正品保障</p>
        <p>· 七天无理由</p>
      </div>
        <button class="canshu_cat" @click="cat_zp" >作品参数</button>
    </div>


    <van-popup
        v-model:show="zpshow"
        closeable
        close-icon="close"
        position="bottom"
        :style="{}"
    >
      <div class="title_zp" >作品参数</div>
      <div class="canshu_zp flex">
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
      </div>

    </van-popup>


    <div class="p1 dd" >
      <div class="flex pj">
        <p>作品评价 ( {{good_info.pingjia}} )</p>
        <p class="more">查看更多 <van-icon id="pj_right" name="arrow"></van-icon></p>
      </div>
      <template v-for="(va,ks) in good_info.pl" :key="ks">
        <div class="flex pj_1" >
          <div class=" flex">
            <p ><img class="avatar" v-lazy="va.avatar"></p>
            <div class="nickname">
              {{va.nickname}}
              <p class="star"><Rate v-model="va.star" size="12"></Rate></p>
            </div>

          </div>
          <div class="createtime">{{va.craete_time}}</div>
        </div>
        <div class="descx">
          <template v-for="(p,pk) in va.pics" :key="pk">
            <img v-lazy="p">
          </template>
          <div class="desc_text">{{va.desc}}</div>
        </div>
      </template>
    </div>

    <van-action-bar class="bar">
      <van-action-bar-icon icon="shop-o" text="店铺" @click="onClickIcon" />
      <van-action-bar-icon icon="chat-o" text="私信" @click="onClickIcon" />
      <van-action-bar-icon icon="star-o" text="收藏" @click="onClickIcon" />
      <van-action-bar-button type="warning" text="加入购物车" color="#D76A31" />
      <van-action-bar-button type="danger" text="立即购买" color="#AB3C35" />
    </van-action-bar>

  </div>
</template>

<script>
import {NavBar, Toast,Swipe,SwipeItem,ImagePreview,Icon,Rate,
  ActionBar, ActionBarIcon, ActionBarButton,Popup} from 'vant';

export default {
  name: "Good",
  data() {
    return {
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      show:false,
      zpshow:false,
      index:1,
      good_info:{
        num:0,
        title:'祖传陶瓷碗',
        pingjia:88,
        pl:[
          {
            avatar:require('../../assets/img/tu3.png'),
            star:5,
            nickname:'小天',
            pics:[
              'https://img01.yzcdn.cn/vant/apple-2.jpg',
              'https://img01.yzcdn.cn/vant/apple-2.jpg',
              'https://img01.yzcdn.cn/vant/apple-2.jpg'
            ],
            desc:'vergoods',
            craete_time:'xxx-xx-xx'
          },
        ]
      }
    }
  },
  created() {
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    show_img(k){
      ImagePreview({
        images:this.images,
        startPosition: k,
      });
    },
    cat_zp(){
      this.zpshow=true;
    }
  },
  components: {
    [NavBar.name]:NavBar,
    [Swipe.name]:Swipe,
    [SwipeItem.name]:SwipeItem,
    [Icon.name]:Icon,
    [ActionBar.name]:ActionBar, [ActionBarIcon.name]:ActionBarIcon, [ActionBarButton.name]:ActionBarButton,
    Rate,[Popup.name]:Popup
  },
  computed: {}
}
</script>

<style scoped>
.my-swipe{
  width: 100%;
  height: 340px;
}
.p1_info{
  text-align: left;
  display: flex;
  font-size: 14px;
  color: #8d8b8b;
}
.p1_info p{
  padding-right: 10px;
}
.bar{padding:10px;}
.ms_img{
  width: 100%;
  height: 340px;
  object-fit: cover;
}
.p1{
  margin: 10px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.p1_1{
  justify-content: space-between;
}
.pj{
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 16px;
  color: black;
}
.more{
  font-size: 14px;
  color: #8d8b8b;
  position: relative;
  top: 2px;
}
#pj_right{position: relative;top: 2px}
.money{color: #d2211b;font-weight: bold}
.money span{color: #4e4949;font-weight: lighter;font-size: 16px;}
.kucun{color: #8d8b8b;font-size: 14px;position: relative;top: 10px}
.title{justify-content: start;padding-top: 5px;font-size: 16px;font-weight: bold;color: black;}
.avatar{width: 40px;border-radius: 99px;}
.nickname{color: #8d8b8b;font-size: 14px;padding-left: 10px;text-align: left;}
.pj_1{justify-content: space-between;}
.createtime{color: #8d8b8b;font-size: 16px;}
.descx{justify-content: start;text-align: left;padding-left: 20px;font-size: 16px;padding-top: 5px;}
.descx img{width: 80px;padding: 10px;}
.desc_text{padding-top: 10px;}
.dd{margin-bottom: 70px;}
.title_zp{padding: 10px;border-bottom: 1px solid #eee;}
.canshu_zp{
  justify-content: space-between;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
}
.line_zp{
  padding-bottom: 20px;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.canshu_cat{
  font-size: 14px;
  padding: 5px;
  position: absolute;
  right: 13px;
  bottom: 5px;
  border-radius: 8px;
  background: #be3d47;
  color: white;
}
</style>
