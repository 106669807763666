import Index from '@/views/attachment/Index';

export default [
    {
        path: '/attachment',
        name: 'Attachment',
        component: Index,
        meta: {
            title: '下载中心',
        },
    }
];