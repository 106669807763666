<template>
  <div class="bg">
    <van-nav-bar
        title="积分兑换"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />
  </div>

  <List  v-model:loading="list.loading"
         :finished="list.finished"
         finished-text="没有更多了"
         error-text="请求失败，点击重新加载"
         @load="onLoad"
  >
    <div class="goods_list">
      <template v-for="(v,k) in goods_data" :key="k">
        <Goods :="v"></Goods>
      </template>
    </div>

  </List>

  <div class="zhongchou">
    兑换
  </div>

</template>

<script>
import {NavBar, Toast,List} from 'vant';
import Goods from "@/components/Goods";
export default {
  name: "Exchange",
  data() {
    return {
      goods_data:[
        {title:'商品名',jifen:888,price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
        {title:'商品名',jifen:888,price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
        {title:'商品名',jifen:888,price:99,dname:'发哥小店','chandi':'义乌','url':'',pic:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2017%2F965%2F784%2F4047487569_716956869.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1619180306&t=cb8c9748c82266d135751e48e0e90598'},
      ],
      list:{
        loading:false,
        finished:true,
      }
    }
  },
  created() {
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
  },
  components: {
    [NavBar.name]:NavBar,Goods,List
  },
  computed: {}
}
</script>
<style scoped>

.bg{
  background: #f9f9f9;
}
.zhongchou{
  background: #AC3C35;
  color: white;
  padding: 10px 0px;
  position: fixed;
  font-size: 22px;
  bottom: 0px;
  width: 100%;
}
.goods_list{
  padding-left: 5px;
}
</style>
