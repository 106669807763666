import request from "@/util/request";

export default {
    /*
    * 附件列表
    * */
    attachmentList: (data) => {
        return request.post('/NewApi/Attachment/index', data);
    },

    /*
    * 附件详情
    * */
    attachmentDetail: (data) => {
        return request.post('/NewApi/Attachment/detail', data);
    },
};