import request from "@/util/request";

export default {

    /**
     * 聚合搜索
     * */
    searchIndex: (params) => {
        return request.post('/NewApi/Search/index', params);
    },

};