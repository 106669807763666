<template>
  <div>
    <van-nav-bar
        title="企业开店信息"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <div>
      <Steps :active="active" active-color="#AC3A35">
        <Step>填写信息</Step>
        <Step>平台审核</Step>
        <Step>完成</Step>
      </Steps>
    </div>



    <van-form @submit="onSubmit" class="form">
<!--个人-->
      <div class="geren" >
        <div class="p1">
          <div class="title">店铺信息</div>

          <div class="kuai">
            <div class="name">店铺名称</div>
            <input class="content"  v-model="info.storeInfo.store_name" placeholder="请输入店铺名称"/>
          </div>

          <div class="kuai" @click="state.xz.showPicker=true">
            <div class="name">店铺性质</div>
            <div class="content" >
              {{state.xz.value}}
            </div>
          </div>
          <van-popup v-model:show="state.xz.showPicker" round position="bottom">
            <van-picker
                :columns="storeTypes"
                @cancel="state.xz.showPicker = false"
                @confirm="onConfirm_xz"
            />
          </van-popup>

          <div class="kuai" @click="state.dpfl.showPicker=true">
            <div class="name">主营分类</div>
            <div class="content" >
              {{state.dpfl.value}}
            </div>
          </div>
          <van-popup v-model:show="state.dpfl.showPicker" round position="bottom">
            <van-picker
                :columns="storeClass"
                @cancel="state.dpfl.showPicker = false"
                @confirm="onConfirm_dpfl"
            />
          </van-popup>

          <div class="kuai">
            <div class="name">登录账号</div>
            <input class="content"  v-model="info.storeInfo.seller_name" placeholder="请输入登录账号"/>
          </div>

          <div class="kuai">
            <div class="name">负责人姓名</div>
            <input class="content"  v-model="info.storeInfo.store_person_name" placeholder="请输入负责人姓名"/>
          </div>

          <div class="kuai">
            <div class="name">负责人手机号</div>
            <input class="content"   v-model="info.storeInfo.store_person_mobile" placeholder="请输入负责人手机号"/>
          </div>

          <div class="kuai">
            <div class="name">负责人邮箱</div>
            <input class="content"  v-model="info.storeInfo.store_person_email"  placeholder="请输入负责人邮箱"/>
          </div>

          <div class="kuai">
            <div class="name">详细地址</div>
            <input class="content"  v-model="info.storeInfo.company_address"  placeholder="请输入详细地址"/>
          </div>
        </div>


        <div class="p1">
          <div class="title">结算银行账户</div>
          <div class="kuai">
            <div class="name">银行开户名</div>
            <input class="content" v-model="info.bankInfo.bank_account_name" placeholder="请输入银行开户名"/>
          </div>
          <div class="kuai">
            <div class="name">银行账号</div>
            <input class="content" v-model="info.bankInfo.bank_account_number" placeholder="请输入银行账号"/>
          </div>
          <div class="kuai">
            <div class="name">开户行</div>
            <input class="content" v-model="info.bankInfo.bank_branch_name" placeholder="请输入开户行"/>
          </div>

          <div class="kuai" @click="state.khh.showPicker=true">
            <div class="name">开户行所在地</div>
            <div class="content" >
              {{state.khh.value}}
            </div>
          </div>
          <van-popup v-model:show="state.khh.showPicker" round position="bottom">
            <van-area :area-list="areaList" :columns-num="2" title="选择地址" v-model="info.bankInfo.addressInfo" @confirm="onConfirm_khh" @cancel="state.khh.showPicker = false"/>
          </van-popup>

        </div>

        <div class="p1">
          <div class="title">运营信息</div>
          <div class="kuai" @click="state.qd.showPicker=true">
            <div class="name">主营渠道</div>
            <div class="content" >
              {{state.qd.value}}
            </div>
          </div>
          <van-popup v-model:show="state.qd.showPicker" round position="bottom">
            <van-picker
                :columns="mainChannels"
                @cancel="state.qd.showPicker = false"
                @confirm="onConfirm_qd"
            />
          </van-popup>

          <div class="kuai">
            <div class="name">近一年销售额</div>
            <input class="content"  v-model="info.saleInfo.sales_volume" placeholder="近一年销售额 单位：万元"/>
          </div>

          <div class="kuai">
            <div class="name">可网售商品数</div>
            <input class="content" v-model="info.saleInfo.sku_num" placeholder="请输入可网售商品数"/>
          </div>

          <div class="kuai">
            <div class="name">预计平均客单</div>
            <input class="content"   v-model="info.saleInfo.avg_price" placeholder="请输入预计平均客单"/>
          </div>

          <div class="kuai" @click="state.ck.showPicker=true">
            <div class="name">仓库情况</div>
            <div class="content" >
              {{state.ck.value}}
            </div>
          </div>
          <van-popup v-model:show="state.ck.showPicker" round position="bottom">
            <van-picker
                :columns="wareHouses"
                @cancel="state.ck.showPicker = false"
                @confirm="onConfirm_ck"
            />
          </van-popup>

        </div>

        <div class="p1">
          <div class="title">同类电子商务网站经营</div>
          <div class="kuai">
            <van-radio-group v-model="info.saleInfo.ec_experience">
              <van-cell title="是" clickable  @click="info.saleInfo.ec_experience = 'Y'">
                <template #right-icon>
                  <van-radio name="Y"  />
                </template>
              </van-cell>
              <van-cell title="否" clickable  @click="info.saleInfo.ec_experience = 'N'">
                <template #right-icon>
                  <van-radio name="N" />
                </template>
              </van-cell>
            </van-radio-group>
          </div>
        </div>

        <div class="p1">
          <div class="title">是否有实体店</div>
          <div class="kuai">
            <van-radio-group v-model="info.saleInfo.entity_shop">
              <van-cell title="是" clickable  @click="info.saleInfo.entity_shop=='1'">
                <template #right-icon>
                  <van-radio name="1"  />
                </template>
              </van-cell>
              <van-cell title="否" clickable  @click="info.saleInfo.entity_shop=='2'">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
            </van-radio-group>
          </div>
        </div>



      </div>
<!--      -->
<!--企业-->

      <div style="margin: 16px;">
        <van-button round block @click="smp" type="primary" style="background: #AC3A35;border: 1px solid #AC3A35;" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import {
  NavBar,
  Icon,
  Form,
  Field,
  Button,
  Uploader,
  Step,
  Steps,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  Popup,
  Picker,
  Area,
  Toast
} from 'vant'
import {ref} from 'vue'
export default {
  name: "Gskd",
  setup() {
    const fileList = ref([
      // Uploader 根据文件后缀来判断是否为图片文件
      // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    ]);


    const afterRead = (file) => {
      file.status = 'uploading';
      file.message = '上传中...';

      setTimeout(() => {
        file.status = 'failed';
        file.message = '上传失败';
      }, 1000);
    };

    return {
      fileList,
      afterRead,
    };
  },
  data(){
    return {
      active:0,
      columns:[
        '杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'
      ],
      // 主营渠道
      mainChannels: [
        '请选择',
        '商场/卖场',
        '批发市场',
        '超市/连锁店/商业中心',
        '电商网站',
        '其他',
      ],
      // 仓库情况
      wareHouses: [
        '请选择',
        '自有仓库',
        '第三方仓库',
        '无仓库',
      ],
      // 店铺性质
      storeTypes: ['旗舰店', '专卖店', '专营店'],
      info:{
        // 表单信息
        storeInfo: {
          // 店铺名称
          store_name: '',
          // 店铺类型(展示用)
          store_type_dis: '',
          // 店铺类型
          store_type: '',
          // 主营类目
          sc_name: '',
          // 主营类目ID
          sc_id: '',
          // 店铺登录账号
          seller_name: '',
          // 店铺负责人名称(姓名)
          store_person_name: '',
          // 店铺负责人联系电话
          store_person_mobile: '',
          // 店铺负责人联系邮箱
          store_person_email: '',
          // 店铺详细地址
          company_address: '',
        },

        // 结算银行信息
        bankInfo: {
          // 银行开户名
          bank_account_name: '',
          // 银行账号
          bank_account_number: '',
          // 开户行名称
          bank_branch_name: '',
          // 开户行所在地(仅做展示)
          addressInfo: '',
          // 开户行所在省
          bank_province: '',
          // 开户行所在市
          bank_city: '',
        },

        // 运营信息
        saleInfo: {
          sales_volume: '',
          main_channel: '',
          ec_experience: 'Y',
          sku_num: '',
          avg_price: '',
          ware_house: '',
          entity_shop: '0',
        },
      },
      state:{
       dpfl:{
         showPicker:false,
         value:'请选择'
       },
        xz:{
          showPicker:false,
          value:'请选择'
       },
        khh:{
          showPicker:false,
          value:'请选择'
        },
        gsxz:{
          showPicker:false,
          value:'请选择'
        },
        gsszd:{
          showPicker:false,
          value:'请选择'
        },
        nsr:{
          showPicker:false,
          value:'请选择'
        },
        nslx:{
          showPicker:false,
          value:'请选择'
        },
        qd:{
          showPicker:false,
          value:'请选择'
        },
        ck:{
          showPicker:false,
          value:'请选择'
        },
      },
      checked_jy:'',
      checked_std:'',
      areaList:[],
      storeClass:[],
      storeClassName:[],
    }
  },
  created() {
    // 店铺主营分类处理
    this.$api.get_storelist().then(r=>{
      console.log(r.data);
      this.storeClass = [];
      this.storeClassName = r.data;
      for (let i in this.storeClassName) {
        if (this.storeClassName[i]!=undefined){
          this.storeClass.push(this.storeClassName[i]);
        }
      }
    })
    this.getAreaList();
  },
  methods:{
    onConfirm_dpfl(value){
        this.state.dpfl.value = value;
        this.state.dpfl.showPicker = false;
        this.info.storeInfo.sc_name = value;
        // console.log(111);
    },
    onConfirm_xz(value){
      this.state.xz.value = value;
      this.info.storeInfo.store_type_dis = value;
      this.state.xz.showPicker = false;
      // console.log(111);
    },
    onConfirm_khh(value){
      this.state.khh.value =value[0].name + value[1].name;
      this.info.bankInfo.bank_province = value[0].name;
      this.info.bankInfo.bank_city = value[1].name;
      this.info.bankInfo.addressInfo = value[0].name + value[1].name;
      this.state.khh.showPicker = false;
      // console.log(111);
    },
    onConfirm_gsxz(value){
      this.state.gsxz.value = value;
      this.state.gsxz.showPicker = false;
      // console.log(111);
    },
    onConfirm_gsszd(value){
      this.state.gsszd.value = value;
      this.state.gsszd.showPicker = false;
      // console.log(111);
    },
    onConfirm_nsr(value){
      this.state.nsr.value = value;
      this.state.nsr.showPicker = false;
      // console.log(111);
    },
    onConfirm_nslx(value){
      this.state.nslx.value = value;
      this.state.nslx.showPicker = false;
      // console.log(111);
    },
    onConfirm_qd(value){
      this.state.qd.value = value;
      this.info.saleInfo.main_channel = value;
      this.state.qd.showPicker = false;
      // console.log(111);
    },
    onConfirm_ck(value){
      this.state.ck.value = value;
      this.state.ck.showPicker = false;
      this.info.saleInfo.ware_house = value;
      // console.log(111);
    },
    /* 获取店铺类型(性质)* */
    getStoreType: function (typeName) {
      for (let i in this.storeTypes){
        if (this.storeTypes[i]!=undefined && this.storeTypes[i] === typeName){
          return i;
        }
      }
      return false;
    },
    /** 获取主营类目* */
    getScId: function (scName) {
      for (let i in this.storeClassName){
        if (this.storeClassName[i]!=undefined && this.storeClassName[i] === scName){
          return i;
        }
      }
      return false;
    },
    /** 获取省市区数据* */
    getAreaList: function () {
      this.$api.get_arealist().then(r=>{
        if(r.code==200){
          this.areaList = r.data;
        }
      })
    },
    smp(){
      // 表单信息校验规则
      let regExpList = {
        // 表单信息
        storeInfo: {
          // 店铺名称
          store_name: {reg: /^[^'s]+$/, tip: '店铺名称有误'},
          // 店铺类型
          store_type: {reg: /^[0-2]$/, tip: '请选择店铺类型'},
          // 主营类目
          sc_name: {reg: /^[^\s]+$/, tip: '请选择主营类目'},
          // 主营类目ID
          sc_id: {reg: /^[0-9]+$/, tip: '请选择主营类目'},
          // 店铺登录账号
          seller_name: {reg: /^[\w]+$/, tip: '请输入登录账号'},
          // 店铺负责人名称(姓名)
          store_person_name: {reg: /^[^\s]{2,}$/, tip: '请输入店铺负责人姓名'},
          // 店铺负责人联系电话
          store_person_mobile: {reg: /^1[3-9][0-9]{9}$/, tip: '请输入店铺负责人联系电话'},
          // 店铺负责人联系邮箱
          store_person_email: {reg: /^[\w]+@[\w]+\.[.\w]+$/, tip: '请输入店铺负责人联系邮箱'},
          // 店铺详细地址
          company_address: {reg: /^[^\s]+$/, tip: '请输入店铺详细地址'},
        },

        // 结算银行信息
        bankInfo: {
          // 银行开户名
          bank_account_name: {reg: /^[^\s]+$/, tip: '请输入正确银行开户名'},
          // 银行账号
          bank_account_number: {reg: /^[0-9]{16,}$/, tip: '请输入正确银行账户'},
          // 开户行名称
          bank_branch_name: {reg: /^[^\s]+$/, tip: '请输入开户行名称'},
          // 开户行所在省
          bank_province: {reg: /^[^\s]+$/, tip: '请选择开户行所在地址'},
          // 开户行所在市
          bank_city: {reg: /^[^\s]+$/, tip: '请选择开户行所在地址'},
        },

        // 运营信息
        saleInfo: {
          sales_volume: {reg: /^[0-9.]+$/, tip: '请填写近一年营业额'},
          main_channel: {reg: /^[1-5]$/, tip: '请选择主销售渠道'},
          ec_experience: {reg: /^[YN]$/, tip: '请选择是否有同类商城经验'},
          sku_num: {reg: /^[0-9]+$/, tip: '请输入可网络销售的商品数量'},
          avg_price: {reg: /^[0-9.]+$/, tip: '请输入预计平均客单价'},
          ware_house: {reg: /^[1-3]$/, tip: '请选择仓库情况'},
          entity_shop: {reg: /^[0-2]$/, tip: '请选择是否有实体店'},
        },
      };
      // 处理部分值需要特殊处理的
      let reqData = JSON.parse(JSON.stringify({
        storeInfo: this.info.storeInfo,
        bankInfo: this.info.bankInfo,
        saleInfo: this.info.saleInfo,
      }));
      reqData.storeInfo.store_type = this.getStoreType(this.info.storeInfo.store_type_dis);   // 店铺类型
      reqData.storeInfo.sc_id = this.getScId(this.info.storeInfo.sc_name);    // 主营分类
      // 处理主营渠道
      for (let i in this.mainChannels) {
        if (this.mainChannels[i]!=undefined && this.mainChannels[i] === reqData.saleInfo.main_channel){
          reqData.saleInfo.main_channel = i;
        }
      }
      // 处理仓库状态
      for (let i in this.wareHouses){
        if (this.wareHouses[i]!=undefined && this.wareHouses[i] === reqData.saleInfo.ware_house) {
          reqData.saleInfo.ware_house = i;
        }
      }

      // 校验表单数据格式
      let verify = true;
      let errList = [];
      for (let i in regExpList) {
        if (regExpList[i]!=undefined) {
          let regExp = regExpList[i];
          for (let l in regExp) {
            if (regExp[l]!=undefined) {
              if (l === 'legal_identity_cert' || l === 'store_person_cert'){
                if (!regExp[l].reg.test(reqData[i][l].name)){
                  verify = false;
                  errList.push(regExp[l].tip);
                }
                continue;
              }
              if (!regExp[l].reg.test(reqData[i][l])) {
                verify = false;
                errList.push(regExp[l].tip);
              }
            }
          }
        }
      }

      // 表单数据格式校验失败
      if (!verify) {
        let errMsg = errList.join("\n");
        Toast(errMsg);
        return;
      }

      // 处理一下, 不要这个层级
      let newReqData = {};
      for (let i in reqData){
        if (reqData[i]!=undefined){
          for (let l in reqData[i]){
            if (reqData[i][l]!=undefined){
              newReqData[l] = reqData[i][l];
            }
          }
        }
      }
      reqData = newReqData;
      // 执行提交
      if (this.mutex) return;
      this.mutex = true;
      this.$api.two_sumb(reqData).then(r=>{
        this.mutex = false;
        // console.log(r);
        if(r.code==200){
          Toast(r.msg);
          this.$router.push('/kdinfo')
        }
      })
    }
  },
  components:{
    [NavBar.name]:NavBar,[Icon.name]:Icon,
    [Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
    [Uploader.name]:Uploader,
    [Cell.name]:Cell,
    [CellGroup.name]:CellGroup,
    [RadioGroup.name]:RadioGroup,
    [Radio.name]:Radio,
    [Popup.name]:Popup,
    [Picker.name]:Picker,
    [Area.name]:Area,
    Step,
    Steps,
  },
  computed:{

  }
}
</script>

<style scoped>
.form{
  margin: 10px;
  border-radius: 18px;
}
.van-cell.van-field:first-child{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.van-cell.van-field:nth-child(3){
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.p1{
  color: black;
  margin: 10px;
  font-size: 16px;
  text-align: left;
}
.title{
  color: #d2211b;
  font-weight: bold;
  margin-bottom: 10px;
}
.kuai{
  background: white;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.name{
  padding-left: 5px;
}
.content{
  padding: 10px;
  display: block;
  color: #8d8b8b;
  width: 90%;
}
.tips{
  color: #8d8b8b;
  font-size: 12px;
  margin:10px;
}
</style>
