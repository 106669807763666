<template>
  <div class="main">
    <nav-bar
        title="我的搭伙"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
        <Icon style="color: white;display: none;" name="search" size="18" />
      </template>

    </nav-bar>
    <!--    swipeable-->
    <van-tabs class="www" v-model="activeName" animated background="#000" color="#fff"
              title-active-color="#8f2526"  @change="change"
              title-inactive-color="#fff">
      <van-tab title="全部" name="index">
        <!--        商品-->
        <dh-list @payshow="pay_now"></dh-list>
        <!--        -->
      </van-tab>
      <van-tab title="未付款"  name="dai" >
        <dh-list v-if="now=='dai'" @payshow="pay_now" status="0" ></dh-list>
      </van-tab>
      <van-tab title="进行中"  name="dai" >
        <dh-list v-if="now=='dai'" @payshow="pay_now" status="1" ></dh-list>
      </van-tab>
      <van-tab title="已完成"  name="wei" >
        <dh-list v-if="now=='wei'" @payshow="pay_now" status="2"></dh-list>
      </van-tab>
      <van-tab title="已结束"   name="daifa" >
        <dh-list v-if="now=='daifa'" @payshow="pay_now" status="3"></dh-list>
      </van-tab>
    </van-tabs>

    <Pay style="z-index: 9999;" @close="payclose"
         :total="pay.total" v-if="payshow" :order-id="pay.orderId" :master_order_sn="pay.master_order_sn"></Pay>
  </div>
</template>

<script>
import {Tab, Tabs, NavBar,Icon} from 'vant';
import DhList from "@/views/marke/pay/DhList";
import Pay from "@/components/Pay";
export default {
  name: "Mypm",
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      now:'',
      payshow:false,
      pay:{
        total:0,
        orderId:0,
        master_order_sn:'',
      },
    }
  },
  created() {

  },
  methods:{
    change(e){
      // console.log(e);
      this.now = e;
    },
    pay_now(e){
      // console.log(e);
      this.pay = e;
      this.payshow = true;
    },
    payclose(){
      this.payshow = false;
    },
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  components: {
    DhList,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    NavBar,
    Icon,Pay,
  },
  computed: {}

}
</script>
<style>

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}




</style>
<style scoped>
.main {
  width: 100%;
  margin-bottom: 50px;
  /*position: fixed;*/
}
.www{
  position: relative;
  bottom: 1px;
  z-index: 999;
}
.card_my{
  background: white;
  /*padding: 10px;*/
  margin: 20px;
  border-radius: 10px;
}
.title{
  font-size: 16px;
  text-align: left;
  width: 160px;
  padding-left: 20px;
}
.price_s{
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 50px;
  font-size: 20px;
  font-weight: bold;
}
.goods-card{
  background: white;
}
.qpic{width: 30px;border-radius: 99px;margin-right: 8px;position: relative;top: 8px;}
.flex.ss{
  justify-content: space-between;
}
.nei_1{
  margin-left: 20px;
  font-size: 14px;
  color: black;
}
.status{
  color: #8d8b8b;
  font-size: 14px;
  padding-top:12px;
  padding-right: 20px;
}
.num{
  position: relative;
  left: 75px;
}
.time{
  padding-top: 10px;
}
.total{
  font-weight: bold;
  font-size: 14px;
}
.total span{
  color: #AC3A35;
  font-size: 18px;
  font-weight: bold;
}
</style>