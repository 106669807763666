<template>
  <div class="searchIndex">
    <!--  顶部导航  -->
    <van-nav-bar
        title="搜索"
        left-text=""
        left-arrow
        @click-left="back"
        style="background: black">
    </van-nav-bar>

    <div style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;background-color: #ffffff;border-radius: 10px">
      <van-search placeholder="请输入名称" background="#ffffff" class="index_serach" v-model="keyword"
                  @search="onSearch"></van-search>
      <!--     搜索按钮     -->
      <van-icon name="search" style="margin-right: 5px" color="#000000" @click="onSearch" size="30px"></van-icon>
    </div>

    <!--  搜索到的产品列表  -->
    <van-grid :border="false" :column-num="2">
      <van-grid-item v-for="(v,k) in lists" :key="k">
        <Goods :="v" style="margin-left: 0;margin: 0"></Goods>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import {Toast, Grid, GridItem, NavBar, Search, Icon} from "vant";
import {Scroll} from "@/util/scroll";

export default {
  name: "Index",
  data: () => {
    return {

      keyword: '',

      mutex: false,

      currentPage: 0,

      lists: [],

    };
  },
  created() {
    this.keyword = this.$route.query.keyword;
    this.getLists();
  },
  mounted() {
    new Scroll().setReachBottomDistance(20).setEmitRate(500).onReachBottom(() => {
      this.getLists();
    });
  },
  methods: {

    /*
    * 返回上一页
    * */
    back: function () {
      this.$router.back();
    },

    /*
    * 搜索
    * */
    onSearch: function () {
      this.currentPage = 0;
      this.mutex = false;
      this.lists = [];
      this.getLists();
    },

    /*
    * 加载更多
    * */
    getLists: function () {
      if (this.mutex) {
        Toast('操作过于频繁');
        return;
      }
      this.mutex = true;
      let page = this.currentPage;
      this.$api.searchIndex({page: ++page, keyword: this.keyword}).then(res => {
        if (res.code === 200) {
          if (page !== 1 && res.data.length === 0) {
            Toast('没有更多了');
          }
          if (res.data.length === 0) --page;
          this.currentPage = page;
          this.lists = this.lists.concat(res.data);
        }
        this.mutex = false;
      }).catch(() => {
        this.mutex = false;
      });
    },

  },
  beforeUnmount() {
    window.onscroll = () => {
    };
  },
  components: {
    Goods,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Icon.name]:Icon,
  },
}
</script>

<style scoped>
.index_serach {
  width: 90%;
  /*padding: 0;*/
  /*width: 100%;*/
  /*padding-right: 5px;*/
}
</style>