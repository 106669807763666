<template>
  <div>
    <van-nav-bar
        title="关注列表"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>
<!--列表-->
    <div class="gz">
        <div class="block flex">
            <div class="flex">
              <p><img class="gz_pic" src="@/assets/img/tu3.png"></p>
              <div class="one">
                <p class="qname">企业名称</p>
                <p class="jieshao">企业、创始人介绍</p>
                <p>
                  <span>粉丝：0</span>
                  <span>作品：0</span>
                </p>
              </div>
            </div>
            <Button class="qe">取消关注</Button>
        </div>

        <div class="block flex">
          <div class="flex">
            <p><img class="gz_pic" src="@/assets/img/tu3.png"></p>
            <div class="one">
              <p class="qname">企业名称</p>
              <p class="jieshao">企业、创始人介绍</p>
              <p>
                <span>粉丝：0</span>
                <span>作品：0</span>
              </p>
            </div>
          </div>
          <Button class="qe">取消关注</Button>
        </div>
    </div>

  </div>
</template>

<script>
import {NavBar,Button} from 'vant';
export default {
    name: "Follow",
    data(){
        return {
        
        }
    },
    created() {
    },
    methods:{
    
    },
    components:{
      [NavBar.name]:NavBar,Button
    },
    computed:{
    
    }
}
</script>

<style scoped>
  .gz_pic{
    width: 50px;
    border-radius: 5px;
  }

  .gz{
    margin: 10px 20px;
  }
  .block{
    background: white;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .qe{
    border-radius: 15px;
    font-size: 12px;
    padding: 1px 18px 1px 18px;
  }
  .flex .qname{
    color: black;
  }
  .one{
    font-size: 12px;
    text-align: left;
    color: #8d8b8b;
    padding-left: 10px;
  }
  .one span{
    padding-right: 5px;
  }
</style>
