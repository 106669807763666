<template>
  <div class="main">
    <nav-bar
        title="积分记录"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>

      </template>

    </nav-bar>

    <van-tabs class="www" v-model="activeName" animated background="#000" color="#fff"
              title-active-color="#8f2526"
              title-inactive-color="#fff">
      <van-tab title="收支明细" name="index">
          <div class="block">

            <div class="p1 flex">
              <div class="p1_1">
                <p class="qd">签到</p>
                <p class="time">2222.22.22</p>
              </div>
              <div class="p1_2">+5</div>
            </div>

            <div class="p1 flex">
              <div class="p1_1">
                <p class="qd">签到</p>
                <p class="time">2222.22.22</p>
              </div>
              <div class="p1_2">+5</div>
            </div>

          </div>
      </van-tab>
      <van-tab title="兑换记录" name="b" >

        <Empty description="暂时没有哦"/>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {Tab, Tabs, NavBar, Empty,Button} from 'vant';
export default {
  name: "Pointsrecord",
  setup() {

  },
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      list:[

      ]
    }
  },
  created() {
  },
  methods() {

  },
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Empty,
    NavBar,[Button.name]:Button,
  },
  computed: {}

}
</script>
<style>

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}




</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
}
.www{
  position: relative;
  bottom: 1px;
  z-index: 999;
}
.block{
  margin: 14px;
  background: white;
  border-radius: 10px;
  font-size: 14px;
  padding: 14px;
}
.p1{
  justify-content: space-between;
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}
.p1_1{
  font-size: 18px;
}
.time{
  font-size: 12px;
  color: #8d8b8b;
  padding-top: 5px;
}
.p1_2{
  color: #d2211b;
  font-weight: bold;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
}
</style>