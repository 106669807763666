// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter,createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import user from './user';
import marke from './marke'
import Research from "@/views/Research";
import Soso from '../views/Soso'
import Pm from "@/views/index/Pm";
import {getKey} from "@/util/cache";
import Resulf from "@/views/marke/pay/Resulf";
import collection from "@/router/collection";
import attachment from "@/router/attachment";
import search from "@/router/search";
import Faxian from "@/components/Faxian";
const routes = [
    ...user,
    ...marke,
    ...collection,
    ...attachment,
    ...search,
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta:{
      title:'天工开物',
      keepAlive:true //需要被缓存的组件
    }
    // children:[
    //     {path:'',component:Faxian}
    // ]
  },
  {
    path: '/faxian',
    name: 'Faxian',
    component: Faxian,
    meta:{
      title:'天工开物',
      keepAlive:true //需要被缓存的组件
    }
  },
  {
    path: '/pm',
    name: 'Pm',
    component: Pm,
    meta:{
      title:'天工开物'
    }
  },
  {
    path: '/research',
    name: 'Research',
    component: Research,
    meta:{
      title:'研究院'
    }
  },
  {
    path: '/soso',
    name: 'Soso',
    component: Soso,
    meta:{
      title:'搜索'
    }
  },
  {
    path: '/pay/resulf',
    name: 'Resulf',
    component: Resulf,
    meta:{
      title:'支付结果返回',
      auth:true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Index,
    meta:{
      title:'404'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  base:'/',
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title;
  }

  if(to.meta.auth){
    let t = getKey();
    if(t==undefined || t==''){
      //请先登录
      router.push('/login');
      return;
    }
  }

  next();
})

export default router
