import Index from "@/views/collection/Index";

export default [
    // 列表
    {
        path: '/collection',
        name: 'Collection',
        component: Index,
        meta:{
            title:'大师馆',
        },
    },
];