<template>
  <div >
    <van-tabbar class="main btmain" v-model="active" active-color="rgb(251 7 7)" route>
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img class="img" :src="props.active ? icon.index.active : icon.index.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/pm">
        <span>拍卖</span>
        <template #icon="props">
          <img class="img" :src="props.active ? icon.paimai.active : icon.paimai.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="empty" >
        <span>社区</span>
        <template #icon="props">
          <img class="img" :src="props.active ? icon.shequ.active : icon.shequ.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/message">
        <span>消息</span>
        <template #icon="props">
          <img class="img" :src="props.active ? icon.xiaoxi.active : icon.xiaoxi.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user">
          <span>我的</span>
          <template #icon="props">
            <img class="img" :src="props.active ? icon.user.active : icon.user.inactive" />
          </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {Tabbar,TabbarItem,Toast} from 'vant';
export default {
  name: "Bottom",
  data() {
    return {
      active:0,
      icon: {
        'index':{
          active: require('../assets/img/home.png'),
          inactive: require('../assets/img/home.png'),
        },
        'paimai':{
          active: require('../assets/img/pai1.png'),
          inactive: require('../assets/img/pai.png'),
        },
        'shequ':{
          active: require('../assets/img/squ1.png'),
          inactive: require('../assets/img/squ.png'),
        },
        'xiaoxi':{
          active: require('../assets/img/xiaoxi1.png'),
          inactive: require('../assets/img/xiaoxi.png'),
        },
        'user':{
          active: require('../assets/img/wode1.png'),
          inactive: require('../assets/img/wode.png'),
        },
      }
    }
  },
  created() {
  },
  methods:{
    empty(){
      Toast('暂未开放');
    }
  },
  components: {
    // active: 0,
    [Tabbar.name]:Tabbar,
    [TabbarItem.name]:TabbarItem
  },
  computed: {}
}
</script>
<style>
.van-tabbar--fixed.btmain{
  z-index: 999;
}
</style>
<style scoped>
.img{
  padding: 3px 0px;
}
.main{
  height: 60px;
}
</style>
