<template>
  <div class="bg">
    <van-nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />
  </div>

  <!--    商品列表-->
  <List  :loading="list.loading"
         :finished="list.finished"
         finished-text="没有更多了"
         error-text="请求失败，点击重新加载"
         @load="onLoad"
         class="flex"
         style="flex-wrap:wrap;justify-content: center;"

  >
    <div class="xxxxlist" v-for="(v,k) in goods_data" :key="k">
      <Goods :="v" act_type="assemble"></Goods>
    </div>
  </List>

</template>

<script>
import {NavBar, Toast,List} from 'vant';
import Goods from "@/components/Goods";
export default {
  name: "Huoping",
  data() {
    return {
      title:'伙拼',
      goods_data:[
      ],
      list:{
        loading:false,
        finished:false,
        page:1,
      }
    }
  },
  created() {
    this.onLoad();
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.back() : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    onLoad(){
      if(this.list.loading || this.list.finished) return;
      this.list.loading=true;
      this.$api.assembleList(this.list.page).then(r=>{
        if(r.code==200){
          console.log(r.data);
          if(r.data.list==null){
            this.list.finished=true;
            return;
          }
          if(r.data.list.length<=0 || r.data.list.length<10){
            this.list.finished=true;
            this.list.loading=false;
          }
          if(this.list.page==1){
            this.goods_data = r.data.list;
          }else{
            this.goods_data = this.goods_data.concat(r.data.list);
          }
          this.list.page+=1;
          this.list.loading=false;
        }
      })
    }
  },
  components: {
    [NavBar.name]:NavBar,Goods,List
  },
  computed: {}
}
</script>
<style scoped>

.bg{
  background: #f9f9f9;
}
.zhongchou{
  background: #AC3C35;
  color: white;
  padding: 10px 0px;
  position: fixed;
  font-size: 22px;
  bottom: 0px;
  width: 100%;
}
.goods_list{
  padding-left: 5px;
}
</style>
