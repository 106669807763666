<template>
  <div class="bg">
    <van-nav-bar
        :title="order.order_status_code=='WAITPAY'?'确认订单':'订单详情'"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />

   <template v-if="order.order_status_code=='WAITPAY'">

       <div class="red_tips">
         <p>等待您付款</p>
         <p class="flex">
           <van-count-down :time="endtime">
             <template #default="timeData">
               <span class="time_w">{{ timeData.hours }}小时</span>
               <span class="time_w">{{ timeData.minutes }}分钟</span>
             </template>
           </van-count-down>
           后订单自动取消
         </p>
       </div>

       <div class="end">
         <div class="end_time">离结束时间还有</div>
         <div>
           <van-count-down :time="endtime">
             <template #default="timeData">
               <span class="time_l">{{ timeData.days }}</span>天
               <span class="time_l">{{ timeData.hours }}</span>时
               <span class="time_l">{{ timeData.minutes }}</span>分
               <span class="time_l">{{ timeData.seconds }}</span>秒
             </template>
           </van-count-down>
         </div>
       </div>
   </template>

    <template v-else>

      <template v-if="order.is_refund==1">
        <div class="red_tips">
          <p>您的订单已退款</p>
        </div>
      </template>
      <template  v-else>
        <div class="red_tips">
          <p>您的订单{{order.order_status_desc}}</p>
        </div>
      </template>
    </template>

    <div class="p1">
      <div class="address">{{order.address}}</div>
      <div class="name flex">
        <div>{{order.consignee}}</div>
        <div>{{order.mobile}}</div>
      </div>
    </div>

    <div class="p1 wuliu">
      <van-steps direction="vertical" :active="order_action.length">
        <van-step>
          <h3>快递单号：{{order.invoice_no?order.invoice_no:'暂无'}}</h3>
          <p>物流公司：{{order.shipping_name?order.shipping_name:'暂无'}}</p>
        </van-step>
      </van-steps>
    </div>

    <div class="p1">
      <div class="address">店铺地址: {{store.province_name}} {{store.city_name}} {{store.district_name}} {{store.store_address}}</div>
      <div class="name flex">
        <div>联系电话：{{store.store_phone?store.store_phone:'暂无'}}</div>
      </div>
    </div>

<!--    <template  v-for="(v,k) in order" :key="k" :ref="'cG'+k">-->
      <div class="card_my">
        <div class="qname" >
          <img :src="store.store_logo" class="qpic">
          {{store.store_name}}
        </div>
        <van-swipe-cell v-for="(sv,sk) in order.goods_list" :key="sk" style="position: relative;" >
          <van-card
              class="goods-card"
              :thumb="sv.original_img"
          >
            <template #title>
              <p class="title">{{sv.goods_name}}</p>
            </template>
            <template #price>
              <p class="price_s">¥ {{sv.member_goods_price}}</p>
            </template>
            <template #footer>
              X {{sv.goods_num}}
            </template>
          </van-card>
          <template #right>
<!--            <van-button square style="height: 100%" text="删除" type="danger" class="delete-button" />-->
          </template>
        </van-swipe-cell>
      </div>

<!--    </template>-->

    <div class="yunfei">
      <p>作品总价：¥{{order.total_amount}}</p>
      <p>运费&nbsp;&nbsp;：¥{{order.shipping_price}}</p>
      <p>共计 {{order.goods_list.length}} 件作品 <span>合计：<b style="color: #d2211b;">¥{{order.order_amount}}</b></span></p>
      <p>支付方式：{{order.pay_name}}</p>
    </div>

    <div class="yunfei left" >
      <p>订单编号：{{order.order_sn}}</p>
      <p>创建时间：{{formatTimestamp(order.add_time)}}</p>
    </div>



  </div>

  <div class="flex bottom" v-if="order.order_status_code=='WAITPAY'" >
    <div class="p10">
      <div><Button class="dingdan quxiao" @click="pay(0)">取消订单</Button></div>
      <div><Button class="dingdan fukuai" @click="pay(1)">付款</Button></div>
    </div>
  </div>

  <van-popup
      v-model:show="zpshow"
      closeable
      close-icon="close"
      position="bottom"
      :style="{}"
  >
    <div class="title_zp" >确认付款</div>
    <div class="canshu_zp flex">
      <div>¥{{total}}</div>
    </div>
    <div class="flex title_zp fangshi">
      <p>支付方式</p>
      <p><img id="fangshi_img" src="@/assets/img/wechat.png">微信支付
        <van-icon id="fang_jian" name="arrow"></van-icon></p>
    </div>

    <div id="pay">
      安全支付
    </div>

  </van-popup>
  <Pay style="z-index: 9999;" @close="payclose"
       :total="order.order_amount" v-if="payshow" :order-id="order.order_id" :master_order_sn="order.master_order_sn"></Pay>


</template>

<script>
import {NavBar, Toast,Button,Icon,Checkbox,SwipeCell,Card,Popup,CountDown,Steps,Step,Dialog} from 'vant';
import {ref} from 'vue';
import Pay from "@/components/Pay";
export default {
  name: "Orderinfo",
  setup() {
    const endtime = ref(2 * 60 * 60 * 1000);
    return { endtime };
  },
  data() {
    return {
      zpshow:false,
      yunfei:8,
      payshow:false,
      id:0,
      list:{
        loading:false,
        finished:true,
      },
      store:{store_name:'',store_logo:''},
      order:{
        goods_list:[],
        order_status_code:'"WAITPAY"',
      },
      order_action:[]
    }
  },
  created() {
    if(this.$route.query.id==undefined || this.$route.query.id<0){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }
    this.id = this.$route.query.id;
    this.$api.order_detail({id:this.id}).then(r=>{
      if(r.code==200){
        console.log(r.data);
        this.store = r.data.store;
        this.order = r.data.order_info;
        this.order_action = r.data.order_action;
      }
    })
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    pay(i){
      if(i==0){
        Dialog.confirm({
          title: '取消订单',
          message: '您真的要取消订单吗？',
          theme: 'round-button',
        }).then(() => {
              this.$api.cancel_order(this.id).then(r=>{
                if(r.code==200){
                  Toast(r.msg);
                  setTimeout(()=>{
                    window.location.reload();
                  },2000)
                }
              })
            })
            .catch(() => {
              // on cancel
            });

        return;
      }
      this.payshow=true;
    },
    payclose(){
      this.payshow=false;
    },
    formatTimestamp(time = 0, fmt = 'yyyy-MM-dd hh:mm:ss') {
      if(time === 0 || !time) {
        return ''
      }
      time = time*1000;
      let date = new Date(time)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
      }
      return fmt;
    }
  },
  components: {
    Pay,
    [NavBar.name]:NavBar,Button,[Icon.name]:Icon,
    [Checkbox.name]:Checkbox,[SwipeCell.name]:SwipeCell,[Card.name]:Card
    ,[Popup.name]:Popup,
    [CountDown.name]:CountDown,
    [Steps.name]:Steps,
    [Step.name]:Step,
  },
  computed: {
    total(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.price*vs.num
      //   })
      // })
      return b;
    },
    num(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.num
      //   })
      // })
      return b;
    }
  }
}
</script>
<style scoped>
.p1{
  margin: 10px;
  background: white;
  border-radius: 6px;
  padding: 10px;
  position: relative;
}
.title_zp{padding: 10px;border-bottom: 1px solid #eee;}
.canshu_zp{
  justify-content: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 50px;
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 30px;
}

.qname{
  font-size: 16px;
  display: flex;
  margin-bottom: 18px;
  line-height: 30px;
}
.qpic{width: 30px;border-radius: 50px;margin-right: 8px;}

.bg{
  background: #f9f9f9;
  margin-bottom: 140px;
}

.bottom{
  position: fixed;
  bottom: 0px;
  background: #F6F6F6;
  width: 100%;
}
.p10{
  display: flex;
  justify-content:flex-end;
  padding: 10px;
  width: 100%;
}

.dingdan{
  /*border-radius: 5px;*/
  color: white;
  font-weight: bold;
  padding-right: 30px;
  padding-left: 30px;
  width: 120px;
}

.quxiao{
  margin: 0;
  background: #f6f6f6;
  color: black;
  border: 1px solid #c1bdbd;
  /*left: 5px;*/
  border-bottom-left-radius:6px;
  border-top-left-radius:6px;
}
.fukuai{
  background:#AC3A35;
  border: 1px solid #AC3A35;
  border-bottom-right-radius:6px;
  border-top-right-radius:6px;

}

.red_tips{
  background: #AC3A35;
  padding: 15px;
  position: relative;
  bottom: 1px;
  z-index: 99;
  color: white;
  font-size: 18px;
  text-align: left;
}
.time_w{
  font-size: 18px;
  color: white;
  position: relative;
  top: 4px;
}

.address{
  text-align: left;
  font-size: 16px;
}

.name{
  color: #8d8b8b;
}
.name div{
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
}


.card_my{
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.title{
  font-size: 16px;
  text-align: left;
  width: 160px;
  padding-left: 20px;
}
.price_s{
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 50px;
  font-size: 20px;
  font-weight: bold;
}
.num{
  position: absolute;
  bottom: 10px;
  left: 206px;
}
#zishu{
  position: absolute;right: 8px;
  font-size: 16px;
  font-weight: 400;
  bottom: 5px;
}
.liuyan{
  height: 60px;
  font-size: 16px;
  padding-top: 10px;
}
.fangshi{
  justify-content: space-between;
  font-size: 18px;
  color: #8d8b8b;
}
#fangshi_img{
  width: 25px;
  position: absolute;
  right: 110px;
}
#fang_jian{
  position: relative;
  top:2px;
}

#pay{
  margin-top: 18px;
  padding: 15px;
  font-size: 20px;
  background: #AC3A35;
  color: white;
}
.end{
  color: black;
  font-size: 18px;
  margin: 20px;
}
.time_l{
  background: #AC3A35;
  padding: 0px 5px;
  color: white;
  font-weight: bold;
  margin-right: 2px;
}

.end_time{
  margin-bottom: 20px;
}

.yunfei{
  text-align: right;
  font-size: 14px;
  color: #6d6a6a;
  margin-right: 14px;
  margin-bottom: 30px;
}
.yunfei p{
  margin: 3px 0px;
}
.left{
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
}

.wuliu >>> .van-step__title{
  font-size: 12px ;
  /*text-align: left;*/
  /*padding-left: 20px;*/
}


</style>
