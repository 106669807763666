<template>
  <div class="main">
    <Empty image="search" description="支付结果查询中"></Empty>
  </div>
</template>

<script>
import {Empty, Toast} from 'vant';
export default {
    name: "Resulf",
    data(){
        return {
        
        }
    },
    created() {
      // eslint-disable-next-line no-unreachable
      switch (this.$route.query.code){
        case '200':
          Toast('支付成功');
          break;
        case '201':
          Toast('订单金额错误');
          break;
        case '202':
          Toast('订单已完成支付');
          break;
        case '500':
          Toast('支付失败');
          break;
        default:
          Toast('错误');
          break;
      }
      this.$router.push('/order');

    },
    methods:{

    },
    components:{
      Empty
    },
    computed:{
    
    }
}
</script>

<style scoped>
.main{
  margin-top: 18vh;
}
</style>
