<template>
  <div>
    <div class="flex gray gp" >
        <span><svg class="icon" aria-hidden="true"><use xlink:href="#icon-dunpai"></use></svg>1000万消费保障</span>
        <span><svg class="icon" aria-hidden="true"><use xlink:href="#icon-yewutubiaopei"></use></svg>假一赔三</span>
        <span><svg class="icon" aria-hidden="true"><use xlink:href="#icon-serviceshandiantuikuan"></use></svg>7天无理由退换</span>
    </div>
<!--    轮播-->
    <Swipe :autoplay="3000" class="lb">
      <SwipeItem v-for="(image, index) in images" :key="index">
        <router-link :to="image.url"><img :src="image.src" /></router-link>
      </SwipeItem>
    </Swipe>
<!--  图标块-->
    <div class="imgkuai">
        <div><img src="@/assets/img/1.png" @click="empty"><p>0元拍</p></div>
        <div><img src="@/assets/img/2.png" @click="empty"><p>直播拍</p></div>
          <div><router-link to="/pm"><img src="@/assets/img/4.png"><p>拍卖行</p></router-link></div>
        <div><router-link to="/Huoping"><img src="@/assets/img/3.png"  ><p>搭伙</p></router-link></div>
      <div><router-link to="/crowd"><img src="@/assets/img/5.png"><p>众筹</p></router-link></div>
      <div><router-link to="/collection"><img src="@/assets/img/collection.png"><p>大师馆</p></router-link></div>
      <div><router-link to="/attachment"><img src="@/assets/img/attachment.png"><p>下载</p></router-link></div>
    </div>
<!--    新人-->
    <div class="xr" @click="empty">
      <div class="red">
        <p>新人专享</p>
        <p>新手低价限时抢购</p>
      </div>
      <Grid :gutter="10" :border="false">
        <GridItem v-for="value in 4" :key="value" >
          <template v-slot:icon>
            <img src="@/assets/img/tu1.png" class="xr_img">
          </template>
          <template v-slot:text>
            <div class="jiage">
              <span style="color: red;font-size: 18px;font-weight: bold;">￥1</span>
              <div style="color: #4e4949;font-size: 14px;position: relative;left:5px;top:4px;
              text-decoration:line-through;">￥99</div>
            </div>
          </template>
        </GridItem>
      </Grid>
    </div>
<!--    4个-->
    <div class="four flex">
      <router-link to="/crowd">
        <div class="f_list">
          <p class="f_list_title">众筹</p>
          <p class="f_list_di">低价限时抢购</p>
          <p><img src="@/assets/img/tu2.png"></p>
        </div>
      </router-link>
      <router-link to="/Huoping">
      <div class="f_list">
        <p class="f_list_title">伙拼</p>
        <p class="f_list_di">低价限时抢购</p>
        <p><img src="@/assets/img/tu2.png"></p>
      </div>
      </router-link>
        <router-link to="/pm">
      <div class="f_list">
        <p class="f_list_title">拍卖</p>
        <p class="f_list_di">低价限时抢购</p>
        <p><img src="@/assets/img/tu2.png"></p>
      </div>
      </router-link>
      <div class="f_list" @click="empty">
        <p class="f_list_title">好物榜</p>
        <p class="f_list_di">低价限时抢购</p>
        <p><img src="@/assets/img/tu2.png"></p>
      </div>
    </div>

<!--    选框-->
    <div class="flex xuankuang">
      <div :class="tuijian=='one'?'select':''"><p class="one">优选</p><p class="two">为您推荐</p></div>
      <div :class="tuijian=='two'?'select':''" @click="empty"><p class="one">直播</p><p class="two">主播推荐</p></div>
    </div>

<!--    商品列表-->
         <List
             v-model="loading"
             :finished="finished"
             finished-text="没有更多了"
             class="goods_list"
             @load="getdata">
           <template v-for="(v,k) in goods_data" :key="k">
             <Goods :="v"></Goods>
           </template>
         
         </List>
         

  </div>
</template>

<script>
import { Swipe, SwipeItem,Grid,GridItem,Toast,List } from 'vant';
import Goods from "@/components/Goods";
export default {
    name: "Faxian",
    data(){
      return {
        images: [
        ],
        tuijian:'one',
        loading: false,
        finished: false,
        back:false,
        page:1,
        goods_data:[

        ]
      }
    },
    created() {
        // console.log('开始');
     this.getdata()
    },
    activated() {
        console.log(this.back)
      console.log('子缓存激活');
    },
    deactivated(){
        console.log('子缓存停用')
        this.back=true;
    },
    methods:{
      getdata(){
          // console.log(this.back)
        if(this.page==1 && this.images.length>0){
            //回退不刷新
            console.log(222);
            return;
        }
        if(this.loading || this.finished) return;
        this.loading = true;

        this.$api.getindex(this.page).then(r=>{
          if(r.code==200){
            if(this.page==1){
                //处理轮播
              if(r.data.content.length>0){
                  for(let k in r.data.content){
                      switch (r.data.content[k].act_type){
                        case '0':
                          r.data.content[k].url = '/auction?id='+r.data.content[k].act_id;
                          break;
                        case '1':
                          r.data.content[k].url = '/crowdgood?id='+r.data.content[k].act_id;
                          break;
                        default:
                          r.data.content[k].url = '/factions?id='+r.data.content[k].act_id;
                          break;
                      }
                  }
              }
              this.images = r.data.content;
            }
            this.loading = false;

            if(r.data.productList.length<=0){
              this.finished=true;
              return;
            }
            this.page+=1;
            this.goods_data = this.goods_data.concat(r.data.productList);
          }

          // console.log(r.data.productList)
        })
        // this.finished=true;
      },
      empty(){
        Toast('敬请期待');
      }
    },
    unmounted(){
        console.log('销毁');
    },
    components:{
      Swipe,
      SwipeItem,
      Grid,
      GridItem,
      Goods,
      List
    },
    computed:{
    
    }
}
</script>
<style>
.gp span{
  font-size: 12px;
  font-weight: bold;
}

.gp{
  justify-content:space-between;
  padding: 8px;
  margin-bottom: 8px;
}
.van-list__finished-text{
  width: 100%;
}
.van-list__placeholder{
  width: 100%;
}
</style>
<style scoped>

.jiage{
  display: flex;
}
.four{
  margin: 10px;
  justify-content: space-around;
}
.f_list{
  background: white;
  width: 86px;
  height: 120px;
  border-radius: 8px;
  color: black;
  font-size: 14px;
}
.f_list_title{
  padding: 10px 0px;
  font-weight: bold;
}
.f_list_di{
  color: white;
  background: #d2211b;
  font-size: 12PX;
  margin: 0px 2px;
  padding: 0px 3px;
}
.f_list img{
  margin-top: 10px;
    height: 50px;
}
.icon{
  font-size: 18px;
  color: #8f8c8c;
  padding-right: 2px;
}
.lb{
  height: 150px;
  margin: 10px;
  border-radius: 15px;
  /*border: 1px solid #000;*/
}
.lb img{
  height: 150px;
  width: 100%;
  /*object-fit:contain;*/
}
.imgkuai{
  background: #fff;
  margin: 0px 10px 0px 10px;
  border-radius: 15px;
  padding: 20px 10px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.imgkuai > div{
  margin-bottom: 5px;
  padding: 0 8px;
}
.imgkuai img{
  width: 50px;
  height: 50px;
}
.xr{
  margin-top: 10px;
  border-radius: 15px;
  background: #fff;
  /*padding: 0px 10px;*/
  margin: 10px 10px 0px 10px;
  border: 3px solid #e00707;
  justify-content: space-between;
}
.red{
  display: flex;
  font-size: 14px;
  padding: 10px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #d2211b;
  color: white;
  justify-content: space-between;
}
.xr_img{
  width: 55px;
}
.xuankuang{
  justify-content: space-around;
  background: black;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
}

.xuankuang .one{font-size: 16px;}
.xuankuang .two{font-size: 12px;}
.xuankuang div{width: 49%;}
.xuankuang div:first-child{border-right: 1px solid #8d8b8b;}
.select{color: #e00707;}
.goods_list{
  margin: 0px 5px;
  text-align: center;
}

</style>
