<template>
  <div class="main">

        <!--        商品-->
    <template v-if="list.length>0">
      <van-list
          :loading="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="card_my" v-for="(sv,sk) in list" :key="sk" >
          <div class="flex ss">
            <p class="nei_1">
              <img class="qpic" :src="sv.store.store_logo">
              {{sv.store.store_name}}
            </p>
<!--            <p class="status">{{sv.goods_list.length==0?'已超时':sv.order_status_desc}}</p>-->
            <p class="status">{{sv.order_status_desc}}</p>
          </div>
          <van-swipe-cell  class="goods-card goods_swipe" v-for="(gv,gk) in sv.goods_list" :key="gk">
            <van-card
                :thumb="gv.original_img"
                class="goods-card"
                @click="tiao(sv.order_id)"
            >
              <template #title>
                <p class="title">{{gv.goods_name}}</p>
              </template>
              <template #price>
                <div class="flex ss" >
                  <p class="price_s">¥ {{gv.member_goods_price}}</p>
                  <p class="num"> X {{gv.goods_num}}</p>
                </div>
              </template>
              <template #footer >
                <van-button style="margin-top: 10px;" v-show="sv.order_status_desc==='待支付'" plain size="small" type="danger" @click="now_pay(sv)" >立即支付</van-button>
                <van-button style="margin-top: 10px;" v-show="sv.order_status_code==='WAITRECEIVE'" plain size="small" type="success" @click="shouhuo(sv)" >确认收货</van-button>
              </template>
            </van-card>
            <template #right>
              <van-button square style="height: 100%" text="删除" type="danger" class="delete-button" />
            </template>

          </van-swipe-cell>

          <div class="info">
            <p class="time total ">总金额 :  <span >¥{{sv.total_amount}}</span></p>
            <p class="time">实际付款: ¥ {{sv.order_amount}}</p>
<!--            <p class="time">运费: ¥ {{sv.shipping_price}}</p>-->
            <p class="time">订单号：{{sv.order_sn}}</p>
<!--            <p class="time">订单生成时间：{{formatTimestamp(sv.add_time)}}</p>-->
          </div>

        </div>
      </van-list>

    </template>
    <Empty  v-else description="没有该类订单"/>
        <!--        -->

  </div>


</template>

<script>
import {Empty, Button, SwipeCell, Card, List, Toast} from 'vant';



export default {
  name: "OrderList",
  props:[
    'type'
  ],
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      page:1,
      t:'',
      state:{
        loading:false,
        error:'',
        finished:false,
      },
      list:[

      ]
    }
  },
  created() {
    this.onLoad();
  },
  methods:{
    onLoad(){
      if(this.state.loading || this.state.finished){
        return;
      }
      console.log(this.state.loading);
      this.state.loading=true;
      this.$api.order_list({page:this.page,type:this.type?this.type:''}).then(r=>{
        if(r.code==200){
          // if(this.page==0){
          //   this.list=[];
          // }
          if(r.data.lists.length<=0){

            this.state.finished=true;
            this.state.loading=false;
            return false;
          }
          if(this.page==1){
            this.list = r.data.lists;
          }else{
            this.list = this.list.concat(r.data.lists);
          }
          this.page+=1;
          console.log(this.list);
        }
        setTimeout(()=>{
          this.state.loading=false;
        },500)

      });
    },
    now_pay(e){
      let d={};
      d.total = e.order_amount;
      d.master_order_sn = e.master_order_sn;
      d.orderId = e.order_id;
      this.$emit('payshow',d);
    },
    shouhuo(sv){
      this.$api.order_confirm(sv.order_id).then(r=>{
        if(r.code==200){
           Toast(r.msg);
           setTimeout(()=>{
             this.$router.push('/orderinfo',{id:sv.order_id});
           },1000)
        }
      })
    },
    tiao(id){
      this.$router.push({path:'orderinfo',query:{id:id}})
    },
    formatTimestamp(time = 0, fmt = 'yyyy-MM-dd hh:mm:ss') {
      if(time === 0 || !time) {
        return ''
      }
      time = time*1000;
      let date = new Date(time)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
      }
      return fmt;
    }
  },
  components: {
    Empty,[Card.name]:Card,
    [Button.name]:Button,
    [SwipeCell.name]:SwipeCell,
    [List.name]:List,
  },
  computed: {}

}
</script>
<style>

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}




</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
}
.www{
  position: relative;
  bottom: 1px;
  z-index: 999;
}
.card_my{
  background: white;
  /*padding: 10px;*/
  margin: 20px;
  border-radius: 10px;
}
.title{
  font-size: 16px;
  text-align: left;
  width: 160px;
  padding-left: 10px;
}
.price_s{
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 50px;
  font-size: 20px;
  font-weight: bold;
}
.goods-card{
  background: white;
}
.qpic{width: 30px;border-radius: 99px;margin-right: 8px;position: relative;top: 8px;}
.flex.ss{
  justify-content: space-between;
}
.nei_1{
  margin-left: 20px;
  font-size: 14px;
  color: black;
}
.status{
  color: #8d8b8b;
  font-size: 14px;
  padding-top:12px;
  padding-right: 20px;
}
.num{
  position: relative;
  left: 50px;
}
.time{
  padding-top: 10px;
}
.total{
  font-weight: bold;
  font-size: 14px;
}
.total span{
  color: #AC3A35;
  font-size: 18px;
  font-weight: bold;
}
.info{
  text-align: right;
  font-size: 12px;
  padding: 0px 10px 10px 10px;
  border-top: 1px solid #eee;
}
.goods_swipe{
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>