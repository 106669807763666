import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Lazyload } from 'vant';
import 'amfe-flexible'
import api from '@/api';
const app = createApp(App);
app.config.globalProperties.$api = api;


//全局左返回注册
app.config.globalProperties.onClickLeft =function(){
  window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
};
app.use(store).use(router).use(Lazyload).mount('#app')

//自定义方法

// if(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)=="micromessenger"){
//    wxShaer();
// }