<template>
    <div class="benti" @click="tiaozhuan">
      <p>
          <van-image class="goods_img" :src="image?image:original_img"/>
        <van-tag v-if="pj.div_name!=''" mark class="biaoqian" :color="pj.div_color" type="primary">{{pj.div_name}}</van-tag>
      </p>
     
      <p class="goods_pro_name">{{pro_name}}</p>
      <p class="desc" v-if="pro_name.length>0">
        <span>{{dname}}</span>
        <span>{{chandi}}</span>
      </p>

      <div v-if="jifen==undefined" class="price">
        <p>￥{{price==0?auction_price:price}}</p>
        <van-tag v-show="act_type!='no'" class="tag" plain :type="tag.color">{{tag.name}}</van-tag>
      </div>
      <p v-else class="price"><img class="jifen_image" src="@/assets/img/jf-88.png"> {{jifen}}</p>

    </div>
</template>

<script>
import {Image,Tag} from 'vant';
export default {
    name: "Goods",
    props:[
      'pro_name','image','dname','chandi',
        'price','url','jifen','pro_id','act_type','id','act_id','original_img','label','auction_price'
    ],
    data(){
      return {
          tag:{
            name:'',
            color:'',
          },
          pj:{
            'div_name':'',
            'div_color':'',
          },
      }
    },
    created() {
      if(this.url==undefined){
        this.nurl='';
      }
     
      if(this.label!=undefined && this.label!=''){
          // console.log(this.label);
          this.pj=this.label; 
      }
      // this.pj.name="大师出品";
      // this.pj.color=this.label;
      

      switch (this.act_type){
        case 'crowdfunding':
          this.tag.name = '众筹'
          this.tag.color = 'warning'
          break;
        case 'assemble':
          this.tag.name = '搭伙'
          this.tag.color = 'success'
          break;
        default:
          this.tag.name = '拍卖'
          this.tag.color = 'primary'
          break;
      }

    },
    methods:{
      tiaozhuan(){
        switch (this.act_type){
          case 'crowdfunding':
            this.$router.push({path:'/crowdgood',query:{id:this.id?this.id:this.act_id}})
            break;
          case 'assemble':
            this.$router.push({path:'/factions',query:{id:this.id?this.id:this.act_id}})
            break;
          default:
            this.$router.push({path:'/auction',query:{id:this.id?this.id:this.act_id}})
            break;
        }
      }
    },
    components:{
      [Image.name]:Image,
      [Tag.name]:Tag
    },
    computed:{
    }
}
</script>
<style scoped>
.goods_img >>> img{
  object-fit: cover;
}
.biaoqian{
    position: absolute;
    left:0px;
    /* top:1px; */
    padding: 4px 5px;
    font-size: 12px;
    font-weight: 600;
}
</style>
<style>
.goods_img{
  width: 43vw;
  /*height: 130px;*/
  height: 43vw;
  object-fit: cover;
}

.benti{
  background: white;
  border: 1px solid #eee;
  margin: 5px;
  width: 45vw;
  margin-left: 5px;
  position: relative;
  color: black;
}
.goods_pro_name{
  display: flex;
  padding-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  padding-bottom: 10px;
  text-align: left;
  height: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.desc{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 15px;
  /*padding-bottom: 5px;*/
  color: #999595;
}
.price{
  display: flex;
  justify-content: space-around;
  padding-left: 5px;
  font-size: 16px;
  color: #e00707;
  padding-bottom: 10px;
  /*padding-top: 10px;*/
  font-weight: bold;
}
.jifen_image{
  height: 12px;
  padding-right: 10px;
  position: relative;
  top: 4px;
  left: 5px;
}
.tag{

}
</style>

