<template>
  <div class="weChatLoginContainer">

  </div>
</template>

<script>
import {setKey} from "@/util/cache";
import {Toast} from "vant";

export default {
  name: "weChatLogin",
  data: () => {
    return {};
  },
  created() {
    this.doLogin();
  },
  methods: {

    /*
    * 登录
    * */
    doLogin: function () {
      Toast.loading({message: '登录中'});
      let locationString = window.location.href.split('?');
      let queryString = locationString[locationString.length - 1];
      let params = {};
      let paramArr = queryString.split('&');
      for (let i = 0; i < paramArr.length; i++) {
        let tmp = paramArr[i].split('=');
        params[tmp[0]] = tmp[1];
      }
      if (params.code) {
        this.$api.weChatLoginCallback(params).then(res => {
          if (res.code === 200) {
            setKey('Tg_token', res.data.token);
            this.$store.commit('adduser', res.data);
            this.$router.push('/');
          }
        });
      }
    },

  },
}
</script>

<style scoped>

</style>