export function Scroll() {

    let reachBottomDistance = 120;

    // 到达底部触发
    let reachBottomCallback = () => {

    };
    // 回到顶部触发
    let backTopCallback = back => console.log(back);

    let mutex = false;

    let rate = 500;

    let lastScrollTop = 0;

    window.onscroll = function () {
        let target = document.documentElement;
        let elHeight = document.body.scrollHeight;
        let elScrollTop = typeof target.scrollTop !== 'undefined' ? target.scrollTop : target.scrollY;
        if ((elHeight - elScrollTop - window.innerHeight <= reachBottomDistance) && lastScrollTop < elScrollTop) {
            if (!mutex) {
                mutex = true;
                reachBottomCallback();
                setTimeout(() => {
                    mutex = false;
                }, rate);
            }
        }
        lastScrollTop = elScrollTop;
        backTopCallback(lastScrollTop === 0);
    };

    // 移除监听
    this.removeListener = function () {
        window.onscroll = () => {};
    };

    this.onReachBottom = function (callback) {
        reachBottomCallback = callback;
        return this;
    };

    this.onBackTop = function (callback) {
        backTopCallback = callback;
        return this;
    };

    this.setReachBottomDistance = function (distance) {
        reachBottomDistance = distance;
        return this;
    };

    this.setEmitRate = function (seconds) {
        rate = seconds;
        return this;
    };

}
