<template>
  <div class="main">
    <div class="headder">
      <div class="flex kuang">
        <div style="width: 90%;display: flex;flex-direction: row;justify-content: space-between;align-items: center;background-color: #ffffff;border-radius: 10px">
          <Search placeholder="请输入名称" background="rgba(0,0,0,0)" style="border: none!important;width: 90%"
                  class="index_serach" v-model="sertxt" @search="onSearch"></Search>
          <!--     搜索按钮     -->
          <van-icon name="search" style="margin-right: 5px" color="#000000" @click="onSearch" size="30px"></van-icon>
        </div>
        <router-link to="/cart">
          <Icon name="shopping-cart" class="card" color="#FFFFFF"/>
        </router-link>
      </div>
    </div>
    <div style="background: #000;position: relative;">
      <van-tabs v-model="activeName" class="tab85" animated background="#000" color="#fff" title-active-color="#8f2526"
                title-inactive-color="#fff">
        <van-tab title-class="font14 bold" title="发现" name="index">
           <keep-alive>
                <router-view>
                    <Faxian></Faxian>
                </router-view>
            </keep-alive>
            <!-- <Faxian></Faxian> -->
        </van-tab>
        <van-tab title-class="font14 bold" title="直播" name="b">
          <Empty description="敬请期待"/>
        </van-tab>
        <van-tab title-class="font14 bold" title="茶叶" name="c">
          <Empty description="敬请期待"/>
        </van-tab>
        <van-tab title-class="font14 bold" title="茶器" name="d">
          <Empty description="敬请期待"/>
        </van-tab>
        <van-tab title-class="font14 bold" title="收视" name="f">
          <Empty description="敬请期待"/>
        </van-tab>
        <van-tab title-class="font14 bold" title="文玩" name="g">
          <Empty description="敬请期待"/>
        </van-tab>
        <van-tab title-class="font14 bold" title="研究院" name="h">
          <Empty description="敬请期待"/>
        </van-tab>
      </van-tabs>
      <div class="fenlei" @click="$router.push('/type')">
        <Icon name="bars" class="bars"/>
        <span id="fename">分类</span></div>
    </div>

    <!--登录-->
    <div class="login_tips flex" v-show="login">
      <div><img src="../assets/img/logo_w.png"></div>
      <router-link to="/login" class="kl">
        <button class="dl">登录</button>
      </router-link>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import {Tab, Tabs, Search, Icon, Empty, Button} from 'vant';
import Faxian from "@/components/Faxian";
import Bottom from "@/components/Bottom";
import {getKey} from "@/util/cache";
import {wxShare,isWx} from '@/util/wx.js'; 

export default {
  name: "Index",
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      login: false,
      tabslist: [],
    }
  },
  activated() {
    console.log('activated');
  },
  // mounted() {
  //   console.log('11');  
  // },
  created() {
    // console.log(this.$api;
    let t = getKey();
    if (t == undefined || t == '') {
      this.login = true;
    }
    this.$api.iconList().then(r => {
      let bb = [];
      if (r.code == 200) {
        for (var k in r.data) {
          bb = bb.concat(r.data[k]);
        }
        this.tabslist = bb;
      }
    })
    
    if(isWx()){
        // console.log('是微信');
        wxShare(window.location.href,'天工开物-非遗文化交易平台','对非物质文化遗产相对应产品进行拍卖、众筹、搭伙团购的网商（跨境）平台','http://fy-china.vip/pic.jpg');
    }
    

    // this.$api.iconList().then(r=>{
    //   let bb = [];
    //   if(r.code==200){
    //     for (var k in r.data){
    //       bb= bb.concat(r.data[k]);
    //     }
    //     this.tabslist = bb;
    //   }
    // })

  },
  methods: {

    /*
    * 搜索
    * */
    onSearch: function () {
      if (!/^[\s]*$/.test(this.sertxt)) {
        this.$router.push({
          path: '/search',
          query: {
            keyword: this.sertxt,
          },
        });
      }
    },

  },
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Search,
    Icon,
    Faxian,
    Empty,
    Bottom,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  computed: {}

}
</script>
<style>
.login_tips {
  width: 100%;
  position: fixed;
  bottom: 60px;
  z-index: 999;
  background: rgb(0 0 0 / 89%);
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
}

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}

.van-search__content {
  border-radius: 4px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.van-tabs__content {
  background: #f9f9f9;
}

.van-tabs__line {
  bottom: 20px;
}

.tab85 .van-tabs__wrap {
  width: 85%;
}


</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
  margin-bottom: 150px;
}

.bars {
  font-size: 18px;
  position: relative;
  top: 2px
}

.fenlei {
  position: absolute;
  right: 10px;
  top: 11px;
  color: #fff;
  z-index: 9999;
  font-size: 14px;
  font-weight: bold
}

#fename {
  position: relative;
  bottom: 2px;
}

.headder {
  background: #000000;
  padding: 14px 20px 0px 10px;
  position: relative;
}

.index_serach {
  padding: 0;
  width: 90%;
  padding-right: 5px;
}

.kuang {
  justify-content: space-between;
}

.card {
  font-size: 30px;
  padding-left: 10px;
  position: relative;
  top: 1px;
}

.dl {
  position: relative;
  /* bottom: 2px; */

}

.kl {
  display: flex;
  margin: 10px 0px;
}

</style>