import home from './home';
import user from './user';
import marke from './marke';
import collection from "@/api/collection";
import attachment from "@/api/attachment";
import search from "@/api/search";
export default {
    ...home,
    ...user,
    ...marke,
    ...collection,
    ...attachment,
    ...search,
}