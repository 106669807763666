import request from "@/util/request";
export default {
    /*首页数据*/
    getindex:(p=1)=>{
        return request.get('/NewApi/open/auctionList',{params:{page:p}});
    },
    /*首页数据*/
    iconList:()=>{
        return request.get('/NewApi/open/iconList');
    },
//    分类数据
    ajaxCategoryList:()=>{
        return request.get('/NewApi/open/ajaxCategoryList')
    },
    ajaxGetActList(id,p){
        return request.get('/NewApi/open/ajaxGetActList',{params:{id:id,page:p}});
    },
    //通用化图片上传
    upload_img:(file)=>{
        let formdata = new FormData()
        formdata.append('file', file)
        // formdata.append('title', "sadasdsa")
        return request.post('/NewApi/newjoin/uploadImg',formdata,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    },
    //获取微信分享
    wxshare:(url)=>{
        return request.get('/NewApi/open/wxShare',{params:{url:url}}
        )
    }
}