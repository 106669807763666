import Index from '@/views/search/Index';

export default [
    {
        name: 'Search',
        path: '/search',
        component: Index,
        meta: {
            title: '搜索',
        },
    }
];