import request from "@/util/request";
export default {
    xxx:(data)=>{
        return request.post('/NewApi/open/sendRegSmsCode',data);
    },
    //拍卖数据
    Auctiondata:(id)=>{
        return request.get('/NewApi/auction/detailJson',{
            params:{id:id}
        });
    },
    //最高价
    getLeader:(id)=>{
        return request.get('/NewApi/auction/getLeader',{
            params:{auction_id:id}
        });
    },
//    * Ws 认证, 请求后端服务进行认证
    WsAuth:(data)=>{
        return request.post('/NewApi/wsUtil/wsAuth',data);
    },
//    获取保证金状态
    checkBond:(id)=>{
        return request.get('/NewApi/auction/checkBond',{
            params:{
                id:id
            }
        })
    },
//    出价
    auction:(d)=>{
        return request.post('/NewApi/auction/auction_push',d);
    },
//    支付保证金
    getPay:(params)=>{
        return request.get('/NewApi/auction/bondPay',{
            params
        })
    },

    // 支付保证金(微信支付)
    bondWeChatPay: (params) => {
        return request.get('/NewApi/auction/bondWeChatPay', {
            params
        });
    },

    // 微信支付的保证金查单
    weChatBondQuery: (params) => {
        return request.post('/NewApi/auction/weChatBondQuery', params);
    },
    
    // 支付店铺押金(微信支付)
    kdWeChatPay: (params) => {
        return request.get('/NewApi/Newjoin/kdWeChatPay', {
            params
        });
    },
    
    // 微信支付的押金查单
    weChatKdQuery: (params) => {
        return request.post('/NewApi/Newjoin/weChatKdQuery', params);
    },

//    众筹数据
    crowdata:(id)=>{
        return request.get('/NewApi/crowdfunding/detailJson',{
            params:{
                crowdfunding_id:id
            }
        })
    },
    //    众筹下单
    croworder:(d)=>{
        return request.get('/NewApi/crowdfunding/order',{
            params:d
        })
    },
//  订单支付
    orderConfirm:(t='crow',d)=>{
        let url;
        if(t=='crow'){
            url= '/NewApi/crowdfunding/orderConfirm';
        }else if(t=='assemble'){
            url = '/NewApi/assemble/orderConfirm';
        }else if(t == 'assembleSingle') {
            url = '/NewApi/assemble/singleOrderConfirm';
        }
        return request.post(url,d);
    },
    pay:(t='crwo',d)=>{
        if(t=='crow'){
            var url = '/NewApi/crowdfunding/pay';
        }
        return request.post(url,d);
    },
    factiondata:(d)=>{
        return request.get('/NewApi/assemble/detailJson',{
            params:d
        })
    },
    //    拼团下单
    factionorder:(d)=>{
        return request.get('/NewApi/assemble/order',{
            params:d
        })
    },

    // 拼团(搭伙)单独购买
    assembleSingleOrder: (d) => {
        return request.get('/NewApi/assemble/singleOrder',{
            params:d
        })
    },

    pm_list:(d)=>{
        return request.get('/NewApi/auction/pendingList',{params:d});
    },
    dh_list:(d)=>{
        return request.get('/NewApi/assemble/myAssembleJson',{params:d});
    },
    share:(d)=>{
        return request.get('/NewApi/assemble/genShareCode',{params:d});
    },
    myteam:(d)=>{
        return request.get('/NewApi/assemble/share',{params:{share_code:d}});
    },
    get_pj(id,p){
        return request.get('/NewApi/open/pingjia',{params:{gid:id,page:p}});
    },
    PmIndexJson(p){
        return request.get('/NewApi/auction/indexJson',{params:{page:p}});
    },
    crowdfundingList(p){
        return request.get('/NewApi/crowdfunding/crowdfundingList',{params:{page:p}});
    },
    assembleList(p){
        return request.get('/NewApi/assemble/assembleList',{params:{page:p}});
    },

}