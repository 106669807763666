import request from "@/util/request";

export default {
    /*获取验证码*/
    sendRegSmsCode: (data) => {
        return request.post('/NewApi/open/sendRegSmsCode', data);
    },
    //注册
    registerConfirm: (data) => {
        return request.post('/NewApi/user/registerConfirm', data);
    },
    //密码登录
    login: (data) => {
        return request.post('/NewApi/user/do_login', data);
    },
    // 微信登录
    weChatLogin: (data) => {
        return request.post('/NewApi/WeChatLogin/login', data);
    },
    // 微信登录回调
    weChatLoginCallback: (data) => {
        return request.post('/NewApi/WeChatLogin/loginCallback', data);
    },
    //验证码密码登录
    ylogin: (data) => {
        return request.post('/NewApi/user/doSmsLogin', data);
    },
    //找回密码
    resetPasswordByPhone: (data) => {
        return request.post('/NewApi/user/resetPasswordByPhone', data);
    },
    //修改用户
    put_userinfo: (d) => {
        return request.put('/NewApi/user/userinfo', d);
    },
    //获取用户
    userinfo: () => {
        return request.get('/NewApi/user/userinfo');
    },
    //检测/设置payid
    acPayId:(d)=>{
         if(d.method=='get'){
             return request.get('/NewApi/user/payid',{params: d});
         }else{
             return request.post('/NewApi/user/payid',d);
         }
    },
    //获取用户
    exit_useri: () => {
        return request.delete('/NewApi/user/userinfo');
    },
    //用户中心数据
    userindex: () => {
        return request.get('/NewApi/user/index');
    },
//    收货地址
    address_list: () => {
        return request.get('/NewApi/user/address_list');
    },
    add_address: (d) => {
        return request.post('/NewApi/user/add_address', d);
    },
    getaddress: (d) => {
        return request.get('/NewApi/user/edit_address', {params: d});
    },
    del_address: (id) => {
        return request.get('/NewApi/user/del_address', {
            params: {
                id: id
            }
        });
    },
    edit_address: (d) => {
        return request.post('/NewApi/user/edit_address', d);
    },
    order_list: (d) => {
        return request.post('/NewApi/user/order_list', d);
    },
    order_detail: (d) => {
        return request.get('/NewApi/user/order_detail', {params: d});
    },
    cancel_order: (id) => {
        return request.get('/NewApi/user/cancel_order', {params: {id: id}});
    },
    order_confirm: (id) => {
        return request.get('/NewApi/user/order_confirm', {params: {id: id}});
    },
    focus: (id) => {
        return request.post('/NewApi/store/focus', {store_id: id});
    },
    comment: (d) => {
        return request.get('/NewApi/user/comment', {params: d});
    },
    comment_list: (d) => {
        return request.get('/NewApi/user/comment_list', {params: d});
    },
    conmment_add: (d) => {
        return request.post('/NewApi/user/conmment_add', d);
    },
    //获取分类
    get_storelist() {
        return request.get('/NewApi/newjoin/storelist');
    },
    //获取省市区
    get_arealist() {
        return request.get('/NewApi/newjoin/arealist');
    },
    //获取公司申请信息
    cmdata() {
        return request.get('/NewApi/newjoin/cmdata');
    },
    //个人/企业提交
    one_sumb(d) {
        return request.post('/NewApi/newjoin/saveContact', d);
    },
    two_sumb(d) {
        return request.post('/NewApi/newjoin/saveCompanyBasic', d);
    },
    kd_check() {
        return request.get('/NewApi/newjoin/check');
    },
    kd_status() {
        return request.get('/NewApi/newjoin/status');
    },
    getPayForm() {
        return request.get('/NewApi/newjoin/getPayForm', {params: {host: window.location.host}})
    }
}