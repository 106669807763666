<template>
  <div class="main">
    <div class="flex l_top">
      <router-link to="/">
        <div style="font-size: 20px;">X</div>
      </router-link>
      <router-link to="/ylogin">
        <div style="line-height: 26px">验证码登录</div>
      </router-link>
    </div>
    <div class="flex denglu">
      登录
    </div>
    <div class="form">
      <Field class="input" v-model="info.mobile" maxlength="11"   type="tel" label="手机号"  placeholder="请输入手机号"></Field>
      <Field class="input" v-model="info.password" type="password" label="密 码" placeholder="请输入密码"></Field>

        <div class="flex" style="justify-content: flex-end;font-size: 14px;margin-top: 8px;color: black;">
          <router-link :to="{path:'/rest',query:{rg:1}}" style="padding-right: 5px;">注册账号</router-link>
          <router-link to="/rest">忘记密码？</router-link>
        </div>
      <Button id="denglu_btn" type="primary" size="large" @click="smp" color="#AC3C35">登录</Button>
    </div>
    <div class="xieyi">
      <p>未注册手机登录后即自动注册</p>
      <p>代表您同意天工开物 <span>用户协议</span> 和 <span>隐私政策</span></p>
    </div>
    <div class="qita">
      - 其他登录方式 -
    </div>
    <div class="si_icon">
      <p><img src="../../assets/img/weixin.png" @click="weChatLogin"></p>
   <!--   <p><img src="../../assets/img/qq.png" @click="tips"></p>
      <p><img src="../../assets/img/weibo.png" @click="tips"></p>
      <p><img src="../../assets/img/pguo.png" @click="tips"></p> -->
    </div>
  </div>
</template>

<script>
import {Field,Button,Toast} from 'vant';
import {setKey} from "@/util/cache";

export default {
  name: "lgoin",
  data(){
    return {
      sertxt:'',
      activeName:'index',
      info:{
        mobile:'',
        password:'',
      }
    }
  },
  created() {
  },
  methods:{
    tips(){
      Toast('暂未开放');
    },

    /**
    * 微信登录
    * */
    weChatLogin() {
      this.$api.weChatLogin({
        call_back_url: window.location.protocol +'//' + window.location.hostname + '/weChatLogin',
        // call_back_url: 'http://www.tgkw.cn/weChatLogin',
      }).then(res => {
        let data = res.data;
        if (res.code === 200){
          window.location.href = data.url;
        }
      });
    },

    smp(){
        if(!(/^1[3456789]\d{9}$/.test(this.info.mobile))){
          Toast('手机号输入有误');
          return;
        }

        if(this.info.password.length==''){
          Toast('请输入密码');
          return;
        }

      this.$api.login(this.info).then(r=>{
        if(r.code==200){
          setKey('Tg_token',r.data.token);
          this.$store.commit('adduser',r.data);
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        }
      })


    }
  },
  components:{
    Field,Button
  },
  computed:{

  }

}
</script>
<style>
.van-search__content{
  border-radius: 4px;
}
</style>
<style scoped>
  .main{
    /*width: 100%;*/
    background: white;
    height: 95vh;
    color: black;
    padding: 15px;
  }
  .l_top{
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .denglu{
    margin: 20px;
    font-size: 32px;
    font-weight: 400;

  }
  .form{
    margin-bottom: 20px;
  }
  .input{
    margin-bottom: 20px;
  }
  #denglu_btn{
    width: 80%;padding: 10px;margin-top: 20px;
    margin-bottom: 10px;
  }
  .xieyi{
    font-size: 14px;
    color: #8d8b8b;
  }
  .xieyi span{
    color: black;
  }
  .qita{
    margin: 20px;
    margin-top: 20vh;
    color: #8d8b8b;
    font-size: 14px;
  }
  .si_icon{
    display: flex;
    justify-content: center;
  }
  .si_icon img{padding: 10px;width: 60px;}

</style>