<template>
  <div class="main">
      <van-nav-bar
          :title="good_info.title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
          style="background: black"
      >
        <template #right >
          <van-icon name="share" size="18" style="color: white;" />
        </template>
    </van-nav-bar>
    <!-- <Pj v-if="good_info.label!=''" :label="good_info.label"></Pj> -->
    <Pj v-if="good_info.label!=''" :label="good_info.label"></Pj>
    <van-swipe class="my-swipe" :autoplay="isPlayVideo ? false : 3000" indicator-color="white" @change="swipeChange">

      <!--   视频   -->
      <van-swipe-item v-if="good_info.video">
        <video :src="good_info.video" @click="playVideo" class="auctionVideo" @ended="playVideo" ref="descVideo"></video>
        <div class="auctionVideoAction" v-show="!isPlayVideo">
          <van-icon name="play-circle" size="60px" color="#FFFFFF" @click="playVideo"></van-icon>
        </div>
      </van-swipe-item>

      <template v-for="(v,k) in images" :key="k">
        <van-swipe-item @click="show_img(k)">
          <img class="ms_img" v-lazy="v" />
        </van-swipe-item>
      </template>
    </van-swipe>

    <div class="p1">
      <div class="flex p1_1">
        <div class="title">
          <div class="flex">
            <van-tag type="primary" class="status" size="medium">{{good_info.status === 1 ? '进行中' : good_info.status === 0 ? '未开始' : '已结束'}}</van-tag>
            <p>{{good_info.title}}</p>
          </div>
          <div class="description">
            {{good_info.description}}
          </div>
        </div>
<!--        <p class="money">¥88 <span class="txtdel">¥99</span></p>-->
        <p class="kucun">{{good_info.num}}人支持</p>
      </div>
<!--      <div class="title flex">{{good_info.title}}</div>-->
    </div>

    <div class="p1 p1_targer">
      <van-progress id="tiao" :pivot-text="parseInt(good_info.progress)+'%'" color="#ee0a24" :percentage="parseInt(good_info.progress)" stroke-width="8"/>
      <div class="flex targer">
        <div class="flex">
          <p class="targer_title">目标金额</p>
          <p>¥ {{good_info.target_price}}</p>
        </div>
        <div class="flex">
          <p class="targer_title">认筹金额</p>
          <p>¥ {{good_info.current_price}}</p>
        </div>
      </div>
      <div class="end_time flex" style="justify-content: center;">结束时间:
        <CountDown  :time="(parseInt(good_info.end_time) - Math.round(new Date()/1000))*1000" format="DD 天 HH 时 mm 分 ss 秒" /></div>
    </div>

    <div class="p1 dd" >
      <div class="flex pj">
        <p>作品评价 ( {{good_info.pl_num}} )</p>
        <router-link :to="{path:'/evaluate',query:{id:good_info.pro_id}}">
          <p class="more">查看更多 <van-icon id="pj_right" name="arrow"></van-icon></p>
        </router-link>
      </div>
      <template v-if="good_info.pl.length>0">
        <template v-for="(va,ks) in good_info.pl" :key="ks">
          <div class="flex pj_1" >
            <div class=" flex">
              <p ><img class="avatar" v-lazy="va.avatar?$va.head:'/avatar.png'"></p>
              <div class="nickname">
                {{va.nickname}}
                <p class="star"><Rate v-model="va.goods_rank" size="12" readonly></Rate></p>
              </div>

            </div>
            <div class="createtime">{{va.add_time}}</div>
          </div>
          <div class="descx">
            <!--          <template v-for="(p,pk) in va.pics" :key="pk">-->
            <!--            <img v-lazy="p">-->
            <!--          </template>-->
            <div class="desc_text">{{va.content}}</div>
          </div>
        </template>
      </template>
    </div>

    <div class="p2">
      <p>众筹详情</p>
    </div>
    <div class="htmlv" v-html="good_info.goods_content"></div>


    <van-action-bar class="bar">
      <van-action-bar-icon icon="shop-o" text="店铺" @click="onClickIcon" />
      <van-action-bar-icon icon="chat-o" text="私信" @click="onClickIcon" />
      <van-action-bar-icon icon="star-o" text="收藏" @click="onClickIcon" />
      <van-action-bar-button type="danger"  text="立即众筹" @click="onClickButton" />
    </van-action-bar>

    <Sku v-if="good_info.support_levels.length>0 && payshow==true" @close="payshow=false;" @onpay="pay" :sku="good_info.support_levels">

    </Sku>
  </div>
</template>

<script>
import {NavBar, Toast,Swipe,SwipeItem,ImagePreview,Icon,Rate,
  ActionBar, ActionBarIcon, ActionBarButton,Progress,Tag,CountDown} from 'vant';
import Sku from "@/components/sku/Sku";
import Pj from '@/components/Pj'
import {wxShare,isWx} from '@/util/wx.js'; 

// 字符串内容全部替换
String.prototype.replaceAll = function(find, replace){
  let reg = new RegExp(find,"g");  //创建正则RegExp对象
  return this.replace(reg, replace);
};

// 将被转义的 html 代码转义回原始状态
String.prototype.unescape = function () {
  return this.replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
      .replaceAll("&#39;", "'")
      .replaceAll("&amp;nbsp;", "&nbsp;");
};
export default {
  name: "CrowdGood",
  data() {
    return {
      images:[],
      show:false,
      payshow:false,
      index:1,
      pid:0,
      pj:'',
      good_info:{
        num:0,
        title:'祖传陶瓷碗',
        pingjia:88,
        support_levels:[],
        pl:[

        ],
        label:''
      },

      isPlayVideo: false,
    }
  },
  created() {
    this.pid = this.$route.query.id;
    if(this.pid==undefined || this.pid<=0){
        this.$router.push('/');
    }
    

    this.$api.crowdata(this.pid).then(r=>{
      if(r.code==200){
          // console.log(r.data);
        r.data.goods_content = r.data.goods_content.unescape();
        this.good_info = r.data;
        this.good_info.pingjia=0;
        this.images = this.good_info.images.concat([r.data.img]);
        
        if(isWx()){
            wxShare(window.location.href,r.data.goods_name,r.data.description,r.data.img);
        }
        // console.log(this.images)
      }else{
        Toast(r.msg);
        this.$router.push('/');
      }
    })

  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    onClickButton(){
      this.payshow=true;
    },
    show_img(k){
      ImagePreview({
        images:this.images,
        startPosition: k,
      });
    },
    pay(d){
      // console.log(d);
      this.$router.push({path:'checkin',query:{id:this.pid,level_id:d.id}})
    },

    /*
    * 轮播切换回调事件
    * */
    swipeChange: function () {
      if (this.good_info.video){
        let el = this.$refs.descVideo;
        if (!el.paused){
          this.playVideo();
        }
      }
    },

    /*
    * 播放视频
    * */
    playVideo: function () {
      let el = this.$refs.descVideo;
      if (el.paused){
        el.play();
      }else{
        el.pause();
      }
      this.isPlayVideo = !el.paused;
    },
  },
  components: {
    [NavBar.name]:NavBar,
    [Swipe.name]:Swipe,
    [SwipeItem.name]:SwipeItem,
    [Icon.name]:Icon,
    [ActionBar.name]:ActionBar, [ActionBarIcon.name]:ActionBarIcon, [ActionBarButton.name]:ActionBarButton,
    Rate,[Progress.name]:Progress,[Tag.name]:Tag,CountDown,Sku,Pj
  },
  computed: {}
}
</script>

<style scoped>

.auctionVideo{
  width: 100%;
  height: 365px;
  background-color: #000000;
  object-fit: contain;
}

.auctionVideoAction{
  width: 100%;
  height: 365px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}
   
  .my-swipe{
    width: 100%;
    height: 340px;
    position: relative;
    bottom: 1px;
    z-index: 10;
  }
  .main{
    margin-bottom: 100px;
  }
  .bar{padding:10px;}
  .ms_img{
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
  .p1{
    margin: 10px;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }
  .p2{
    background: black;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
  .p1_1{
    justify-content: space-between;
  }
  .pj{
    margin-bottom: 10px;
    justify-content: space-between;
    font-size: 16px;
    color: black;
  }
  .more{
    font-size: 14px;
    color: #8d8b8b;
    position: relative;
    top: 2px;
  }
  #pj_right{position: relative;top: 2px}
  .money{color: #d2211b;font-weight: bold}
  .money span{color: #4e4949;font-weight: lighter;font-size: 16px;}
  .kucun{color: #8d8b8b;font-size: 14px;position: relative;top: 6px}
  .title{justify-content: start;padding-top: 5px;font-size: 16px;font-weight: bold;color: black;text-align: left;width: 280px;}
  .avatar{width: 40px;border-radius: 99px;}
  .nickname{color: #8d8b8b;font-size: 14px;padding-left: 10px;text-align: left;}
  .pj_1{justify-content: space-between;}
  .createtime{color: #8d8b8b;font-size: 14px;position: relative;top:14px;}
  .descx{justify-content: start;text-align: left;padding-left: 50px;font-size: 16px;padding-top: 5px;margin-bottom: 10px;}
  .descx img{width: 80px;padding: 10px;}
  .desc_text{padding-top: 0px;position: relative;bottom: 10px;font-size: 14px;}
  .dd{margin-bottom: 20px;}
  .targer{
    margin:10px 0px;
    font-size: 16px;
    justify-content: space-around;
    color: black;
  }
  .targer_title{
    margin-right: 10px;
  }
  .p1_targer{
    padding-top: 20px;
  }
  .end_time{font-size: 14px;color: #8d8b8b;}
  .p3{
    padding: 10px;
  }
  .status{
    margin-bottom: 5px;
    margin-right: 5px;
    margin-top: 1px;
    min-width: 37px;
    height: 18px;
    text-align: center;
    /* padding: 0px 8px; */
  }
  .description{
    font-size: 14px;
    font-weight: lighter;
    margin-top: 10px;
    font-size: 14px;
    color: #4E4949;
  }
  .htmlv{
    margin: 5px;
    font-size: 14px;
	text-align: left;
  }
  .htmlv >>> img{
    width: 100%;
	display: block;
  }
  .htmlv >>> video{
    width: 100%;
  }
  #tiao >>> .van-progress__portion{
    max-width: 100%;
  }
</style>
