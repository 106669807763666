<template>
  <div class="main">

    <!--  顶部导航  -->
    <van-nav-bar
        title="大师馆"
        left-text=""
        left-arrow
        @click-left="back"
        style="background: black">
    </van-nav-bar>

    <!--  内容列表  -->
    <div class="contentList">
      <div v-for="item in lists" class="content" :key="item.id" @click="viewDetail(item.link)">
        <!--    展示图    -->
        <div class="contentPoster">
          <img :src="item.poster" class="contentPosterImg" alt="">
        </div>

        <!--    右侧内容名称和简介    -->
        <div class="contentRight">
          <!--     内容名称     -->
          <div class="contentName">
            {{ item.name }}
          </div>

          <!--    内容简介      -->
          <div class="contentDescription">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {NavBar, Toast} from "vant";
import config from '../../../config.base';
import {Scroll} from "@/util/scroll";

export default {
  name: "Index",
  components: {
    [NavBar.name]: NavBar,
  },
  data: () => {
    return {

      mutex: false,

      lists: [],

      currentPage: 0,

    };
  },
  created() {
    this.getLists();
  },
  mounted() {
    new Scroll().setReachBottomDistance(20).setEmitRate(500).onReachBottom(() => {
      this.getLists();
    });
  },
  beforeUnmount() {
    window.onscroll = () => {};
  },
  methods: {

    /*
    * 返回
    * */
    back: function () {
      this.$router.back();
    },

    /*
    * 获取大师馆列表
    * */
    getLists: function () {
      if (this.mutex) {
        Toast('操作过于频繁');
        return;
      }
      this.mutex = true;
      let page = this.currentPage;
      this.$api.collectionList({
        page: ++page,
      }).then(res => {
        if (res.code === 200) {
          if (res.data.length === 0) --page;
          this.currentPage = page;
          let data = res.data;
          for (let i = 0; i < data.length; i++) {
            data[i].poster = config.baseURL + data[i].poster;
          }
          this.lists = this.lists.concat(res.data);
        }
        this.mutex = false;
      }).catch(() => {
        this.mutex = false;
      });
    },

    /*
    * 查看详情
    * */
    viewDetail: function (link) {
      if (link) {
        window.open(link);
      } else {
        Toast('暂无预览地址');
      }
    },

  },
}
</script>

<style scoped>
html, body {
  background-color: #f4f4f4;
}

.contentList {
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 100%;
}

.content {
  width: calc(50% - 12px);
  margin: 10px 5px;
  background-color: #ffffff;
  /*border-radius: 5px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eee;
}

.contentPoster {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*border-radius: 5px;*/
}

.contentPosterImg {
  width: 100%;
  height: 120px;
  object-fit: cover;
  /*border-radius: 5px;*/
}

.contentRight {
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.contentName {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentDescription {
  font-size: 14px;
  text-align: left;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 2em;
}

</style>