<template>
  <div>
    <van-nav-bar
        title="意见反馈"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <van-form @submit="onSubmit" class="form">
      <van-field
          v-model="username"
          error
          required
          label="+86 "
          placeholder="请输入手机号码"
      />

      <van-field
          v-model="message"
          rows="2"
          autosize
          label="反馈信息"
          type="textarea"
          maxlength="140"
          placeholder="请输入留言"
          show-word-limit
      />

      <van-field name="uploader" label="附件">
        <template #input>
          <van-uploader v-model="fileList" multiple :max-count="3" :after-read="afterRead"/>
        </template>
      </van-field>

      <div style="margin: 16px;">
        <van-button round block type="primary" style="background: #AC3A35;border: 1px solid #AC3A35;" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {NavBar,Icon,Form,Field,Button,Uploader} from 'vant'
import {ref} from 'vue'
export default {
    name: "Feedback",
    setup() {
      const fileList = ref([
        { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
      ]);

      const afterRead = (file) => {
        file.status = 'uploading';
        file.message = '上传中...';

        setTimeout(() => {
          file.status = 'failed';
          file.message = '上传失败';
        }, 1000);
      };

      return {
        fileList,
        afterRead
      };
    },
    data(){
        return {
        
        }
    },
    created() {
    },
    methods:{
    
    },
    components:{
      [NavBar.name]:NavBar,[Icon.name]:Icon,
      [Form.name]:Form,
      [Field.name]:Field,
      [Button.name]:Button,
      [Uploader.name]:Uploader
    },
    computed:{
    
    }
}
</script>

<style scoped>
.form{
  margin: 10px;
  border-radius: 18px;
}
.van-cell.van-field:first-child{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.van-cell.van-field:nth-child(3){
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
