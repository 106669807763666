import { createStore } from 'vuex'

export default createStore({
  state: {
    token:'',
    share_code:'',
    user:{
      nickname:'',
      mobile:'',
      head_pic:'',
      token:'',
      user_money:'',
      level:'',
      sex:'0',
      user_id:'0',
      // email:'',
    },
  },
  //方法
  mutations: {
    addToken(state,num){
      state.token = num;
    },
    add_share_code(state,v){
      // console.log(v);
      state.share_code = v;
      // console.log(state.share_code);
    },
    adduser(state,val){
      state.user = val;
    }
  },
  actions: {
  },
  //获取
  getters:{
    getToken(state){
      return state.token;
    }
  },
  modules: {
  }
})
