<template>
  <div class="main">
    <van-nav-bar
        :title="good_info.goods_name"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
        <van-icon name="share" size="18" style="color: white;"/>
      </template>
    </van-nav-bar>
    <Pj v-if="good_info.label!=''" :label="good_info.label"></Pj>
    <van-swipe class="my-swipe" :autoplay="isPlayVideo ? false : 3000" indicator-color="white" @change="swipeChange">

      <!--   视频   -->
      <van-swipe-item v-if="good_info.video">
        <video :src="good_info.video" @click="playVideo" class="auctionVideo" @ended="playVideo"
               ref="descVideo"></video>
        <div class="auctionVideoAction" v-show="!isPlayVideo">
          <van-icon name="play-circle" size="60px" color="#FFFFFF" @click="playVideo"></van-icon>
        </div>
      </van-swipe-item>

      <template v-for="(v,k) in good_info.images" :key="k">
        <van-swipe-item @click="show_img(k)">
          <img class="ms_img" v-lazy="v"/>
        </van-swipe-item>
      </template>
    </van-swipe>

    <div class="flex newjia">
      <div class="left">
        <span>当前价：¥</span>
        <span class="price_now">{{ now_price }}</span>
        <span class="status">{{ good_info.status_cn }}</span>
      </div>
      <div class="right">
        <p class="qi">结拍时间</p>
        <p class="time">
          <van-count-down :time="good_info.end_time" format="DD天 HH时 mm分 ss秒"/>
        </p>
      </div>
    </div>

    <div class="p1">
      <div class="title flex">{{ good_info.description }}</div>
      <div class="flex pj tiao">
        <p class="flex title_lan">
          <span>起拍价 ¥{{ good_info.starting_price }}</span>
          <span>加价幅度 ¥{{ good_info.price_increase }}</span>
          <span>保证金 ¥{{ good_info.insured_price }}</span>
        </p>
        <p class="more">
          <van-icon class="title_icon" name="arrow"></van-icon>
        </p>
      </div>
    </div>

    <!--    <div class="p1">-->
    <!--      <div class="p1_info">-->
    <!--        <p>· 正品保障</p>-->
    <!--        <p>· 七天无理由</p>-->
    <!--      </div>-->
    <!--      <button class="canshu_cat" @click="cat_zp" >作品参数</button>-->
    <!--    </div>-->


    <van-popup
        v-model:show="zpshow"
        closeable
        close-icon="close"
        position="bottom"
        :style="{}"
    >
      <div class="title_zp">作品参数</div>
      <div class="canshu_zp flex">
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
        <div class="line_zp">
          <p style="color:#8d8b8b;">参数</p>
          <p>参数</p>
        </div>
      </div>

    </van-popup>


    <div class="p1 dd">
      <div class="flex pj" @click="showchujia">
        <p>出价记录 ( {{ good_info.auction_list.length }} )</p>
        <p class="more">
          <van-icon id="pj_right" name="arrow"></van-icon>
        </p>
      </div>
      <div v-if="good_info.auction_list.length>0">
        <template v-for="(va,ks) in good_info.auction_list" :key="ks">
          <div class="flex pj_1">
            <div class=" flex">
              <p><img class="avatar" v-lazy="va.avatar"></p>
              <div class="nickname">
                {{ va.nickname }}
                <p class="star">
                  <template v-if="ks==0">
                    <van-tag type="success">领先</van-tag>
                  </template>
                  <template v-else>
                    <van-tag type="danger">出局</van-tag>
                  </template>
                </p>
              </div>
            </div>

            <div class="createtime">
              <div class="pai_price">¥ {{ va.price }}</div>
              <div class="createtime_time">{{ va.time }}</div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="wujia">
        暂无出价
      </div>

    </div>

    <!--    tabs-->
    <van-tabs class="" v-model="activeName" animated background="#000" color="#fff" title-active-color="#AC3C35"
              title-inactive-color="#fff" style="width: 100%;z-index: 999;">
      <van-tab title-class="font14 bold" title="拍卖详情" name="index">
        <div style="font-size: 16px;" class="xiangqing">
          <div v-html="good_info.goods_content">
          </div>
        </div>

      </van-tab>
      <van-tab title-class="font14 bold" title="推荐拍品" name="b">
        <template v-if="good_info.other_auction.length>0">
          <div class="goods_list">
            <template v-for="(v,k) in good_info.other_auction" :key="k">
              <Goods :="v"></Goods>
            </template>
          </div>
        </template>
        <Empty v-else description="暂无推荐"/>
      </van-tab>
    </van-tabs>

    <van-action-bar class="bar">
      <van-action-bar-icon icon="smile-comment-o" text="讨论" @click="empty"/>
      <van-action-bar-icon icon="clock-o" text="提醒" @click="empty"/>
      <van-action-bar-button type="danger" @click="pay" text="立即出价" color="#AB3C35"/>
    </van-action-bar>

    <!--    保证金弹出-->
    <van-overlay :show="bzjshow" style="z-index: 99999;width: 100%;height: 100%;" @click="bzjshow = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <p class="tips_bz">缴纳保证金：{{ good_info.insured_price }}元</p>
          <p class="tips_bz_gz">保证金规则</p>
          <button class="jiaonai" @click="bondPayMethodChooser">立即缴纳</button>
        </div>
      </div>
    </van-overlay>

    <!--  保证金支付方式选择弹窗  -->
    <van-action-sheet
        v-model:show="showBondPayMethod"
        :actions="bondPayMethods"
        cancel-text="取消"
        @cancel="onBondPayCancel"
        @select="onSelectBondPayMethod"
        close-on-click-action>
    </van-action-sheet>

    <!--    出价弹窗-->
    <van-popup
        v-model:show="chujiashow"
        closeable
        close-icon="close"
        position="bottom"
        :style="{}"
    >
      <div class="title_zp flex">
        <p style="color: #d2211b;">出价记录</p>
        <p @click="empty">讨论区</p>
      </div>
      <div v-if="good_info.auction_list.length>0" style="margin: 10px">
        <template v-for="(va,ks) in good_info.auction_list" :key="ks">
          <div class="flex pj_1">
            <div class=" flex">
              <p><img class="avatar" v-lazy="va.avatar"></p>
              <div class="nickname">
                {{ va.nickname }}
                <p class="star">
                  <template v-if="ks==0">
                    <van-tag type="success">领先</van-tag>
                  </template>
                  <template v-else>
                    <van-tag type="danger">出局</van-tag>
                  </template>
                </p>
              </div>
            </div>

            <div class="createtime">
              <div class="pai_price">¥ {{ va.price }}</div>
              <div class="createtime_time">{{ va.time }}</div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="wujia" style="margin: 10px;">
        暂无出价
      </div>

    </van-popup>

    <div id="paydom">

    </div>

    <!--    出价-->
    <!--    <Pay total="88" v-if="bzj_moeny"></Pay>-->

    <Pmpay :total="parseInt(now_price)" @gbchujia="gb_chujia" @pushprice="chujia_pay" :user="now_jinzhu"
           :show="push_show"></Pmpay>
  </div>


</template>

<script>
import {
  ActionSheet, NavBar, Toast, Swipe, SwipeItem, ImagePreview, Icon,
  ActionBar, ActionBarIcon, ActionBarButton, Popup, Empty, Tab, Tabs, Overlay, Tag, CountDown
} from 'vant';
// import Pay from "@/components/Pay";
import Pmpay from "@/components/Pmpay";
import Goods from "@/components/Goods";
import Pj from "@/components/Pj";
import {wxShare,isWx} from '@/util/wx.js'; 

// 字符串内容全部替换
String.prototype.replaceAll = function (find, replace) {
  let reg = new RegExp(find, "g");  //创建正则RegExp对象
  return this.replace(reg, replace);
};

// 将被转义的 html 代码转义回原始状态
String.prototype.unescape = function () {
  return this.replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
      .replaceAll("&#39;", "'")
      .replaceAll("&amp;nbsp;", "&nbsp;");
};

export default {
  name: "Auction",
  data() {
    return {
      show: false,
      bzjshow: false,
      zpshow: false,
      chujiashow: false,
      push_show: false,
      bzj_moeny: false,
      chujia: false,
      pid: 0,
      index: 1,
      now_jinzhu: {'avatr': '', 'nickname': ''},
      // socket
      WsServer: {},
      // 同步请求锁定
      mutex: false,
      // Ws 认证Key
      WsAuthKey: '',
      // 结束提示
      endTip: '',
      mtx: false,
      // 出价列表
      auctionList: [],
      now_price: 0,
      good_info: {
        num: 0,
        goods_remark: '祖传陶瓷碗',
        pingjia: 88,
        other_auction: [],
        auction_list: [],
        label: '',
        pl: [
          {
            avatar: require('../../assets/img/tu3.png'),
            star: 5,
            nickname: '小天',
            price: 88,
            craete_time: 'xxx-xx-xx'
          },
        ]
      },

      isPlayVideo: false,

      // 是否显示保证金支付方式选择
      showBondPayMethod: false,

      // 保证金支付方式列表
      bondPayMethods: [
        {
          name: '支付宝',
          type: 0,
        },
        {
          name: '微信支付',
          type: 1,
        },
      ],
    }
  },
  created() {
    this.pid = this.$route.query.id;
    if (this.pid == undefined || this.pid < 0) {
      this.$router.go(-1);
      return;
    }
    this.payid();
    this.$nextTick(() => {
      this.getAuctionDetail();
      this.getLeader();
      // 暂不使用
      this.WsInit();
    });
    
    

  },
  methods: {
    payid(){
        if(window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
          if(this.$route.query.code!=undefined){
              let data={};
              data.code = this.$route.query.code;
              data.method = 'post';
              this.$api.acPayId(data).then(r=>{
                  if(r.code!==200){
                      Toast('获取id错误');
                  }
              })
          }else{
              this.$api.acPayId({
                call_back_url: window.location.href,
                method:'get'
              }).then(res => {
                if (res.data.url !== undefined){
                     window.location.href = res.data.url;
                }
              });
          }
        }
      },
    /*
    * 保证金支付方式选择取消
    * */
    onBondPayCancel: function () {
      this.showBondPayMethod = false;
    },

    /*
    * 保证金支付方式选择
    * */
    onSelectBondPayMethod: function (e) {
      if (e.type === 1) {
        this.bondWeChatPay();
      } else {
        this.bondPay();
      }
    },

    /*
    * 微信支付保证金
    * */
    bondWeChatPay: function () {
      this.$api.bondWeChatPay({id: this.pid, re: window.location.host}).then(r => {
        if (r.code === 200) {
          if (typeof window.WeixinJSBridge === 'undefined') {
            window.WeixinJSBridge = {};
          }
          let that = this;
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(r.data.pay_config),
              function (res) {
                console.log(res);
                // TODO: 检查微信支付结果
                that.bondWeChatPayQuery(r.data.order.order_sn);
              }
          );
        }
      });
    },

    /*
    * 检查微信支付结果
    * */
    bondWeChatPayQuery: function (orderSn) {
      this.$api.weChatBondQuery({order_sn: orderSn}).then(res => {
        if (res.code === 200) {
          Toast('保证金支付成功');
        } else {
          if (res.code === 500) {
            Toast('支付失败');
          } else {
            setTimeout(() => {
              this.bondWeChatPayQuery(orderSn);
            }, 200);
          }
        }
      });
    },

    /*
    * 显示保证金支付方式选择
    * */
    bondPayMethodChooser: function () {
      this.bzjshow = false;
      this.showBondPayMethod = true;
    },

    onClickLeft() {
        // this.$router.back();
    window.history.length > 1 ?  this.$router.back() : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    show_img(k) {
      ImagePreview({
        images: this.good_info.images,
        startPosition: k,
      });
    },
    empty() {
      Toast('敬请期待');
    },
    //查看
    cat_zp() {
      this.zpshow = true;
    },
    //看出价记录
    showchujia() {
      this.chujiashow = true;
    },
    //获取领先价
    getLeader() {
      this.$api.getLeader(this.pid).then(r => {
        if (r.code == 200) {
          this.now_price = r.data.Leader.price;
        }
      })
    },
    //关闭出价
    gb_chujia() {
      this.push_show = false;
    },
    /*
     * 加载拍卖详情
     * */
    getAuctionDetail: function () {
      let that = this;
      this.$api.Auctiondata(this.pid).then(function (data) {
        // console.log(data);
        if (data.code == 200) {
          data.data.goods_content = data.data.goods_content.unescape();
          data.data.goods_remark = data.data.goods_remark.unescape();
          that.good_info = data.data;
          that.auctionList = data.data.auction_list;
          switch (data.data.status) {
            case 0:
              that.good_info.status_cn = '未开始';
              break;
            case 1:
              that.good_info.status_cn = '拍卖中';
              break;
            case 2:
              that.good_info.status_cn = '已结束';
              break;
          }
          // 结束时间提示
          that.good_info.end_time = (data.data.end_timestamp * 1000) - Date.parse(new Date());
          if (data.data.auction_list.length > 0) {
            that.now_jinzhu = data.data.auction_list[0];
          }
          
          if(isWx()){
              wxShare(window.location.href,data.data.goods_name,data.data.description,data.data.original_img);
          }

        }
      })
    },
    pay() {
      //    检查保证金
      this.$api.checkBond(this.pid).then(r => {
        Toast(r.msg);
        if (r.code != 200) {
          return;
        }

        if (r.msg == '活动不存在或已结束') {
          return;
        }

        if (r.data.status != 1) {
          this.bzjshow = true;
          return;
        }
        this.push_show = true;
      })

    },
    //出价按钮
    chujia_pay(e) {
      let num = parseInt(e);
      this.push_show = false;
      if (num <= this.now_price) {
        Toast('金额不能小于当前出价');
        return;
      }

      if (this.mtx) {
        return;
      }

      this.mtx = true;

      this.$api.auction({auction_id: this.pid, price: num.toFixed(2)}).then(r => {
        if (r.msg == '出价成功') {
          window.location.reload();
        }
      });

    },
    //支付保证金
    bondPay() {
      this.$api.getPay({id: this.pid, re: window.location.host}).then(r => {
        if (r.code == 200) {
          document.getElementById('paydom').innerHTML = r.data.payForm;
          document.getElementById('alipaysubmit').submit();
        }
      })
    },
    /*
           * 初始化Ws链接
           * */
    WsInit: function () {
      this.WsServer = new WebSocket('ws://' + window.location.hostname + ':9501');
      this.WsServer.onmessage = this.WsReceive;
    },

    /*
    * Ws接收到消息
    * */
    WsReceive: function (event) {
      // console.log(JSON.parse(event.data));
      let data = JSON.parse(event.data);
      switch (data.type) {
        case 'auth':
          this.WsAuthProcess(data);
          break;
        case 'msg':
          this.WsMsgProcess(data);
          break;
      }
    },

    /*
    * 认证类型交互各个流程处理
    * */
    WsAuthProcess: function (data) {
      if (data.status === 'auth') {   // 认证阶段
        this.WsAuthKey = data.data.clientId;
        this.WsAuth();
      } else {
        if (data.code === 1) {      // 认证失败
          this.$notify(data.msg);
        } else {
          // TODO: 认证成功
          // setInterval(() => {    // 定时更新最新出价
          //     this.WsSendCommand({
          //         type: 'getLeader',
          //         auction_id: '{$auction_id}',
          //     });
          // }, 2000);
        }
        // TODO: 认证失败处理
      }
    },

    /*
    * 消息交互相关处理
    * */
    WsMsgProcess: function (data) {
      // console.log(data);
      if (typeof data.update !== 'undefined') {
        switch (data.update) {
          case 'auctionDetail':           // 更新拍卖领先价和出价列表
            if (data.data.auctionId === '{$auction_id}') {
              this.popupAuction.leader.price = data.data.price;
              this.auctionList = data.data.auctionList;
            }
            // console.log(data.data.auctionList);
            break;
        }
      } else {
        this.$notify(data.msg);
      }
    },

    /*
    * 发送查询指令
    * */
    WsSendCommand: function (command) {
      this.WsServer.send(JSON.stringify(command));
    },

    /*
    * 关闭Ws
    * */
    WsClose: function () {
      this.WsServer.close();
    },

    /*
    * Ws 认证, 请求后端服务进行认证
    * */
    WsAuth: function () {

      this.$api.WsAuth({authKey: this.WsAuthKey}).then(r => {
        if (r.code == 200) {       // 发送了认证申请, 请求 Ws 检查认证情况
          this.WsSendCommand({
            type: 'auth',
            authKey: this.WsAuthKey,
          });
        } else {
          // TODO: 认证失败处理
        }
      })
    },

    /*
    * 轮播切换回调事件
    * */
    swipeChange: function () {
      if (this.good_info.video) {
        let el = this.$refs.descVideo;
        if (!el.paused) {
          this.playVideo();
        }
      }
    },

    /*
    * 播放视频
    * */
    playVideo: function () {
      let el = this.$refs.descVideo;
      if (el.paused) {
        el.play();
      } else {
        el.pause();
      }
      this.isPlayVideo = !el.paused;
    },

  },
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
    [Tag.name]: Tag,
    [ActionSheet.name]: ActionSheet,
    [ActionBar.name]: ActionBar, [ActionBarIcon.name]: ActionBarIcon, [ActionBarButton.name]: ActionBarButton,
    [Popup.name]: Popup, Empty, [Tab.name]: Tab, [Tabs.name]: Tabs, [Overlay.name]: Overlay, Pmpay, Goods, Pj
  },
  computed: {}
}
</script>

<style scoped>

.auctionVideo {
  width: 100%;
  height: 365px;
  background-color: #000000;
  object-fit: contain;
}

.auctionVideoAction {
  width: 100%;
  height: 365px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}

.main {
  margin-bottom: 120px;
}

.xiangqing >>> img {
  width: 100%;
}

.xiangqing >>> video {
  width: 100%;
}

.my-swipe {
  width: 100%;
  height: 340px;
  position: relative;
  bottom: 1px;
  z-index: 10;
}

.p1_info {
  text-align: left;
  display: flex;
  font-size: 14px;
  color: #8d8b8b;
}

.p1_info p {
  padding-right: 10px;
}

.bar {
  padding: 10px;
  z-index: 999;
}

.ms_img {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

.p1 {
  margin: 10px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.p1_1 {
  justify-content: space-between;
}

.pj {
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 16px;
  color: black;
}

.pj.tiao {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.more {
  font-size: 14px;
  color: #8d8b8b;
  position: relative;
  top: 2px;
}

#pj_right {
  position: relative;
  top: 2px
}

.money {
  color: #d2211b;
  font-weight: bold
}

.money span {
  color: #4e4949;
  font-weight: lighter;
  font-size: 16px;
}

.kucun {
  color: #8d8b8b;
  font-size: 14px;
  position: relative;
  top: 10px
}

.title {
  justify-content: start;
  padding-top: 5px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-align: left;
}

.avatar {
  width: 40px;
  border-radius: 99px;
}

.nickname {
  color: #8d8b8b;
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
  font-weight: bold
}

.pj_1 {
  justify-content: space-between;
}

.createtime {
  color: #8d8b8b;
  font-size: 16px;
}

.descx {
  justify-content: start;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
  padding-top: 5px;
}

.descx img {
  width: 80px;
  padding: 10px;
}

.desc_text {
  padding-top: 10px;
}

.dd {
  margin-bottom: 10px;
}

.title_zp {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.canshu_zp {
  justify-content: space-between;
  padding: 10px 20px;
  flex-wrap: wrap;
  display: flex;
}

.line_zp {
  padding-bottom: 20px;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.canshu_cat {
  font-size: 14px;
  padding: 5px;
  position: absolute;
  right: 13px;
  bottom: 5px;
  border-radius: 8px;
  background: #be3d47;
  color: white;
}

.newjia {
  height: 53px;
  text-align: left;
  line-height: 30px;
  position: relative;
  z-index: 88;
  bottom: 1px;
  background: #d76a31;
}

.newjia .left {
  background: #AC3C35;
  color: white;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 5px;
  width: 265px;
  border-top-right-radius: 6px;
  /*padding-bottom: 5px;*/
}

.newjia .right {
  background: #D76A31;
  font-size: 16px;
  width: 180px;
  color: white;
  padding-left: 10px;
  border-top-left-radius: 6px;
}

.price_now {
  font-size: 22px;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}

.status {
  background: white;
  color: #AC3C35;
  font-size: 12px;
  padding: 0px 3px;
  position: relative;
  bottom: 5px;
  left: 3px;
}

.time {
  font-weight: bold;
  position: relative;
  bottom: 8px;
}

.qi {
  font-size: 14px;
}

.title_icon {
}

.title_lan {
  color: #8d8b8b;
  font-size: 14px;
  padding-top: 8px;
  justify-content: space-between;
  width: 290px;
}

.pai_price {
  color: #d2211b;
  font-weight: bold;
}

.kuang {
  padding: 10px;
}

.title_zp.flex p {
  font-size: 16px;
  padding-right: 10px;
}

.xuankuang {
  justify-content: space-around;
  background: black;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 300px;
  height: 130px;
  background-color: #fff;
  border-radius: 12px;
  margin: auto;
}

.tips_bz {
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}

.tips_bz_gz {
  color: #d2211b;
  font-size: 14px;
  padding-top: 10px;
}

.jiaonai {
  position: relative;
  top: 20px;
  width: 100%;
  padding: 9px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #d2211b;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.van-count-down {
  color: white;
  padding-top: 2px;
  font-size: 14Px;
  z-index: 999;
}

.createtime_time {
  font-size: 12px;
}

.goods_list {
  margin: 0px 5px;
  text-align: center;
}

.wujia {
  font-size: 14px;

}
</style>
