<template>
  <div class="bg">
    <van-nav-bar
        title="添加评价"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />
<!--    <template  v-for="(v,k) in order" :key="k" :ref="'cG'+k">-->
      <div class="card_my">
        <div class="qname" >
          评价商品
        </div>
        <van-swipe-cell style="position: relative;" >
          <van-card
              class="goods-card"
              :thumb="d.no_comment_goods.original_img"
          >
            <template #title>
              <p class="title">{{d.no_comment_goods.goods_name}}</p>
            </template>
            <template #price>
              <p class="price_s">¥ {{d.no_comment_goods.goods_price}}</p>
            </template>
            <template #footer>
              X 1
            </template>
          </van-card>
          <template #right>
<!--            <van-button square style="height: 100%" text="删除" type="danger" class="delete-button" />-->
          </template>
        </van-swipe-cell>
      </div>

    <div class="p1">
      <div class="flex rate">
        <p>商品满意度</p>
        <Rate :size="25"
              color="#ffd21e"
              void-icon="star"
              :count="5"
              v-model="rate.rank"
              void-color="#eee"></Rate>
      </div>
      <div class="flex rate">
        <p>卖家服务</p>
        <Rate :size="25"
              color="#ffd21e"
              void-icon="star"
              :count="5"
              v-model="rate.store_speed_hidden"
              void-color="#eee"></Rate>
      </div>
      <div class="flex rate">
        <p>描述相符</p>
        <Rate :size="25"
              color="#ffd21e"
              void-icon="star"
              :count="5"
              v-model="rate.store_packge_hidden"
              void-color="#eee"></Rate>
      </div>
      <div class="flex rate">
      <p>物流服务</p>
      <Rate :size="25"
            color="#ffd21e"
            void-icon="star"
            :count="5"
            v-model="rate.store_sever_hidden"
            void-color="#eee"></Rate>
    </div>

      <div class="p2">
        <p class="t1">评价内容</p>
        <textarea class="content" v-model="rate.content" maxlength="400"></textarea>
      </div>

<!--      <div class="p2">-->
<!--        <p class="t1">印象标签</p>-->
<!--        <textarea class="content"></textarea>-->
<!--      </div>-->

      <Button style="width: 50%" round type="success" @click="smp">提交</Button>


    </div>


    <!--    </template>-->

  </div>

  <div class="flex bottom" v-if="1==2" >
    <div class="p10">
      <div><Button class="dingdan quxiao" @click="pay(0)">取消订单</Button></div>
      <div><Button class="dingdan fukuai" @click="pay(1)">付款</Button></div>
    </div>
  </div>



</template>

<script>
import {NavBar,Toast,Button,Icon,SwipeCell,Card,Popup,CountDown,Steps,Step,Rate} from 'vant';
import {ref} from 'vue';
export default {
  name: "AddComment",
  setup() {
    const endtime = ref(2 * 60 * 60 * 1000);
    return { endtime };
  },
  data() {
    return {
      zpshow:false,
      rate:{
        store_speed_hidden:'0',
        rank:'0',
        store_packge_hidden:'0',
        store_sever_hidden:'0',
        content:'',
        // yinxiang:'',
      },
      ids:{},
      yunfei:8,
      payshow:false,
      id:0,
      list:{
        loading:false,
        finished:true,
      },
      d:{no_comment_goods:{}},
    }
  },
  created() {
    if(
        this.$route.query.order_id==undefined || this.$route.query.order_id<0 ||
        this.$route.query.store_id==undefined || this.$route.query.store_id<0 ||
        this.$route.query.goods_id==undefined || this.$route.query.goods_id<0
    ){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }

    this.ids = {
      order_id:this.$route.query.order_id,
      store_id:this.$route.query.store_id,
      goods_id:this.$route.query.goods_id,
    };

    this.$api.comment_list(this.ids).then(r=>{
      if(r.code==200){
        console.log(r.data);
        this.d = r.data;
      }
    })
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    payclose(){
      this.payshow=false;
    },
    smp(){
      var is_action =false;
      for(let k in this.rate){
        if(this.rate[k]==''){
          Toast('参数不能留空');
          is_action= true;
          return;
        }
      }
      if(is_action) return;
      this.$api.conmment_add({
        ...this.rate,
        ...this.ids,

      }).then(r=>{
        if(r.code==200){
          Toast('提交成功');
          this.$router.go(-1);
        }
      })

    },
    formatTimestamp(time = 0, fmt = 'yyyy-MM-dd hh:mm:ss') {
      if(time === 0 || !time) {
        return ''
      }
      time = time*1000;
      let date = new Date(time)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
      }
      return fmt;
    }
  },
  components: {
    [NavBar.name]:NavBar,Button,[Icon.name]:Icon,
    // [Checkbox.name]:Checkbox,
    [SwipeCell.name]:SwipeCell,[Card.name]:Card
    ,[Popup.name]:Popup,
    [CountDown.name]:CountDown,
    [Steps.name]:Steps,
    [Step.name]:Step,
    Rate
  },
  computed: {
    total(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.price*vs.num
      //   })
      // })
      return b;
    },
    num(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.num
      //   })
      // })
      return b;
    }
  }
}
</script>
<style scoped>
.rate{
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.p1{
  margin: 10px;
  background: white;
  border-radius: 6px;
  padding: 10px;
  position: relative;
}

.qname{
  font-size: 16px;
  display: flex;
  margin-bottom: 18px;
  line-height: 30px;
}
.qpic{width: 30px;border-radius: 50px;margin-right: 8px;}

.bg{
  background: #f9f9f9;
  margin-bottom: 140px;
}

.bottom{
  position: fixed;
  bottom: 0px;
  background: #F6F6F6;
  width: 100%;
}
.p10{
  display: flex;
  justify-content:flex-end;
  padding: 10px;
  width: 100%;
}

.dingdan{
  /*border-radius: 5px;*/
  color: white;
  font-weight: bold;
  padding-right: 30px;
  padding-left: 30px;
  width: 120px;
}

.quxiao{
  margin: 0;
  background: #f6f6f6;
  color: black;
  border: 1px solid #c1bdbd;
  /*left: 5px;*/
  border-bottom-left-radius:6px;
  border-top-left-radius:6px;
}
.fukuai{
  background:#AC3A35;
  border: 1px solid #AC3A35;
  border-bottom-right-radius:6px;
  border-top-right-radius:6px;

}

.red_tips{
  background: #AC3A35;
  padding: 15px;
  position: relative;
  bottom: 1px;
  z-index: 99;
  color: white;
  font-size: 18px;
  text-align: left;
}
.time_w{
  font-size: 18px;
  color: white;
  position: relative;
  top: 4px;
}

.address{
  text-align: left;
  font-size: 16px;
}

.name{
  color: #8d8b8b;
  margin-top: 8px;
  border-top: 1px solid #eee;
}
.name div{
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
}
.name img{
  width: 40px;
  height: 40px;
  border-radius: 85px;
}
.nickname{
  position: relative;
  bottom: 15px;
  left: 10px;
}
.role{
  position: relative;
  top: 10px;
}
.card_my{
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.title{
  font-size: 16px;
  text-align: left;
  width: 160px;
  padding-left: 20px;
}
.price_s{
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 50px;
  font-size: 20px;
  font-weight: bold;
}

.yunfei p{
  margin: 3px 0px;
}
.left{
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
}

.wuliu >>> .van-step__title{
  font-size: 12px ;
  /*text-align: left;*/
  /*padding-left: 20px;*/
}
.t1{
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.p2{
  margin: 10px 0px;
}
.content{
  font-size: 14px;
  background: #f5f5f5;
  width: 90%;
  margin-top: 10px;
  padding: 10px;
}
</style>
