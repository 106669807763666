<template>
  <div>
    <van-nav-bar
        title="消息"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
    </van-nav-bar>
  </div>
</template>

<script>
import {NavBar} from 'vant';
export default {
  name: "MessContent",
  data() {
    return {}
  },
  created() {
  },
  methods: {},
  components: {[NavBar.name]:NavBar},
  computed: {}
}
</script>

<style scoped>

</style>
