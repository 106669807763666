import Home from "@/views/Home";
import Login from "@/views/user/Login"
import Rest from "@/views/user/Rest"
import Ylogin from "@/views/user/Ylogin";
import Cart from "@/views/user/Cart";
import Message from "@/views/user/Message";
import MessContent from "@/views/user/MessContent";
import Index from "@/views/user/Index"
import Order from "@/views/marke/pay/Order";
import Bond from "@/views/user/Bond";
import Address from "@/views/user/address/Address";
import Edit from "@/views/user/address/Edit";
import Feedback from "@/views/user/Feedback";
import Kaidian from "@/views/user/Kaidian";
import Follow from "@/views/user/Follow";
import Coupon from "@/views/user/Coupon";
import Integral from "@/views/user/unique/Integral";
import Exchange from "@/views/user/unique/Exchange";
import Pointsrecord from "@/views/user/unique/Pointsrecord";
import Mypm from "@/views/user/Mypm.vue";
import Vip from "@/views/user/unique/Vip";
import InfoEdit from "@/views/user/InfoEdit";
import Dh from "@/views/user/Dh";
import Myteam from "@/views/marke/pay/Myteam";
import Evaluate from "@/views/user/Evaluate";
import addComment from "@/views/marke/pay/addComment";
import Kaidiantwo from "@/views/user/Kaidiantwo";
import KdInfo from "@/views/user/KdInfo";
import weChatLogin from "@/views/user/weChatLogin";
export default [
    {
        path: '/test',
        name: 'test',
        component: Home,
        meta:{
            title:'11111'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta:{
            title:'登录'
        }
    },
    {
        path: '/we_chat_login',
        name: 'WeChatLogin',
        component: weChatLogin,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/rest',
        name: 'Rest',
        component: Rest,
        meta:{
            title:'重置密码'
        }
    },
    {
        path: '/ylogin',
        name: 'Ylogin',
        component: Ylogin,
        meta:{
            title:'登录'
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta:{
            title:'购物车'
        }
    },
    {
        path: '/message',
        name: 'Mess',
        component: Message,
        meta:{
            title:'消息'
        }
    },
    {
        path: '/messcontent',
        name: 'Messcontent',
        component: MessContent,
        meta:{
            title:'消息内容'
        }
    },
    {
        path: '/user',
        name: 'User',
        component: Index,
        meta:{
            title:'会员中心',
            auth:true
        }
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
        meta:{
            title:'我的订单',
            auth:true
        }
    },
    {
        path: '/infoedit',
        name: 'Infoedit',
        component: InfoEdit,
        meta:{
            title:'信息修改',
            auth:true
        }
    },
    {
        path: '/bond',
        name: 'Bond',
        component: Bond,
        meta:{
            title:'我的保证金'
        }
    },
    {
        path: '/address',
        name: 'Address',
        component: Address,
        meta:{
            title:'收货地址',
            auth:true
        }
    },
    {
        path: '/address/edit',
        name: 'Edit',
        component: Edit,
        meta:{
            title:'编辑地址',
            auth:true
        }
    },
    {
        path: '/back',
        name: 'back',
        component: Feedback,
        meta:{
            title:'意见反馈',
            auth:true
        }
    },
    {
        path: '/kaidian',
        name: 'Kd',
        component: Kaidian,
        meta:{
            title:'开店申请',
            auth:true
        }
    },
    {
        path: '/gskd',
        name: 'Gskd',
        component: Kaidiantwo,
        meta:{
            title:'公司开店申请',
            auth:true
        }
    },
    {
        path: '/kdinfo',
        name: 'Kdinfo',
        component: KdInfo,
        meta:{
            title:'店铺状态',
            auth:true
        }
    },
    {
        path: '/follow',
        name: 'Follow',
        component: Follow,
        meta:{
            title:'关注列表'
        }
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: Coupon,
        meta:{
            title:'我的优惠卷',
            auth:true
        }
    },
    {
        path: '/integral',
        name: 'Integral',
        component: Integral,
        meta:{
            title:'积分中心'
        }
    },
    {
        path: '/exchange',
        name: 'Exchange',
        component: Exchange,
        meta:{
            title:'积分兑换'
        }
    },
    {
        path: '/pr',
        name: 'Pr',
        component: Pointsrecord,
        meta:{
            title:'积分记录'
        }
    },
    {
        path: '/mypm',
        name: 'Mypm',
        component: Mypm,
        meta:{
            title:'我的拍卖',
            auth:true
        }
    },
    {
        path: '/dh',
        name: 'Dh',
        component: Dh,
        meta:{
            title:'我的搭伙',
            auth:true
        }
    },
    {
        path: '/myteam',
        name: 'Myteam',
        component: Myteam,
        meta:{
            title:'我的队伍',
            auth:true
        }
    },
    {
        path: '/vip',
        name: 'Vip',
        component: Vip,
        meta:{
            title:'会员中心',
            auth:true
        }
    },
    {
        path: '/evaluatelist',
        name: 'Evaluatelist',
        component: Evaluate,
        meta:{
            title:'我的评价',
            auth:true
        }
    },
    {
        path: '/addem',
        name: 'Addem',
        component: addComment,
        meta:{
            title:'添加评价',
            auth:true
        }
    },
]