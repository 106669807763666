<template>
  <div>
    <van-nav-bar
        title="我的保证金"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
        <span style="color: white;">规则说明</span>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import {NavBar,Icon} from 'vant'
export default {
    name: "Bond",
    data(){
        return {
        
        }
    },
    created() {
    },
    methods:{
    
    },
    components:{
      [NavBar.name]:NavBar,[Icon.name]:Icon
    },
    computed:{
    
    }
}
</script>

<style scoped>

</style>
