import Crowd from "@/views/marke/Crowd";
import CrowdGood from "@/views/marke/CrowdGood";
import Type from "@/views/marke/Type";
import Product from "../views/marke/Product";
import Good from "@/views/marke/Good";
import Factions from "@/views/marke/Factions";
import Pingjia from "@/views/marke/Pingjia";
import Checkin from "@/views/marke/pay/Checkin";
import Orderinfo from "@/views/marke/pay/Orderinfo";
import Auction from "@/views/marke/Auction";
import Auctionrules from "@/views/marke/Auctionrules";
import Typelist from "@/views/marke/Typelist";
import Huoping from "@/views/marke/Huoping";

export default [
    {
        path: '/crowd',
        name: 'Crowd',
        component: Crowd,
        meta:{
            title:'众筹'
        }
    },
    {
        path: '/crowdgood',
        name: 'CrowdGood',
        component: CrowdGood,
        meta:{
            title:'众筹商品'
        }
    },
    {
        path: '/type',
        name: 'Type',
        component: Type,
        meta:{
            title:'分类'
        }
    },
    {
        path: '/typelist',
        name: 'Typelist',
        component: Typelist,
        meta:{
            title:'分类列表'
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: Product,
        meta:{
            title:'商品列表'
        },
        children:[

        ]
    },
    {
        path: '/product/good',
        name: 'Good',
        component: Good,
        meta:{
            title:'商品'
        }
    },
    {
        path: '/factions',
        name: 'Factions',
        component: Factions,
        meta:{
            title:'伙拼商品'
        }
    },
    {
        path: '/huoping',
        name: 'Huoping',
        component: Huoping,
        meta:{
            title:'伙拼'
        }
    },
    {
        path: '/evaluate',
        name: 'Pingjia',
        component: Pingjia,
        meta:{
            title:'评论列表'
        }
    },
    {
        path: '/checkin',
        name: 'Checkin',
        component: Checkin,
        meta:{
            title:'确认订单',
            auth:true,
        }
    },
    {
        path: '/orderinfo',
        name: 'Orderinfo',
        component: Orderinfo,
        meta:{
            title:'订单详情',
            auth:true,
        }
    },
    {
        path: '/auction',
        name: 'Auction',
        component: Auction,
        meta:{
            title:'拍卖商品'
        }
    },
    {
        path: '/auctionrules',
        name: 'Auctionrules',
        component: Auctionrules,
        meta:{
            title:'拍卖规则'
        }
    },
]