import api from '@/api';
import wx from 'weixin-js-sdk';

export function isWx(){
    let isWechat = navigator.userAgent.indexOf('MicroMessenger') > -1
        if(!isWechat) {
                  return false;
            }
    return true;
}

export function wxShare(localUrl='',title='',desc='',imgUrl='',link=''){
    //对url 进行编码
    // var localUrl = encodeURIComponent(location.href.split('#')[0]);//
    // //url传到后台的格式
    // var url = 'URL=' + localUrl;
    // //这几个参数都是后台从微信公众平台获取到的
    // var nonceStr,signature,timestamp,appId,shareUrl;
    //+ this.$route.query.viewid
    let isWechat = navigator.userAgent.indexOf('MicroMessenger') > -1;
    if(!isWechat) {
              return false;
        }
    if(link===''){
        link = localUrl;
    }
    var currentUrl = location.href;
       api.wxshare(localUrl).then(response => {
           // console.log(response)
         const {appId, nonceStr, signature, timestamp} = response.data
         //经过微信config接口注入配置
         wx.config({
           debug: false, //默认为false 为true的时候是调试模式，会打印出日志
           appId: appId,
           timestamp: timestamp,
           nonceStr: nonceStr,
           signature: signature,
           jsApiList: [
             'checkJsApi',
             'onMenuShareTimeline',
             'onMenuShareAppMessage',
             'onMenuShareQQ',
             'onMenuShareQZone',
             'updateAppMessageShareData',
             'updateTimelineShareData',
           ],
         });
           
         //配置自定义分享内容
         window.share_config = {
           share: {
             title:  title, // 这是分享展现卡片的标题
             desc: desc, // 这是分享展现的摘要
             link: link, // 这里是分享的网址
             imgUrl: imgUrl, // 这里是须要展现的图标
             success: function (rr) {
            ` 分享成功回调 能够在这儿加 alert 打印 便于调试`
               console.log('成功' + JSON.stringify(rr))
             },
             cancel: function (tt) {
             ` 分享失败回调 能够在这儿加 alert 打印 便于调试`
               console.log('失败' + JSON.stringify(tt));
             }
           }
         };
           
         // config
         wx.ready(function () {
           wx.onMenuShareAppMessage(window.share_config.share); // 微信好友
           wx.onMenuShareTimeline(window.share_config.share); // 微信朋友圈
           wx.onMenuShareQQ(window.share_config.share); // QQ
           wx.updateAppMessageShareData(window.share_config.share);
             wx.updateTimelineShareData(window.share_config.share);
         });
         wx.error(function (res) {
            // console.log(res)
         });
       });
}

