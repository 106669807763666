<template>
  <div class="bg">
    <!--    tabs-->

    <van-tabs class="pm_nv" v-model="activeName" animated background="#000"  color="#fff" title-active-color="#8f2526"
              title-inactive-color="#fff" style="width: 100%;z-index: 999;" >
      <van-tab title-class="font18 bold" title="推荐" name="index">
        <!--    轮播-->
        <Swipe :autoplay="3000" class="lb">
          <SwipeItem v-for="(image, index) in images" :key="index">
            <img :src="image" />
          </SwipeItem>
        </Swipe>

        <!--    四個-->
        <div class="fours">
          <div class="xr" v-for="(v,k) in fours" :key="k" >
            <div class="red" :style="{background: v.color}">
              {{v.name}}
            </div>
            <div class="shenlin">身临其境拍卖现场</div>
            <Grid :gutter="10" :border="false">
              <GridItem v-for="value in 2" :key="value" >
                <template v-slot:icon>
                  <img src="@/assets/img/tu2.png" class="xr_img">
                </template>
                <template v-slot:text>
                  <div class="jiage">
                    <span style="font-size: 18px;font-weight: bold;">￥1</span>
                  </div>
                </template>
              </GridItem>
            </Grid>
          </div>
        </div>


        <!--    选框-->
        <div class="flex xuankuang">
          <div :class="tuijian==1?'select':''"><p class="one">全部</p></div>
          <div @click="empty" :class="tuijian==2?'select':''"><p class="one">鬼市</p></div>
          <div @click="empty" :class="tuijian==3?'select':''"><p class="one">建盏</p></div>
          <div @click="empty" :class="tuijian==4?'select':''"><p class="one">孤品</p></div>
        </div>

        <!--    商品列表-->
          <List  :loading="list.loading"
                 :finished="list.finished"
                 finished-text="没有更多了"
                 error-text="请求失败，点击重新加载"
                 @load="onLoad"
                 class="flex"
                 style="flex-wrap:wrap;justify-content: center;"

          >
          <div class="xxxxlist" v-for="(v,k) in goods_data" :key="k">
            <Goods :="v"></Goods>
          </div>
          </List>


      </van-tab>
      <van-tab title-class="font18 bold" title="专属" name="b" >
        <Empty description="敬请期待" />
      </van-tab>
      <template #nav-left	>
        <Icon name="arrow-left" @click="onClickLeft" class="pm_icon" color="#fff" size="24" />
      </template>
      <template #nav-right	>
        <Icon name="search"  class="pm_icon" color="#fff" size="24" />
      </template>
    </van-tabs>



    <Bottom></Bottom>

  </div>
</template>

<script>
import { Toast,Icon,Tab,Tabs,Empty,Swipe,SwipeItem,Grid,GridItem,List } from 'vant';
import Bottom from "@/components/Bottom";
import Goods from "@/components/Goods";
export default {
  name: "Pm",
  data(){
    return {
      activeName:'index',
      tuijian:1,
      page:1,
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      goods_data:[
      ],
      fours:[
        {
          name:'直播拍卖',color:'#d2211b',
          list:[],
        },
        {
          name:'专场拍卖',color:'#358EBA',
          list:[],
        },
        {
          name:'直播拍卖',color:'#E99562',
          list:[],
        },
        {
          name:'专场拍卖',color:'#2E9275',
          list:[],
        }
      ],
      list:{
        loading:false,
        finished:false,
        page:1,
      }
    }
  },
  created() {
    this.onLoad();
  },
  methods:{
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    empty(){
     Toast('敬请期待');
    },
    onLoad(){
      if(this.list.loading || this.list.finished) return;
      this.list.loading=true;
      this.$api.PmIndexJson(this.page).then(r=>{
        if(r.code != 200){
          Toast(r.msg);
          return;
          // this.onClickLeft();
        }
        if(r.data.list.length<=0){
          this.list.finished=true;
          this.list.loading=true;
        }
        if(this.list.page==1){
          this.goods_data = r.data.list;
        }else{
          this.goods_data = this.goods_data.concat(r.data.list);
        }
        this.list.page+=1;
        this.page+=1;
        this.list.loading=false;
      })
    }
  },
  components:{
    Icon,[Tabs.name]:Tabs,[Tab.name]:Tab,Empty,Bottom,Swipe,SwipeItem
    ,Grid,GridItem,Goods,List
  },
  computed:{

  }
}
</script>
<style>
@import "../../style/header.css";
.font18{
  font-size: 20PX;
  padding: 10px;
}
.pm_nv .van-tabs__wrap{
  height: 60px!important;
}
</style>
<style scoped>
.bg{
  background: #f9f9f9;
  margin-bottom: 140px;
}
.pm_icon{
  position: relative;
  top: 18px;
  padding: 0px 10px;
}
.lb{
  height: 150px;
  margin: 10px;
  border-radius: 15px;
}
.lb img{
  /*height: 150px;*/
  width: 100%;
  object-fit:contain;
}
.shenlin{
  font-size: 16px;
  padding-top: 10px;
  color: #8d8b8b;
}
.fours{
  display: flex;
  flex-wrap: wrap;
}
.xr{
  margin-top: 10px;
  border-radius: 15px;
  background: #fff;
  /*padding: 0px 10px;*/
  margin: 10px 10px 0px 10px;
  width: 44%;
  justify-content: center;
}
.red{
  display: flex;
  font-size: 18px;
  padding: 10px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #d2211b;
  color: white;
  font-weight: bold;
  justify-content: center;
}
.xuankuang{
  justify-content: space-around;
  background: black;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
}
.xuankuang .one{font-size: 16px;}
.xuankuang .two{font-size: 12px;}
.xuankuang div{width: 49%;}
.xuankuang div{border-right: 1px solid #8d8b8b;}
.xuankuang div:last-child{border-right: 0;}

.select{color: #e00707;}

.xr_img{
  width: 50px;
}
.goods_list{
  display: flex;
}
.xxxxlist{
  /*margin-left: 5px;*/
}
</style>
