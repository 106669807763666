<template>
  <div class="main">

    <!--  顶部导航  -->
    <van-nav-bar
        title="下载中心"
        left-text=""
        left-arrow
        @click-left="back"
        style="background: black">
    </van-nav-bar>

    <!--  内容列表  -->
    <div class="contentList">
      <div v-for="item in lists" class="content" :key="item.id" @click="downloadFile(item.file, item.isAuth)">
          <!--     内容名称     -->
          <div class="contentName">
            {{ item.name }}
          </div>

          <!--    内容简介      -->
          <div class="contentDescription">
            {{ item.description }}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {NavBar, Toast} from "vant";
import config from '../../../config.base';
import {Scroll} from "@/util/scroll";

export default {
  name: "Index",
  components: {
    [NavBar.name]: NavBar,
  },
  data: () => {
    return {

      mutex: false,

      currentPage: 0,

      lists: [],

    };
  },
  created() {
    this.getLists();
  },
  mounted() {
    new Scroll().setReachBottomDistance(20).setEmitRate(500).onReachBottom(() => {
      this.getLists();
    });
  },
  beforeUnmount() {
    window.onscroll = () => {};
  },
  methods: {

    /*
    * 返回上一页
    * */
    back: function () {
      this.$router.back();
    },

    /*
    * 获取附件列表
    * */
    getLists: function () {
      if (this.mutex) {
        Toast('操作过于频繁');
        return;
      }
      this.mutex = true;
      let page = this.currentPage;
      this.$api.attachmentList({page: ++page}).then(res => {
        if (res.code === 200) {
          if (res.data.length === 0) --page;
          this.currentPage = page;
          let data = res.data;
          for (let i = 0; i < data.length; i++) {
            data[i].file = config.baseURL + data[i].file;
          }
          this.lists = this.lists.concat(data);
        }
        this.mutex = false;
      }).catch(() => {
        this.mutex = false;
      });
    },

    /*
    * 下载文件
    * */
    downloadFile: function (file, isAuth) {
      if (!isAuth){
        Toast('无下载权限');
        return;
      }
      if (file){
        window.open(file);
      }else{
        Toast('暂无下载链接');
      }
    },

  },
}
</script>

<style scoped>
.contentList{
  width: 100%;
}
.content{
  width: calc(100% - 40px);
  margin: 10px auto;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

.contentName{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentDescription {
  font-size: 14px;
  text-align: left;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 2em;
}
</style>