<template>
  <div class="bg">
    <nav-bar
        title="研究院"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
        <Icon name="search" size="18" />
      </template>
    </nav-bar>
<!--    tabs-->
      <van-tabs v-model="activeName" animated background="#000"  color="#fff" title-active-color="#8f2526"
                title-inactive-color="#fff" style="width: 100%;z-index: 999;top: -1px;" >
        <van-tab title-class="font14 bold" title="实践基地" name="index">
        </van-tab>
        <van-tab title-class="font14 bold" title="新闻中心" name="b" ><Empty description="敬请期待" />
        </van-tab>
        <van-tab title-class="font14 bold" title="党群工作" name="c"   ><Empty description="敬请期待" /></van-tab>
        <van-tab title-class="font14 bold" title="情报研究" name="d"   ><Empty description="敬请期待" /></van-tab>、
      </van-tabs>
<!--    <div>1111</div>-->
  </div>
</template>

<script>
import { NavBar,Toast,Icon,Tab,Tabs } from 'vant';

export default {
    name: "Research",
    data(){
      return {
        activeName:'index',
      }
    },
    created() {
    },
    methods:{
      onClickLeft(){
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
      },
      onClickRight() {
        Toast('按钮');
      },
    },
    components:{
      NavBar,Icon,[Tabs.name]:Tabs,[Tab.name]:Tab
    },
    computed:{
    
    }
}
</script>
<style>
@import "../style/header.css";

</style>
<style scoped>
.bg{
  background: #f9f9f9;
}

</style>
