<template>
  <div class="bg">
    <nav-bar
        title="商品评价"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
        <Icon name="share" size="18" style="color: white;" />
      </template>
    </nav-bar>
    <!--    tabs-->
    <van-tabs v-model="activeName" animated background="#000"  color="#fff" title-active-color="#8f2526"
              title-inactive-color="#fff" style="width: 100%;z-index: 999;top: -1px;" >
      <van-tab title-class="bold title_tab" title="本作品购买评价" name="index">

        <List  :loading="list.loading"
               :finished="list.finished"
               finished-text="没有更多了"
               error-text="请求失败，点击重新加载"
               @load="onLoad"
        >

          <div class="p1 dd" >
            <template v-for="(va,ks) in pl" :key="ks">
              <div class="flex pj_1" >
                <div class=" flex">
                  <p ><img class="avatar" v-lazy="va.avatar?va.head:'/avatar.png'"></p>
                  <div class="nickname">
                    {{va.nickname}}
                    <p class="star"><Rate v-model="va.goods_rank" size="12" readonly></Rate></p>
                  </div>

                </div>
                <div class="createtime">{{va.add_time}}</div>
              </div>
              <div class="descx">
                <!--          <template v-for="(p,pk) in va.pics" :key="pk">-->
                <!--            <img v-lazy="p">-->
                <!--          </template>-->
                <div class="desc_text">{{va.content}}</div>
              </div>
            </template>
          </div>

        </List>
      </van-tab>
<!--      <van-tab title-class="font14 bold" title="新闻中心" name="b" ><Empty description="敬请期待" />-->
<!--      </van-tab>-->
    </van-tabs>
    <!--    <div>1111</div>-->
  </div>
</template>

<script>
import { NavBar,Toast,Icon,Tab,Tabs,List,Rate } from 'vant';

export default {
  name: "Pingjia",
  data(){
    return {
      activeName:'index',
      pl:[
        {
          avatar:'',
          star:5,
          nickname:'小天',
          pics:[
            'https://img01.yzcdn.cn/vant/apple-2.jpg',
            'https://img01.yzcdn.cn/vant/apple-2.jpg',
            'https://img01.yzcdn.cn/vant/apple-2.jpg'
          ],
          desc:'vergoods',
          craete_time:'xxx-xx-xx',
          head:'',
        },
      ],
      list:{
        loading:false,
        finished:false,
        page:1,
      }
    }
  },
  created() {
    if(this.$route.query.id==undefined || this.$route.query.id==''){
      this.onClickLeft();
    }
    this.onLoad();
  },
  methods:{
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    onLoad(){
      if(this.list.loading || this.list.finished) return;
      this.list.loading=true;
      this.$api.get_pj(this.$route.query.id,this.list.page).then(r=>{
        if(r.code != 200){
          Toast(r.msg);
          return;
          // this.onClickLeft();
        }
        if(r.data.pl.length<=0){
          this.list.finished=true;
          this.list.loading=true;
        }
        if(this.list.page==1){
          this.pl = r.data.pl;
        }else{
          this.pl = this.pl.concat(r.data.pl);
        }
        this.list.page+=1;
        this.list.loading=false;
      })
    }
  },
  components:{
    NavBar,Icon,[Tabs.name]:Tabs,[Tab.name]:Tab,List,Rate
  },
  computed:{

  }
}
</script>
<style>
@import "../../style/header.css";
.title_tab{
  font-size: 16px;
}
</style>
<style scoped>
.bg{
  background: #f9f9f9;
}

.p1{
  margin: 10px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.p1_1{
  justify-content: space-between;
}
.pj{
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 16px;
  color: black;
}
.more{
  font-size: 14px;
  color: #8d8b8b;
  position: relative;
  top: 2px;
}

#pj_right{position: relative;top: 2px}
.money{color: #d2211b;font-weight: bold}
.money span{color: #4e4949;font-weight: lighter;font-size: 16px;}
.kucun{color: #8d8b8b;font-size: 14px;position: relative;top: 10px}
.title{justify-content: start;padding-top: 5px;font-size: 16px;font-weight: bold;color: black;}
.avatar{width: 40px;border-radius: 99px;}
.nickname{color: #8d8b8b;font-size: 14px;padding-left: 10px;text-align: left;}
.pj_1{justify-content: space-between;}
.createtime{color: #8d8b8b;font-size: 14px;position: relative;top:14px;}
.descx{justify-content: start;text-align: left;padding-left: 50px;font-size: 16px;padding-top: 5px;margin-bottom: 10px;}
.descx img{width: 80px;padding: 10px;}
.desc_text{padding-top: 0px;position: relative;bottom: 10px;font-size: 14px;}
.dd{margin-bottom: 70px;}
.title_zp{padding: 10px;border-bottom: 1px solid #eee;}
</style>
