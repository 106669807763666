<template>
  <div>
    <van-nav-bar
        title="收货地址"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <AddressList
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="onSelect"
        :switchable="select"
        :add-button-text="select?'选择地址':'新增地址'"
    />
<!--    :disabled-list="disabledList"-->
<!--    disabled-text="以下地址超出配送范围"-->
  </div>
</template>

<script>
import {NavBar,Icon,AddressList,Toast} from 'vant'
import {ref} from 'vue'
export default {
  name: "Address",
  setup() {
    const chosenAddressId = ref('1');
    const disabledList = [
      {
        id: '3',
        name: '王五',
        tel: '1320000000',
        address: '浙江省杭州市滨江区江南大道 15 号',
      },
    ];
    return {
      disabledList,
      chosenAddressId,
    };
  },
  data(){
    return {
      list:[
        // {
        //   id: '1',
        //   name: '张三',
        //   tel: '13000000000',
        //   address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
        //   isDefault: true,
        // },
      ],
      select:false,
      selectId:0,
    }
  },
  created() {
    this.$api.address_list().then(r=>{
      this.list = r.data.address;
      if(this.list.length<=0){
        this.$router.push('/address/edit')
      }

      // this.selectId

    })

    if(this.$route.query.id!=undefined){
      this.select=true;
    }

  },
  methods:{
    onAdd(){
      if(this.select){
        if(this.selectId<=0){
          Toast('请先选择地址');
          return;
        }
        let d= {
          id:this.$route.query.id,
          s_id:this.selectId,
        }
        if(this.$route.query.level_id){
          d.level_id=this.$route.query.level_id;
        }
        this.$router.push({
          path:'checkin',
          query:d
        })
      }else {
        Toast('新增地址');
        this.$router.push('/address/edit')
      }
    },
    onEdit(e){
      Toast('修改地址');
      this.$router.push('/address/edit?e=1&id='+e.id)
    },
    onSelect(e){
      // console.log(e);
      this.selectId = e.address_id;
    },
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  components:{
    [NavBar.name]:NavBar,[Icon.name]:Icon,
    AddressList
  },
  computed:{

  }
}
</script>

<style scoped>

</style>
