<template>
  <div class="main">
    <nav-bar
        title="我的优惠卷"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right>
        <Icon style="color: white;" name="" size="18" />
       <span style="color: white;">助力卷</span>
      </template>

    </nav-bar>

    <van-tabs class="www" v-model="activeName" animated background="#000" color="#fff"
              title-active-color="#8f2526"
              title-inactive-color="#fff">
      <van-tab title="未使用" name="index">

        <div class="block">
            <div class="flex p1">
              <div class="num flex">
                <span class="bi">¥</span>
                <span class="price">8</span>
              </div>
              <div class="remak">
                <p>优惠卷</p>
                <p>2019.1.1-2222.1.1</p>
              </div>
            </div>
            <div class="status">未使用</div>
            <div class="gang"></div>
          <div class="desc">说明</div>
        </div>



      </van-tab>
      <van-tab title="已使用" name="b" >

        <div class="block gray">
          <div class="flex p1 ">
            <div class="num flex">
              <span class="bi gray">¥</span>
              <span class="price gray">8</span>
            </div>
            <div class="remak">
              <p>优惠卷</p>
              <p>2019.1.1-2222.1.1</p>
            </div>
          </div>
          <div class="status red">已使用</div>
          <div class="gang gang_g"></div>
          <div class="desc">说明</div>
        </div>

      </van-tab>
      <van-tab title="已过期" name="b" >
        <div class="block gray">
          <div class="flex p1 ">
            <div class="num flex">
              <span class="bi gray">¥</span>
              <span class="price gray">8</span>
            </div>
            <div class="remak">
              <p>优惠卷</p>
              <p>2019.1.1-2222.1.1</p>
            </div>
          </div>
          <div class="status gray">已过期</div>
          <div class="gang gang_g"></div>
          <div class="desc">说明</div>
        </div>

        <Empty description="暂时没有哦"/>
      </van-tab>
    </van-tabs>



  </div>


</template>

<script>
import {Tab, Tabs, NavBar, Empty,Icon,Button} from 'vant';



export default {
  name: "Coupon",
  setup() {

  },
  data() {
    return {
      sertxt: '',
      activeName: 'index',
      list:[

      ]
    }
  },
  created() {
  },
  methods() {

  },
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Empty,
    NavBar,Icon,[Button.name]:Button,
  },
  computed: {}

}
</script>
<style>

.login_tips img {
  width: 120px;
  position: relative;
  top: 5px
}

.login_tips button {
  margin-right: 40px;
  padding: 5px 20px;
  font-size: 14px;
  background: #AC3A35;
  color: white;
  font-weight: bold;
}




</style>
<style scoped>
.main {
  width: 100%;
  /*position: fixed;*/
}
.www{
  position: relative;
  bottom: 1px;
  z-index: 999;
}

.status{
  color: #8d8b8b;
  font-size: 14px;
  padding-top:12px;
  padding-right: 20px;
}
.block{
  position: relative;
}
.p1{
  background: white;
  margin: 10px 20px;
  padding: 20px 10px;
  border-radius: 5px;
  font-size: 16px;
  text-align: left;
}
.num{
  width: 34%;
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #d2211b;
}
.bi{
  position: relative;
  top: 10px;
  left: 5px;
}
.price{
  font-size: 32px;
}
.gray{
  color: #8d8b8b !important;
}
.red{
  color: #AC3A35;
}
.status{
  position: absolute;
  right: 10px;
  bottom: 97px;
}
.gang{
  position: absolute;
  width: 1px;
  background: #d2211b;
  height: 65%;
  left: 120px;
  top:4px
}
.gang_g{
  background: #cdcccc !important;
}
.desc{
  border: 1px solid #eee;
  margin: 0px 20px;
  padding-top: 5px;
  color: #4c4b4b;
  border-radius: 5px;
  background: #eee;
  position: relative;
  bottom: 8px;
}
</style>