<template>
  <div class="main">
    <van-action-sheet v-model:show="show" title="选择众筹商品" @closed="close">
      <div class="content">
        <div class="flex">
          <div class="flex one">
            <div>
              <img :src="now.img">
            </div>
            <div class="two">
              <p id="title">{{now.title}}</p>
              <p id="desc">{{now.description}}</p>
              <p id="price">¥ {{now.price}}</p>
            </div>
          </div>

          <div id="num">剩余{{now.num}}名可支持</div>
        </div>

        <div id="gg">档位规格</div>
        <div id="dangwei">
          <template v-for="(v,k) in sku" :key="k">
            <p id="sku" @click="que(k)" :class="k==key?'select':''">¥{{v.price}}</p>
          </template>
        </div>
      </div>

      <div class="zhichi" @click="pay">立即支持</div>

    </van-action-sheet>
  </div>
</template>

<script>
import {ActionSheet} from 'vant';
export default {
  name: "Sku",
  props:['sku'],
  data() {
    return {
      show:true,
      key:0,
      now:{
        img:'',
        price:'',
        description:'',
        num:'',
        title:'',
      }
    }
  },
  created() {
    //出列第一个
    if(this.sku!=undefined && this.sku.length>0){
      this.now = this.sku[0];
    }
  },
  methods: {
    que(e){
      if(this.sku[e]!=undefined){
        this.key=e;
        this.now = this.sku[e];
      }
    },
    pay(){
      this.$emit('onpay',this.now);
    },
    close(){
      this.$emit('close');
    }
  },
  components: {
    [ActionSheet.name]:ActionSheet
  },
  computed: {}
}
</script>

<style scoped>
.main{font-size: 16px;}
.content {
  border-top: 1px solid #f6f6f6;
  padding: 16px 16px 100px;
}
.zhichi{
  padding: 10px;
  background: #AC3A35;
  color: white;
  border-radius: 15px;
  margin: 5px 30px;
  font-size: 18px;
  margin-bottom: 10px;
}
.one{
  width: 80%;
}
.one img{
  width: 100px;
  height: 95px;
}
.two{
  margin-top: 5px;
  margin-left: 15px;
}
#title{
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}
#desc{
  margin: 10px 0px;
  min-height: 20px;
}
#price{
  color: #d2211b;
}
#num{
  font-size: 14px;
  width: 120px;
  color: #8d8b8b;
  margin-top: 5px;
}
#gg{
  text-align: left;
  margin: 20px;
}
#dangwei{
  display: flex;
 flex-wrap: wrap;
}
#sku{
  background: #9b9898;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  margin: 5px;
}
.select{
  background: #AC3A35!important;
}

</style>
