<template>
  <div>
    <Search
        v-model="value"
        shape="round"
        background="#000"
        placeholder="请输入搜索关键词"
    >
      <template #left>
        <div @click="onClickLeft"><Icon class="left" name="arrow-left" /></div>
      </template>
    </Search>

    <van-tree-select
        v-model:main-active-index="activeIndex"
        :items="items"
        :key="myk"
        class="my-class"
    >
      <template #content >
        <template v-for="(vx,kx) in items" :key="kx">
          <div :class="kx==0?'ty_content':''" >
            <div v-if="activeIndex === kx">
              <img class="tu_type"
                   src="../../assets/img/tup.png"
              />
              <template v-for="(v,k) in vx.children" :key="k">
                <div class="title_t">{{v.text}}</div>
                <van-row>
                  <template v-for="(vs,ks) in v.children" :key="ks">
                    <van-col span="8" @click="$router.push({path:'/typelist',query:{id:vs.id}})">
                      <img class="tu_t" :src="vs.image">
                      <p style="color: #8d8b8b;">{{vs.text}}</p>
                    </van-col>
                  </template>
                </van-row>
              </template>

            </div>
          </div>
        </template>

      </template>
    </van-tree-select>
  </div>
</template>

<script>
import {Search,Icon,TreeSelect,Image,Row,Col} from 'vant';
import {ref} from 'vue';
export default {
    name: "Type",
    setup() {
      const activeIndex = ref(0);
      return {
        activeIndex,
        myk:0,

      };
    },
    data(){
        return {
          items: [
            { text: '1',  className: 'my-class',},
          ],
        }
    },
    created() {
      this.$api.ajaxCategoryList().then(r=>{
        if(r.code==200){
          console.log(r.data);
          this.items = r.data;
        }
      })
    },
    methods:{
    
    },
    components:{
      Search,
      Icon,
      [TreeSelect.name]:TreeSelect,
      [Image.name]:Image,
      [Row.name]:Row,
      [Col.name]:Col
    },
    computed:{
    
    }
}
</script>
<style>
.van-sidebar-item--select{
  background: #AC3A35;
  color: white;
}
.my-class{
  font-size: 16px;
  /*position: relative;*/
  /*z-index: 99;*/
  /*bottom: 1px;*/
}
.van-tree-select__content{
  flex: 3;
  min-height: 92vh;
}
</style>
<style scoped>
.left{
  position: relative;
  top: 5px;
  right: 8px;
}
.title_t{
  margin: 10px;
  color: black;
  display: flex;
  margin-bottom: 20px;
}
.title_t img{
  width: 50px;
}
.ty_content{
  padding: 10px;
  margin-top: 5px;
}
.tu_type{
  width: 250px;
}

.tu_t{
  width: 70px;
  border-radius: 20px;
}
</style>
