import request from "@/util/request";

export default {
    /**
    * 大师馆内容列表
    * */
    collectionList: (data) => {
        return request.post('/NewApi/Collection/index', data);
    },

    /**
    * 大师馆内容详情
    * */
    collectionDetail: (data) => {
        return request.post('/NewApi/Collection/detail', data);
    },
};