<template>
  <div class="main">
    <van-nav-bar
        title="会员积分"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

<!--    -->
    <div class="bg">
      <div class="p1 flex">
        <div class="flex k1">
          <p><img id="jifen_pic" src="@/assets/img/jf-85.png"></p>
          <div class="k2">
            <p><span class="num">000</span>积分</p>
            <p>
              查看记录
              <Icon name="arrow" class="left" />
            </p>
          </div>
        </div>

        <div class="wfgz">
          玩法规则
        </div>
      </div>

<!--      -->

      <div class="p2">
        <div class="flex p2_1">
          <span class="jin">今日签到</span>
          <span class="lian">连续签到,积分更多</span>
        </div>
        <div class="flex qi">
          <div><img src="@/assets/img/jf-87.png"><p>第1天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第2天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第3天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第4天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第5天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第6天</p></div>
          <div><img src="@/assets/img/jf-88.png"><p>第7天</p></div>
        </div>
        <div class="qiandao">签到</div>
      </div>

      <div class="p3">
        <div class="title">做任务，赚积分</div>
        <div class="flex dan1">
          <div class="dan1_1">
            <p class="t1">逛一逛社区</p>
            <p class="t2">
              <img src="@/assets/img/jf-85.png" >积分+0
            </p>
          </div>
          <div class="wancheng">去完成</div>
        </div>
        <div class="flex dan1">
          <div class="dan1_1">
            <p class="t1">逛一逛社区</p>
            <p class="t2">
              <img src="@/assets/img/jf-85.png" >积分+0
            </p>
          </div>
          <div class="wancheng">去完成</div>
        </div>
      </div>

    </div>
<!--    -->


  </div>
</template>

<script>
import {NavBar,Icon} from 'vant'
export default {
    name: "Integral",
    data(){
        return {
        
        }
    },
    created() {
    },
    methods:{
    
    },
    components:{
      [NavBar.name]:NavBar,Icon
    },
    computed:{
    
    }
}
</script>

<style scoped>
.bg{
  background: url("../../../assets/img/jifen.png");
  background-size:100% 100%;
  width: 100%;
  height: 203px;
  z-index: 99;
  position: relative;
  bottom: 1px;
}

.p1{
  color: white;
  z-index: 999;
  font-size: 14px;
  justify-content: space-between;
  padding: 20px;
}
#jifen_pic{
  width: 50px;
}
.k1{
  text-align: left;
}
.k2{
  padding-left: 10px;
}
.num{
  font-size: 24px;
  font-weight: bold;
  padding-right: 5px;
}
.left{
  position: relative;
  top:2px
}
.wfgz{
  position: relative;
  top:32px;
}
.p2{
  background: url('../../../assets/img/jf-86.png');
  background-size:100% 100%;
  margin: 0px 14px;
  font-size: 14px;
  padding: 10px;
}
.p2 img{
  width: 40px;
  padding-right: 8px;
}
.p2_1{
 margin-bottom: 10px;
}
.jin{
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.lian{
  font-size: 12px;
  padding-left: 10px;
  color: #f8d36c;
  padding-top: 4px;
}
.qi{
  margin: 25px 0px;
  font-size: 12px;
  color: #f8d36c;
}
.qi p{
  padding-right: 5px;
}
.qiandao{
  background: #BD8F42;
  padding: 10px 20px;
  color: white;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  margin-bottom: 15px;
}

.p3{
  background: white;
  margin: 15px;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  border-radius: 12px;
}
.title{
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}
.dan1{
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 5px;
  margin-top: 10px;
  width: 100%;
}
.dan1_1{
  font-size: 12px;
}
.t1{
  font-size: 14px;
}
.t2 img{
  width: 16px;
  padding-top: 5px;
  padding-right: 5px;
}
.wancheng{
  background: #BD8F42;
  height: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  position: relative;
  top: 8px;
}
</style>
