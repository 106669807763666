<template>
  <div class="bg">
    <van-nav-bar
        title="我的队伍"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    />

      <div class="red_tips">
        <p>我的队伍</p>
      </div>
<!--    <template  v-for="(v,k) in order" :key="k" :ref="'cG'+k">-->
      <div class="card_my">
        <div class="qname" >
          搭伙商品
        </div>
        <van-swipe-cell style="position: relative;" >
          <van-card
              class="goods-card"
              :thumb="team.original_img"
          >
            <template #title>
              <p class="title">{{team.goods_name}}</p>
            </template>
            <template #price>
              <p class="price_s">¥ {{team.assemble_price}}</p>
            </template>
            <template #footer>
              X 1
            </template>
          </van-card>
          <template #right>
<!--            <van-button square style="height: 100%" text="删除" type="danger" class="delete-button" />-->
          </template>
        </van-swipe-cell>
      </div>

    <div class="p1">
      <p class="address" v-if="parseInt(team.person_num) !== team.ranks.length" style="color: orangered;">队伍人数 {{team.ranks.length}}, 还差 {{parseInt(team.person_num) - team.ranks.length}} 人</p>
      <p class="address" style=" color: orangered;" v-else>队伍人数已满</p>
      <div class="name" v-for="(v,k) in team.ranks" :key="k">
          <div class="flex" style="justify-content: space-between;">
            <p><img :src="v.head_pic?v.head_pic:'/avatar.png'"> <span class="nickname">{{v.nickname}}</span></p>
            <p class="role">{{k==0?'发起人':'队员'}}</p>
          </div>
      </div>

    </div>


    <!--    </template>-->

  </div>

  <div class="flex bottom" v-if="1==2" >
    <div class="p10">
      <div><Button class="dingdan quxiao" @click="pay(0)">取消订单</Button></div>
      <div><Button class="dingdan fukuai" @click="pay(1)">付款</Button></div>
    </div>
  </div>



</template>

<script>
import {NavBar, Toast,Button,Icon,Checkbox,SwipeCell,Card,Popup,CountDown,Steps,Step,Dialog} from 'vant';
import {ref} from 'vue';
export default {
  name: "Orderinfo",
  setup() {
    const endtime = ref(2 * 60 * 60 * 1000);
    return { endtime };
  },
  data() {
    return {
      zpshow:false,
      yunfei:8,
      payshow:false,
      id:0,
      list:{
        loading:false,
        finished:true,
      },
      team:{ranks:[]},
      teamId:'',
    }
  },
  created() {
    if(this.$route.query.share_code==undefined || this.$route.query.share_code<0){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }
    this.id = this.$route.query.share_code;
    this.$api.myteam(this.id).then(r=>{
      if(r.code==200){
        console.log(r.data);
        this.team = r.data.assemble_info;
        this.teamId = r.data.share_id;
      }
    })
  },
  methods: {
    onClickLeft(){
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    onClickRight() {
      Toast('按钮');
    },
    pay(i){
      if(i==0){
        Dialog.confirm({
          title: '取消订单',
          message: '您真的要取消订单吗？',
          theme: 'round-button',
        }).then(() => {
              this.$api.cancel_order(this.id).then(r=>{
                if(r.code==200){
                  Toast(r.msg);
                  setTimeout(()=>{
                    window.location.reload();
                  },2000)
                }
              })
            })
            .catch(() => {
              // on cancel
            });

        return;
      }
      this.payshow=true;
    },
    payclose(){
      this.payshow=false;
    },
    formatTimestamp(time = 0, fmt = 'yyyy-MM-dd hh:mm:ss') {
      if(time === 0 || !time) {
        return ''
      }
      time = time*1000;
      let date = new Date(time)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
      }
      return fmt;
    }
  },
  components: {
    [NavBar.name]:NavBar,Button,[Icon.name]:Icon,
    [Checkbox.name]:Checkbox,[SwipeCell.name]:SwipeCell,[Card.name]:Card
    ,[Popup.name]:Popup,
    [CountDown.name]:CountDown,
    [Steps.name]:Steps,
    [Step.name]:Step,
  },
  computed: {
    total(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.price*vs.num
      //   })
      // })
      return b;
    },
    num(){
      var b = 0;
      // this.cats.forEach(v=>{
      //   v.list.forEach(vs=>{
      //     b +=vs.num
      //   })
      // })
      return b;
    }
  }
}
</script>
<style scoped>
.p1{
  margin: 10px;
  background: white;
  border-radius: 6px;
  padding: 10px;
  position: relative;
}

.qname{
  font-size: 16px;
  display: flex;
  margin-bottom: 18px;
  line-height: 30px;
}
.qpic{width: 30px;border-radius: 50px;margin-right: 8px;}

.bg{
  background: #f9f9f9;
  margin-bottom: 140px;
}

.bottom{
  position: fixed;
  bottom: 0px;
  background: #F6F6F6;
  width: 100%;
}
.p10{
  display: flex;
  justify-content:flex-end;
  padding: 10px;
  width: 100%;
}

.dingdan{
  /*border-radius: 5px;*/
  color: white;
  font-weight: bold;
  padding-right: 30px;
  padding-left: 30px;
  width: 120px;
}

.quxiao{
  margin: 0;
  background: #f6f6f6;
  color: black;
  border: 1px solid #c1bdbd;
  /*left: 5px;*/
  border-bottom-left-radius:6px;
  border-top-left-radius:6px;
}
.fukuai{
  background:#AC3A35;
  border: 1px solid #AC3A35;
  border-bottom-right-radius:6px;
  border-top-right-radius:6px;

}

.red_tips{
  background: #AC3A35;
  padding: 15px;
  position: relative;
  bottom: 1px;
  z-index: 99;
  color: white;
  font-size: 18px;
  text-align: left;
}
.time_w{
  font-size: 18px;
  color: white;
  position: relative;
  top: 4px;
}

.address{
  text-align: left;
  font-size: 16px;
}

.name{
  color: #8d8b8b;
  margin-top: 8px;
  border-top: 1px solid #eee;
}
.name div{
  padding-top: 10px;
  padding-right: 10px;
  font-size: 16px;
}
.name img{
  width: 40px;
  height: 40px;
  border-radius: 85px;
}
.nickname{
  position: relative;
  bottom: 15px;
  left: 10px;
}
.role{
  position: relative;
  top: 10px;
}
.card_my{
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.title{
  font-size: 16px;
  text-align: left;
  width: 160px;
  padding-left: 20px;
}
.price_s{
  color: #d2211b;
  text-align: left;
  position: relative;
  right: 50px;
  font-size: 20px;
  font-weight: bold;
}

.yunfei p{
  margin: 3px 0px;
}
.left{
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
}

.wuliu >>> .van-step__title{
  font-size: 12px ;
  /*text-align: left;*/
  /*padding-left: 20px;*/
}


</style>
