<template>
  <div>
    <van-nav-bar
        title="开店申请"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
      </template>
    </van-nav-bar>

    <div>
      <Steps :active="active" active-color="#AC3A35">
        <Step>填写信息</Step>
        <Step>平台审核</Step>
        <Step>完成</Step>
      </Steps>
    </div>



    <van-form @submit="onSubmit" class="form">

      <div class="p1">
        <div class="title">联系人信息</div>
        <div class="kuai">
          <div class="name">联系人</div>
          <input class="content" v-model="contactInfo.contacts_name" placeholder="请填写真实姓名"/>
        </div>

        <div class="kuai">
          <div class="name">联系人手机</div>
          <input class="content" v-model="contactInfo.contacts_mobile" placeholder="请填写手机号码"/>
        </div>

        <div class="kuai">
          <div class="name">联系人邮箱</div>
          <input class="content" v-model="contactInfo.contacts_email" placeholder="请填写联系人邮箱"/>
        </div>
      </div>

      <div class="p1">
        <div class="title">入驻类型</div>
        <div class="kuai">
          <van-radio-group v-model="apply_type">
              <van-cell title="个人" clickable  @click="qie(1)">
                <template #right-icon>
                  <van-radio name="1"  />
                </template>
              </van-cell>
              <van-cell title="公司" clickable  @click="qie(2)">
                <template #right-icon>
                  <van-radio name="2"  />
                </template>
              </van-cell>
          </van-radio-group>
        </div>

      </div>
<!--个人-->
      <div class="geren" v-if="apply_type==1">

        <div class="p1">
          <div class="title">店铺信息</div>

          <div class="kuai">
            <div class="name">店铺名称</div>
            <input class="content" v-model="info.geren.storeInfo.store_name" placeholder="请输入店铺名称"/>
          </div>

          <div class="kuai" @click="state.xz.showPicker=true">
            <div class="name">店铺性质</div>
            <div class="content" >
              {{state.xz.value}}
            </div>
          </div>
          <van-popup v-model:show="state.xz.showPicker" round position="bottom">
            <van-picker
                :columns="storeTypes"
                @cancel="state.xz.showPicker = false"
                @confirm="onConfirm_xz"
            />
          </van-popup>

          <div class="kuai" @click="state.dpfl.showPicker=true">
            <div class="name">主营分类</div>
            <div class="content" >
              {{state.dpfl.value}}
            </div>
          </div>
          <van-popup v-model:show="state.dpfl.showPicker" round position="bottom">
            <van-picker
                :columns="storeClass"
                @cancel="state.dpfl.showPicker = false"
                @confirm="onConfirm_dpfl"
            />
          </van-popup>

          <div class="kuai">
            <div class="name">登录账号</div>
            <input class="content" v-model="info.geren.storeInfo.seller_name" placeholder="请输入登录账号"/>
          </div>

          <div class="kuai">
            <div class="name">负责人姓名</div>
            <input class="content" v-model="info.geren.storeInfo.store_person_name" placeholder="请输入负责人姓名"/>
          </div>

          <div class="kuai">
            <div class="name">负责人手机号</div>
            <input class="content"  v-model="info.geren.storeInfo.store_person_mobile" placeholder="请输入负责人手机号"/>
          </div>

          <div class="kuai">
            <div class="name">负责人邮箱</div>
            <input class="content" v-model="info.geren.storeInfo.store_person_email" placeholder="请输入负责人邮箱"/>
          </div>

          <div class="kuai">
            <div class="name">详细地址</div>
            <input class="content" v-model="info.geren.storeInfo.company_address" placeholder="请输入详细地址"/>
          </div>
        </div>

        <div class="p1">
          <div class="title">身份信息</div>
          <div class="kuai">
            <div class="name">身份证号码</div>
            <input class="content" v-model="info.geren.personInfo.store_person_identity" placeholder="请输入身份证号码"/>
          </div>

            <div class="kuai">
              <div class="name">身份证正面</div>
              <div class="content">
                <van-uploader v-model="fileList_z" name="zheng" multiple :max-count="1" :after-read="afterRead"/>
              </div>
            </div>

          <div class="kuai">
            <div class="name">身份证反面</div>
            <div class="content">
              <van-uploader v-model="fileList_f" name="fan" multiple :max-count="1" :after-read="afterRead"/>
            </div>
          </div>
            <div class="tips">
              请上传身份证正、反面。大小5M以内
            </div>
        </div>

        <div class="p1">
          <div class="title">结算银行账户</div>
          <div class="kuai">
            <div class="name">银行开户名</div>
            <input class="content"  v-model="info.geren.bankInfo.bank_account_name" placeholder="请输入银行开户名"/>
          </div>
          <div class="kuai">
            <div class="name">银行账号</div>
            <input class="content" v-model="info.geren.bankInfo.bank_account_number" placeholder="请输入银行账号"/>
          </div>
          <div class="kuai">
            <div class="name">开户行</div>
            <input class="content" v-model="info.geren.bankInfo.bank_branch_name" placeholder="请输入开户行"/>
          </div>

          <div class="kuai" @click="state.khh.showPicker=true">
            <div class="name">开户行所在地</div>
            <div class="content" >
              {{state.khh.value}}
            </div>
          </div>
          <van-popup v-model:show="state.khh.showPicker" round position="bottom">
            <van-area :area-list="areaList" :columns-num="2" title="选择地址" v-model="info.geren.bankInfo.addressInfo" @confirm="onConfirm_khh" @cancel="state.khh.showPicker = false"/>

          </van-popup>

        </div>


      </div>
<!--      -->
<!--企业-->
      <div class="qiye" v-if="apply_type==2">

        <div class="p1">
          <div class="title">公司信息</div>

          <div class="kuai">
            <div class="name">公司名称</div>
            <input class="content" v-model="info.gongsi.companyInfo.company_name" placeholder="请填写公司名称"/>
          </div>

          <div class="kuai" @click="state.gsxz.showPicker=true">
            <div class="name">公司性质</div>
            <div class="content" >
              {{state.gsxz.value}}
            </div>
          </div>
          <van-popup v-model:show="state.gsxz.showPicker" round position="bottom">
            <van-picker
                :columns="companyTypes"
                @cancel="state.gsxz.showPicker = false"
                @confirm="onConfirm_gsxz"
            />
          </van-popup>

          <div class="kuai">
            <div class="name">公司官网</div>
            <input class="content" v-model="info.gongsi.companyInfo.company_website" placeholder="可留空"/>
          </div>

          <div class="kuai" @click="state.gsszd.showPicker=true">
            <div class="name">公司所在地</div>
            <div class="content" >
              {{state.gsszd.value}}
            </div>
          </div>
          <van-popup v-model:show="state.gsszd.showPicker" round position="bottom">
            <van-area :area-list="areaList" :columns-num="3" title="选择地址" v-model="info.gongsi.companyInfo.addressInfo"
                      @confirm="onConfirm_gsszd" @cancel="state.gsszd.showPicker = false"/>
          </van-popup>

          <div class="kuai">
            <div class="name">公司详细地址</div>
            <input class="content" v-model="info.gongsi.companyInfo.company_address"  placeholder="请输入公司详细地址"/>
          </div>

          <div class="kuai">
            <div class="name">固定电话</div>
            <input class="content"  v-model="info.gongsi.companyInfo.company_telephone" placeholder="请输入固定电话"/>
          </div>

          <div class="kuai">
            <div class="name">电子邮箱</div>
            <input class="content"  v-model="info.gongsi.companyInfo.company_email" placeholder="请输入电子邮箱"/>
          </div>

          <div class="kuai">
            <div class="name">传真</div>
            <input class="content"  v-model="info.gongsi.companyInfo.company_fax" placeholder="请输入传真"/>
          </div>

          <div class="kuai">
            <div class="name">邮政编码</div>
            <input class="content" v-model="info.gongsi.companyInfo.company_zipcode"  placeholder="请输入邮箱编码"/>
          </div>

        </div>

        <div class="p1">
          <div class="title">营业执照信息</div>
          <div class="kuai">
            <div class="name">注册资金</div>
            <input class="content"  v-model="info.gongsi.companyInfo.reg_capital" placeholder="请输入注册资金，单位:万元"/>
          </div>
        </div>

        <div class="p1">
          <div class="title">一证一码商家</div>
          <div class="kuai">
            <van-radio-group v-model="info.gongsi.companyInfo.threeinone">
              <van-cell title="是" clickable  @click="info.gongsi.companyInfo.threeinone=='1'">
                <template #right-icon>
                  <van-radio name="1"  />
                </template>
              </van-cell>
              <van-cell title="否" clickable  @click="info.gongsi.companyInfo.threeinone=='2'">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
            </van-radio-group>
          </div>

          <div class="kuai">
            <div class="name">信用代码</div>
            <input class="content" v-model="info.gongsi.companyInfo.business_licence_number" placeholder="请输入统一社会信用代码"/>
          </div>

          <div class="kuai">
            <div class="name">法人姓名</div>
            <input class="content"  v-model="info.gongsi.companyInfo.legal_person" placeholder="请输入法人姓名"/>
          </div>

          <div class="kuai" @click.stop="showBusinessDateAction=true">
            <div class="name">营业执照有效期</div>
            <div class="content"  >
              {{info.gongsi.companyInfo.business_date?info.gongsi.companyInfo.business_date:'请选择'}}
            </div>
          </div>

          <!--日期选择-->
          <van-action-sheet
              v-model:show="showBusinessDateAction"
              :actions="businessDateActions"
              cancel-text="取消"
              @select="businessDateSelect"
              @cancel="() => {showBusinessDateAction = false}">

          </van-action-sheet>
          <van-popup v-model:show="showDateChoose" position="bottom">
            <van-datetime-picker
                :title="dateChooseTip"
                v-model:show="nowChooseDate"
                :formatter="formatter"
                @confirm="dateChooseOk"
                @cancel="showDateChoose = false"
                type="date"></van-datetime-picker>
          </van-popup>

          <div class="kuai">
            <div class="name">经营范围</div>
            <input class="content" v-model="info.gongsi.companyInfo.business_scope" placeholder="请输入经营范围"/>
          </div>

          <div class="kuai" @click="state.nsr.showPicker=true">
            <div class="name">纳税人类型</div>
            <div class="content"  >
              {{info.gongsi.companyInfo.taxpayer?info.gongsi.companyInfo.taxpayer:'请选择'}}
            </div>
          </div>
          <van-popup v-model:show="state.nsr.showPicker" round position="bottom">
            <van-picker
                :columns="taxPayers"
                @cancel="state.nsr.showPicker = false"
                @confirm="onConfirm_nsr"
            />
          </van-popup>
          <div class="kuai" @click="state.nslx.showPicker=true">
            <div class="name">纳税类型税码</div>
            <div class="content"  >
              {{info.gongsi.companyInfo.tax_rate?info.gongsi.companyInfo.tax_rate:'请选择'}}
            </div>
          </div>
          <van-popup v-model:show="state.nslx.showPicker" round position="bottom">
            <van-picker
                :columns="taxRates"
                @cancel="state.nslx.showPicker = false"
                @confirm="onConfirm_nslx"
            />
          </van-popup>

          <div class="kuai">
            <div class="name">营业执照</div>
            <div class="content">
              <van-uploader v-model="yingyeList" name="ye" multiple :max-count="1" :after-read="afterRead"/>
            </div>
          </div>


        </div>
      </div>


      <div style="margin: 16px;">
        <van-button round block type="primary" @click="smp" style="background: #AC3A35;border: 1px solid #AC3A35;" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import {
  NavBar,
  Icon,
  Form,
  Field,
  Button,
  Uploader,
  Step,
  Steps,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  Popup,
  Picker,
  Area,
  Toast,
    DatetimePicker,ActionSheet
} from 'vant'
import {ref} from 'vue'
// const regExp_contactInfo = {
//     contacts_name: {reg: /^[^\s]{2,}$/, tip: '联系人信息格式有误'},
//     contacts_mobile: {reg: /^1[3-9][0-9]{9}$/, tip: '联系人手机号有误'},
//     contacts_email: {reg: /^[\w]+@[\w]+\.[.\w]+$/, tip: '联系人邮箱地址有误'},
//     apply_type: {reg: /^[01]$/, tip: '入驻类型有误'},
// };
export default {
  name: "Kaidian",
  setup() {
    const fileList_z = ref([
      // Uploader 根据文件后缀来判断是否为图片文件
      // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    ]);
    const fileList_f = ref([
      // Uploader 根据文件后缀来判断是否为图片文件
      // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    ]);
    const yingyeList = ref([
      // Uploader 根据文件后缀来判断是否为图片文件
      // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    ]);
    return {
      fileList_z,
      fileList_f,
      yingyeList,
    };
  },
  data(){
    return {
      active:0,
      mutex:false,
      columns:[
        '杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'
      ],
      storeTypes: ['旗舰店', '专卖店', '专营店'],
      // 纳税人类型
      taxPayers: [
        '请选择',
        '一般纳税人',
        '小规模纳税人',
        '非增值税纳税人',
      ],
      // 纳税码
      taxRates: [
        '0%',
        '3%',
        '6%',
        '7%',
        '11%',
        '13%',
        '17%',
      ],
      // 当前选择的日期
      nowChooseDate: new Date(),
      // 是否显示日期选择器
      showDateChoose: false,
      // 日期选择提示文字
      dateChooseTip: '起始时间',
      // 是否展示营业执照有效期选择菜单
      showBusinessDateAction: false,
      // 营业执照有效期选择弹层选项
      businessDateActions: [
        {name: '选择起止时间', action: 'choose'},
        {name: '长期有效', action: 'long'},
      ],
      // 当前选择的日期属于开始还是结束
      nowDateChooseType: 0,

      storeClass:[],
      storeClassName:[],
      companyTypes:[],
      contactInfo: {
        contacts_name: '',
        contacts_mobile: '',
        contacts_email: '',
        apply_type: '1',
      },


      info:{
        geren:{
          // 表单信息
          storeInfo: {
            // 店铺名称
            store_name: '',
            // 店铺类型(展示用)
            store_type_dis: '',
            // 店铺类型
            store_type: '',
            // 主营类目
            sc_name: '',
            // 主营类目ID
            sc_id: '',
            // 店铺登录账号
            seller_name: '',
            // 店铺负责人名称(姓名)
            store_person_name: '',
            // 店铺负责人联系电话
            store_person_mobile: '',
            // 店铺负责人联系邮箱
            store_person_email: '',
            // 店铺详细地址
            company_address: '',
          },

          // 身份信息
          personInfo: {
            // 身份证号
            store_person_identity: '',
            // 身份证正面
            legal_identity_cert: {name: ''},
            // 身份证反面
            store_person_cert: {name: ''},
          },

          // 结算银行信息
          bankInfo: {
            // 银行开户名
            bank_account_name: '',
            // 银行账号
            bank_account_number: '',
            // 开户行名称
            bank_branch_name: '',
            // 开户行所在地(仅做展示)
            addressInfo: '',
            // 开户行所在省
            bank_province: '',
            // 开户行所在市
            bank_city: '',
          },
        },
        gongsi:{
          // 表单信息
          companyInfo: {
            company_name: '',
            company_type: '',
            company_website: '',
            addressInfo: '',
            company_province: '',
            company_city: '',
            company_district: '',
            company_address: '',
            company_telephone: '',
            company_email: '',
            company_fax: '',
            company_zipcode: '',
            threeinone: '1',
            reg_capital: '',
            business_licence_number: '',
            legal_person: '',
            business_date: '',
            business_date_start: '',
            business_date_end: '',
            business_permanent: 'N',
            business_scope: '',
            taxpayer: '',
            tax_rate: '',
            business_licence_cert: {name: ''},
          },
        },
      },
      state:{
       dpfl:{
         showPicker:false,
         value:'请选择'
       },
        xz:{
          showPicker:false,
          value:'请选择'
       },
        khh:{
          showPicker:false,
          value:'请选择'
        },
        gsxz:{
          showPicker:false,
          value:'请选择'
        },
        gsszd:{
          showPicker:false,
          value:'请选择'
        },
        nsr:{
          showPicker:false,
          value:'请选择'
        },
        nslx:{
          showPicker:false,
          value:'请选择'
        },
      },
      apply_type:'',
      checked_shangjia:'',
      areaList:[],
    }
  },
  created() {
    this.$api.kd_check().then(r=>{
      if(r.code==301){
        this.$router.push('/kdinfo');
      }
      if(r.code==302){
        this.$router.push('/gskd');
      }
    })
  },
  methods:{
    smp(){
      //先检查联系人
      let re = this.lianxi_check();
      if(!re) return;
      //检查类型
      if(this.apply_type=='' || this.apply_type==undefined){
        Toast('请选择入驻类型');
        return;
      }

      if(this.apply_type==1){           // 个人

        let rr = this.geren_check();
        if(!rr) return;
        if (this.mutex) return;
        this.mutex = true;
        this.$api.one_sumb({
          ...this.contactInfo,
          ...this.info.geren.personInfo,
          ...this.info.geren.storeInfo,
          ...this.info.geren.bankInfo,
          apply_type:this.apply_type=='2'?0:1
        }).then(r=>{
              this.mutex = false;
              // console.log(r);
              if(r.code==200){
                Toast(r.msg);
                this.$router.push('/kdinfo')
              }
        })
      }else{
      //  企业
        let rr = this.qiye_check();
        if(!rr) return;
        if (this.mutex) return;
        this.mutex = true;
        this.$api.one_sumb({
          ...this.contactInfo,
          ...rr,
          apply_type:this.apply_type=='2'?0:1
        }).then(r=>{
          this.mutex = false;
          // console.log(r);
          if(r.code==200){
            Toast(r.msg);
            this.$router.push('/gskd')
          }
        })
      }
    },
    lianxi_check(){
      let regExp = {
        contacts_name: {reg: /^[^\s]{2,}$/, tip: '联系人信息格式有误'},
        contacts_mobile: {reg: /^1[3-9][0-9]{9}$/, tip: '联系人手机号有误'},
        contacts_email: {reg: /^[\w]+@[\w]+\.[.\w]+$/, tip: '联系人邮箱地址有误'},
        apply_type: {reg: /^[01]$/, tip: '入驻类型有误'},
      };
      // 校验表单数据格式
      let verify = true;
      let errList = [];
      for (let i in regExp) {
        if (regExp[i]!=undefined) {
          if (!regExp[i].reg.test(this.contactInfo[i])) {
            verify = false;
            errList.push(regExp[i].tip);
          }
        }
      }
      // 表单数据格式校验失败
      if (!verify) {
        let errMsg = errList.join("\n");
        Toast(errMsg);
      }
      return verify;
    },
    geren_check(){
      // 处理部分值需要特殊处理的
      this.info.geren.storeInfo.store_type = this.getStoreType(this.info.geren.storeInfo.store_type_dis);
      this.info.geren.storeInfo.sc_id = this.getScId(this.info.geren.storeInfo.sc_name);

      let regExpList = {
        // 表单信息
        storeInfo: {
          // 店铺名称
          store_name: {reg: /^[^'s]+$/, tip: '店铺名称有误'},
          // 店铺类型
          store_type: {reg: /^[0-2]$/, tip: '请选择店铺类型'},
          // 主营类目
          sc_name: {reg: /^[^\s]+$/, tip: '请选择主营类目'},
          // 主营类目ID
          sc_id: {reg: /^[0-9]+$/, tip: '请选择主营类目'},
          // 店铺登录账号
          seller_name: {reg: /^[\w]+$/, tip: '请输入登录账号'},
          // 店铺负责人名称(姓名)
          store_person_name: {reg: /^[^\s]{2,}$/, tip: '请输入店铺负责人姓名'},
          // 店铺负责人联系电话
          store_person_mobile: {reg: /^1[3-9][0-9]{9}$/, tip: '请输入店铺负责人联系电话'},
          // 店铺负责人联系邮箱
          store_person_email: {reg: /^[\w]+@[\w]+\.[.\w]+$/, tip: '请输入店铺负责人联系邮箱'},
          // 店铺详细地址
          company_address: {reg: /^[^\s]+$/, tip: '请输入店铺详细地址'},
        },

        // 身份信息
        personInfo: {
          // 身份证号
          store_person_identity: {reg: /^[0-9xX]{18}$/, tip: '请输入身份证号'},
          // 身份证正面
          legal_identity_cert: {reg: /^[^\s]+$/, tip: '请上传身份证正面照'},
          // 身份证反面
          store_person_cert: {reg: /^[^\s]+$/, tip: '请上传身份证反面照'},
        },

        // 结算银行信息
        bankInfo: {
          // 银行开户名
          bank_account_name: {reg: /^[^\s]+$/, tip: '请输入银行开户名'},
          // 银行账号
          bank_account_number: {reg: /^[0-9]{16,}$/, tip: '请输入正确银行账户'},
          // 开户行名称
          bank_branch_name: {reg: /^[^\s]+$/, tip: '请输入开户行名称'},
          // 开户行所在省
          bank_province: {reg: /^[^\s]+$/, tip: '请选择开户行所在地址'},
          // 开户行所在市
          bank_city: {reg: /^[^\s]+$/, tip: '请选择开户行所在地址'},
        },
      };

      // 校验表单数据格式
      let verify = true;
      let errList = [];
      for (let i in regExpList) {
        if (regExpList[i]!=undefined) {
          let regExp = regExpList[i];
          for (let l in regExp) {
            if (regExp[l]!=undefined) {
              if (l === 'legal_identity_cert' || l === 'store_person_cert'){
                if (!regExp[l].reg.test(this.info.geren[i][l].name)){
                  verify = false;
                  errList.push(regExp[l].tip);
                }
                continue;
              }
              if (!regExp[l].reg.test(this.info.geren[i][l])) {
                verify = false;
                errList.push(regExp[l].tip);
              }
            }
          }
        }
      }
      // 表单数据格式校验失败
      if (!verify) {
        let errMsg = errList.join("\n");
        Toast(errMsg);
        return false;
      }
      return true;
    },
    qiye_check(){
      // 表单信息校验规则
      let regExp = {
        company_name: {reg: /^[^\s]{2,}$/, tip: '公司名称格式有误'},
        company_type: {reg: /^[0-7]$/, tip: '公司类型有误'},
        company_website: {reg: /(^$|^[\w.]+$)/, tip: '公司官网有误'},
        company_province: {reg: /^[^\s]+$/, tip: '公司地址有误'},
        company_city: {reg: /^[^\s]+$/, tip: '公司地址有误'},
        company_district: {reg: /^[^\s]+$/, tip: '公司地址有误'},
        company_telephone: {reg: /^[0-9]{7,}$/, tip: '固定电话格式有误'},
        company_email: {reg: /^[\w]+@[\w]+\.[.\w]+$/, tip: '请输入正确的邮箱地址'},
        company_fax: {reg: /(^$|^[^\s]+$)/, tip: '传真格式有误'},
        company_zipcode: {reg: /^[0-9]{6,}$/, tip: '邮政编码格式错误'},
        threeinone: {reg: /^[01]$/, tip: '请选择是否一证一码商家'},
        reg_capital: {reg: /^[0-9.]+$/, tip: '请输入正确的注册资金'},
        business_licence_number: {reg: /^[0-9A-Z]{18,}$/, tip: '请输入正确的统一社会信用代码'},
        legal_person: {reg: /^[^\s]{2,}$/, tip: '请输入正确的法定代表人姓名'},
        business_date_start: {reg: /(^$|^[0-9]{4,}-[0-9]+-[0-9]+$)/, tip: '请选择正确的营业执照有效期'},
        business_date_end: {reg: /(^$|^[0-9]{4,}-[0-9]+-[0-9]+$)/, tip: '请选择正确的营业执照有效期'},
        business_permanent: {reg: /^[YN]$/, tip: '请选择正确的营业执照有效期'},
        business_scope: {reg: /^.+$/, tip: '请输入正确的经营范围'},
        taxpayer: {reg: /^[1-3]$/, tip: '请输入正确的纳税人类型'},
        tax_rate: {reg: /^[01367]+$/, tip: '请输入正确的纳税类型税码'},
      };
      // 整理出有效的格式
      let companyInfo = JSON.parse(JSON.stringify(this.info.gongsi.companyInfo));
      // 剔除展示用的多余信息
      delete companyInfo.addressInfo;
      delete companyInfo.business_date;
      // 选项的正确值处理
      // 公司类型
      for (let i in this.companyTypes) {
        if (this.companyTypes[i]!=undefined && this.companyTypes[i] === companyInfo.company_type) {
          companyInfo.company_type = i;
        }
      }
      // 纳税人类型
      for (let i in this.taxPayers) {
        if (this.taxPayers[i]!=undefined && this.taxPayers[i] === companyInfo.taxpayer) {
          companyInfo.taxpayer = i;
        }
      }
      // 纳税类型税码
      let taxRateReg = /^([0-9]+)%$/;
      if (taxRateReg.test(companyInfo.tax_rate)) {
        let res = taxRateReg.exec(companyInfo.tax_rate);
        if (typeof res[1] !== 'undefined'){
          companyInfo.tax_rate = res[1];
        }
      }

      // 校验表单数据格式
      let verify = true;
      let errList = [];
      for (let i in regExp) {
        if (regExp[i]!=undefined) {
          if (!regExp[i].reg.test(companyInfo[i])) {
            verify = false;
            errList.push(regExp[i].tip);
          }
        }
      }
      companyInfo.business_licence_cert = this.info.gongsi.companyInfo.business_licence_cert;
      // 营业执照
      if (companyInfo.business_licence_cert.name === ''){
        verify = false;
        errList.push('请上传营业执照');
      }
      // 表单数据格式校验失败
      if (!verify) {
        let errMsg = errList.join("\n");
        Toast(errMsg);
        return false;
      }

      return companyInfo;
      // return verify;
    },
    afterRead(file,name){
      file.status = 'uploading';
      file.message = '上传中...';

      console.log(file);
      console.log(name);

      this.$api.upload_img(file.file).then(r=>{
        if(r.code==200){
          file.status = 'success';
          file.message = '上传成功';
          if(name.name=='zheng'){
            this.info.geren.personInfo.legal_identity_cert = r.data.img;
          }
          if(name.name=='fan'){
              this.info.geren.personInfo.store_person_cert = r.data.img;
          }

          if(name.name=='ye'){
            this.info.gongsi.companyInfo.business_licence_cert = r.data.img;
          }

        }else{
          file.status = 'failed';
          file.message = '上传失败';
        }
        console.log(r);
      })

    },
    qie(e){
      this.apply_type = ''+e+'';
      console.log(e);
      if(e=='1'){
      //  个人
        // 店铺主营分类处理
        this.$api.get_storelist().then(r=>{
          console.log(r.data);
          this.storeClass = [];
          this.storeClassName = r.data;
          for (let i in this.storeClassName) {
            if (this.storeClassName[i]!=undefined){
              this.storeClass.push(this.storeClassName[i]);
            }
          }
        })
      }else{
      //  企业
        this.$api.cmdata().then(r=>{
          // console.log(r);
          this.companyTypes = r.data.company_type;
        })

      }
      this.getAreaList();
    },
    /* 获取店铺类型(性质)* */
    getStoreType: function (typeName) {
      for (let i in this.storeTypes){
        if (this.storeTypes[i]!=undefined && this.storeTypes[i] === typeName){
          return i;
        }
      }
      return false;
    },
    /** 获取主营类目* */
    getScId: function (scName) {
      for (let i in this.storeClassName){
        if (this.storeClassName[i]!=undefined && this.storeClassName[i] === scName){
          return i;
        }
      }
      return false;
    },
    /** 获取省市区数据* */
    getAreaList: function () {
      this.$api.get_arealist().then(r=>{
        if(r.code==200){
          this.areaList = r.data;
        }
      })
    },
    onConfirm_dpfl(value){
        this.state.dpfl.value = value;
        this.info.geren.storeInfo.sc_name = value;
        this.state.dpfl.showPicker = false;
        // console.log(111);
    },
    onConfirm_xz(value){
      this.state.xz.value = value;
      this.info.geren.storeInfo.store_type_dis = value;
      this.state.xz.showPicker = false;
      // console.log(111);
    },
    //开户行选择
    onConfirm_khh(value){
      this.state.khh.value =value[0].name + value[1].name;
      this.info.geren.bankInfo.bank_province = value[0].name;
      this.info.geren.bankInfo.bank_city = value[1].name;
      this.info.geren.bankInfo.addressInfo = value[0].name + value[1].name;
      this.state.khh.showPicker = false;
      // console.log(111);
    },
    onConfirm_gsxz(value){
      this.state.gsxz.value = value;
      this.state.gsxz.showPicker = false;
      this.info.gongsi.companyInfo.company_type = value;

      // console.log(111);
    },
    onConfirm_gsszd(address){
      this.state.gsszd.value =address[0].name + address[1].name + address[2].name;
      this.state.gsszd.showPicker = false;
      this.info.gongsi.companyInfo.addressInfo = address[0].name + address[1].name + address[2].name;
      this.info.gongsi.companyInfo.company_province = address[0].name;
      this.info.gongsi.companyInfo.company_city = address[1].name;
      this.info.gongsi.companyInfo.company_district = address[2].name;
      // console.log(111);
    },
    onConfirm_nsr(value){
      this.state.nsr.value = value;
      this.info.gongsi.companyInfo.taxpayer = value;
      this.state.nsr.showPicker = false;
      // console.log(111);
    },
    onConfirm_nslx(value){
      this.state.nslx.value = value;
      this.info.gongsi.companyInfo.tax_rate = value;
      this.state.nslx.showPicker = false;
      // console.log(111);
    },
    /*
    * 营业执照日期选择完毕回调
    * */
    dateChooseOk: function (data) {
      if (this.nowDateChooseType === 0) {  // 起始时间
        this.info.gongsi.companyInfo.business_date = data.toLocaleDateString().replace(/\//g, '-');
        this.businessDateChoose(1);
      } else {      // 截止时间
        this.info.gongsi.companyInfo.business_date += ' ~ ' + data.toLocaleDateString().replace(/\//g, '-');
        this.showDateChoose = false;
        // this.nowChooseDate = false;
      }
    },
    /*
    * 营业执照有效期操作选择完毕
    * */
    businessDateSelect: function (val) {
      if (val.action === 'choose') {   // 选择起始日期
        this.info.gongsi.companyInfo.business_permanent = 'N';
        this.businessDateChoose(0);
      } else {      // 长期有效
        this.info.gongsi.companyInfo.business_date_start = '';
        this.info.gongsi.companyInfo.business_date_end = '';
        this.info.gongsi.companyInfo.business_permanent = 'Y';
        this.info.gongsi.companyInfo.business_date = '长期有效';
      }

      this.showBusinessDateAction = false;
    },
    /*
    * 选择营业执照起止时间
    * */
    businessDateChoose: function (type) {
      if (type === 0) {    // 第一次选择, 开始时间
        this.dateChooseTip = '起始时间';
        this.nowDateChooseType = 0;
        this.showDateChoose = true;
      } else {      // 第二次选择, 结束时间
        this.dateChooseTip = '结束时间';
        this.nowDateChooseType = 1;
        this.showDateChoose = true;
      }
    },
    /*
    * 日期格式化
    * */
    formatter: function (type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`
      }
      return `${value}日`;
    },
    onClickLeft(){
      this.$router.push('/user')
    }
  },
  components:{
    [NavBar.name]:NavBar,[Icon.name]:Icon,
    [Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
    [Uploader.name]:Uploader,
    [Cell.name]:Cell,
    [CellGroup.name]:CellGroup,
    [RadioGroup.name]:RadioGroup,
    [Radio.name]:Radio,
    [Popup.name]:Popup,
    [Picker.name]:Picker,
    [Area.name]:Area,
    [DatetimePicker.name]:DatetimePicker,
    [ActionSheet.name]:ActionSheet,
    Step,
    Steps,
  },
  computed:{

  }
}
</script>

<style scoped>
.form{
  margin: 10px;
  border-radius: 18px;
}
.van-cell.van-field:first-child{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.van-cell.van-field:nth-child(3){
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.p1{
  color: black;
  margin: 10px;
  font-size: 16px;
  text-align: left;
}
.title{
  color: #d2211b;
  font-weight: bold;
  margin-bottom: 10px;
}
.kuai{
  background: white;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.name{
  padding-left: 5px;
}
.content{
  padding: 10px;
  display: block;
  color: #8d8b8b;
  width: 90%;
}
.tips{
  color: #8d8b8b;
  font-size: 12px;
  margin:10px;
}
</style>
