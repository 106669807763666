<template>
  <div class="main">
    <van-nav-bar
        title="购物车"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="background: black"
    >
      <template #right >
        <van-icon name="share" size="18" style="color: white;" />
      </template>
    </van-nav-bar>
<!--购物车-->
      <div class="shop">

        <template  v-for="(v,k) in cats" :key="k" :ref="'cG'+k">
          <div class="card_my">
            <van-checkbox v-model="v.check" class="qname" name="{{v.qname}}" @click="qxuan(k)">
              <img :src="v.qpic" class="qpic">
              {{v.qname}}
            </van-checkbox>

            <van-swipe-cell v-for="(sv,sk) in v.list" :key="sk" style="position: relative;" >
              <van-checkbox v-model="sv.check" class="check_cart" name="{{sv.id}}}"></van-checkbox>
              <van-card
                  class="goods-card"
                  :thumb="sv.image"
              >
                <template #title>
                  <p class="title">{{sv.title}}</p>
                </template>
                <template #price>
                  <p class="price_s">¥ {{sv.price}}</p>
                </template>
                <template #footer>
                  <Stepper class="num" v-model="sv.num"></Stepper>
                </template>
              </van-card>
              <template #right>
                <van-button square style="height: 100%" text="删除" type="danger" class="delete-button" />
              </template>
            </van-swipe-cell>
          </div>

        </template>

      </div>

    <van-submit-bar :price="TotalPrice" button-text="结算" @submit="onSubmit">
      <van-checkbox v-model="checked" @click="allxuan" ref="allel">全选</van-checkbox>
<!--      <template #tip>-->
<!--        你的收货地址不支持同城送, <span @click="onClickLink">修改地址</span>-->
<!--      </template>-->
    </van-submit-bar>
  </div>
</template>

<script>
import {Card,Tag,Button,NavBar,SubmitBar,Checkbox,SwipeCell,Stepper} from 'vant'
export default {
    name: "Cart",
    setup() {

  },
    data(){
        return {
          cats:[
              {
                qname:'天工开物',
                qpic:require('../../assets/img/tu3.png'),
                qid:1,
                check:true,
                list:[
                  {
                    image:require('../../assets/img/tu3.png'),
                    title:'商品商品商品商品商品商品商品',
                    desc:'xxxxxxxxxxxxxxxxxx',
                    num:1,
                    price:99,
                    check:true,
                    id:1,
                  },
                  {
                    image:require('../../assets/img/tu3.png'),
                    title:'商品',
                    desc:'xxxxxxxxxxxxxxxxxx',
                    num:1,
                    price:99,
                    check:false,
                    id:2,
                  },
                ]
              },
              {
                qname:'天工开物',
                qpic:require('../../assets/img/tu3.png'),
                qid:1,
                check:true,
                list:[
                  {
                    image:require('../../assets/img/tu3.png'),
                    title:'商品商品商品商品商品商品商品',
                    desc:'xxxxxxxxxxxxxxxxxx',
                    num:1,
                    price:99,
                    check:true,
                    id:1,
                  },
                  {
                    image:require('../../assets/img/tu3.png'),
                    title:'商品',
                    desc:'xxxxxxxxxxxxxxxxxx',
                    num:1,
                    price:99,
                    check:false,
                    id:2,
                  },
                ]
              }
            ],
          resulf:[],
          checked:false,
        }
    },
    created() {
    },
    methods:{
      allxuan(){
        this.$refs.allel.toggle();
        // if(this.checked==false){
        //   this.checked=true;
        // }else{
        //   this.checked=false;
        // }
        // console.log(this.checked)
        this.cats.forEach((v)=>{
          v.check = this.checked;
          v.list.forEach(vs=>{
            vs.check = this.checked;
          })
        })
      },
      qxuan(i){
        // console.log(this.cats[i].check);
        // this.cats[i].check = !this.cats[i].check;
        this.cats[i].list.forEach((v)=>{
          v.check = this.cats[i].check;
        })
      },
      onClickLeft(){
        this.$router.go(-1);
      }
    },
    components:{
      [Card.name]:Card,[Tag.name]:Tag,[Button.name]:Button,
      [NavBar.name]:NavBar,[SubmitBar.name]:SubmitBar,
      [Checkbox.name]:Checkbox,
      [SwipeCell.name]:SwipeCell,
      Stepper
    },
    computed:{
      TotalPrice:function(){
        var b = 0;
        this.cats.forEach((v)=>{
            v.list.forEach(vs=>{
             if(vs.check==true){
               b+=vs.price*vs.num;
             }
            })
        })
        return b*100;
      },
    }
}
</script>
<style>
.van-checkbox__label{
  line-height: 50px;
}
.check_cart{
  position: relative;
  top: 30px;
  z-index: 99;
}
.qname{font-size: 16px;}
</style>
<style scoped>
  .shop{
    margin-top: 20px;
  }
  .main{margin-bottom: 80px;}
  .qpic{width: 30px;border-radius: 99px;margin-right: 8px;position: relative;top: 8px;}

  .van-card{
    background: #fff;
    margin: 0px 15px;
    margin-right: 0px;
    border-radius: 10px;
  }
  .card_my{
    background: white;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
  }
  .title{
    font-size: 16px;
    text-align: left;
    width: 160px;
    padding-left: 20px;
  }
  .price_s{
    color: #d2211b;
    text-align: left;
    position: relative;
    right: 50px;
    font-size: 20px;
    font-weight: bold;
  }
  .num{
    position: absolute;
    bottom: 10px;
    left: 206px;
  }
</style>
